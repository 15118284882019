// eslint-disable-next-line no-unused-vars
export enum MARKETING_TABS {
	// eslint-disable-next-line no-unused-vars
	Statuses = 'Statuses',
	// eslint-disable-next-line no-unused-vars
	CHALLENGES = 'Challenges',
	// eslint-disable-next-line no-unused-vars
	DAILY_QUOTES = 'Daily Quotes',
	// eslint-disable-next-line no-unused-vars
	SPECIAL_OFFER = 'Special Offer',
}
