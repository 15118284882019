const photoRegex = /\.(jpg|jpeg|png|gif|webp|bmp)$/i;
const videoRegex = /\.(mp4|webm|ogg|avi|mov)$/i;

export const isImage = (url: File | string): boolean => {
	return url instanceof File ? photoRegex.test(url.name) : photoRegex.test(url);
};

export const isVideo = (url: File | string): boolean => {
	return url instanceof File ? videoRegex.test(url.name) : videoRegex.test(url);
};
