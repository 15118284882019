import React, { FC, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';

// icons
import { ReactComponent as ClockSvg } from 'assets/image/clock-challenges.svg';
import { ReactComponent as CopyIcon } from 'assets/image/icons/copy-icon.svg';

// hooks
import { useVolunteersDonations } from 'hooks/store';

// helpers
import { handleCopy } from '../../../Store/helpers';
import { STORE_VOLUNTEER_DONATIONS_QUERY_KEY } from 'core/constants';

// components
import Loader from 'shared/components/Loader';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import SortableHeaderItem from './SortableHeaderItem';
import CustomTooltip from 'shared/components/Tooltip';
import { TableEmptyState } from 'shared/components/Table/TableEmptyState';
import { SnackbarSuccess } from 'shared/components/_Snackbars';

// types
import {
	DONATION_VOLUNTEER_POSSIBLE_SORT,
	DonationVolunteerResponse,
	DonationVolunteerSort,
} from '@joc/api-gateway/lib/api-client';
import { SortDirection } from '@joc/api-gateway';

// styles
import styles from './DonationInfoTable.module.scss';

type DonationInfoTableProps = {
	donationItemId: number;
	search?: string;
};

const DonationInfoTable: FC<DonationInfoTableProps> = ({ donationItemId, search = '' }) => {
	const queryClient = useQueryClient();

	const [totalFetchedCoupons, setTotalFetchedCoupons] = useState(0);
	const [sort, setSort] = useState<DonationVolunteerSort>();
	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

	const { data, isFetching, isFetched, status, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useVolunteersDonations(search, donationItemId, sort);

	const { ref, inView } = useInView();

	useEffect(() => {
		setTotalFetchedCoupons(
			([] as Array<DonationVolunteerResponse>).concat(...(data?.pages.map(page => page?.records) || [])).length
		);
	}, [data]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const handleSortChange = useCallback(
		(sortBy: DONATION_VOLUNTEER_POSSIBLE_SORT, clickedDirection?: SortDirection) => {
			queryClient.removeQueries({ queryKey: [STORE_VOLUNTEER_DONATIONS_QUERY_KEY] });

			setSort(prevSort => {
				// Якщо вже вибраний той самий sortBy і вказано той самий direction → скидаємо сортування
				if (prevSort?.sortBy === sortBy && clickedDirection && prevSort?.sortDir === clickedDirection) {
					return undefined;
				}

				// Якщо sortBy той самий, але direction не вказано → Використовуємо стару логіку
				if (prevSort?.sortBy === sortBy && !clickedDirection) {
					if (prevSort.sortDir === SortDirection.ASC) {
						return DonationVolunteerSort.fromJS({ ...prevSort, sortDir: SortDirection.DESC });
					}
					return undefined;
				}

				// Якщо вказано clickedDirection, встановлюємо його. Інакше вибираємо ASC за замовчуванням
				return DonationVolunteerSort.fromJS({
					sortBy,
					sortDir: clickedDirection ?? SortDirection.ASC,
				});
			});
		},
		[]
	);

	const handleCopyEmail = async (email: string) => {
		setIsOpenSnackbar(true);
		await handleCopy(email);
	};

	return (
		<section
			className={cx(styles.donationsTable_wrapper, {
				[styles.overflow__hidden]: status === 'error' || (isFetched && !totalFetchedCoupons),
				[styles.overflow_y__hidden]: status === 'pending' && !totalFetchedCoupons,
			})}
		>
			<table className={styles.donationsTable}>
				<thead className={styles.donationsTable__thead}>
					<tr>
						<th align="left" className={styles.donationsTable__thead__Avatar}>
							Avatar
						</th>
						<th align="left" className={styles.donationsTable__thead__FullName}>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<SortableHeaderItem
								sortBy={DONATION_VOLUNTEER_POSSIBLE_SORT.Name}
								title="Full name"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.donationsTable__thead__ShownAs}>
							Shown as
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.donationsTable__thead__Sum}>
							<SortableHeaderItem
								sortBy={DONATION_VOLUNTEER_POSSIBLE_SORT.CoinAmount}
								title="Sum"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.donationsTable__thead__Date}>
							<SortableHeaderItem
								sortBy={DONATION_VOLUNTEER_POSSIBLE_SORT.CreateDate}
								title="Date"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.donationsTable__thead__Email}>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<SortableHeaderItem
								sortBy={DONATION_VOLUNTEER_POSSIBLE_SORT.Email}
								title="Email"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th className={styles.donationsTable__thead__Actions} />
					</tr>
				</thead>
				<tbody className={styles.donationsTable__tbody}>
					{data?.pages.flatMap(page =>
						page?.records.map(donation => (
							<tr key={donation.id}>
								<td align="left" className={styles.donationsTable__tbody__Avatar}>
									<AvatarCell
										imagePath={donation.avatarPath}
										donationVolunteerName={donation.volunteerName}
										withPopup={true}
										parentClassName={styles.donationsTable__tbody__Avatar}
									/>
								</td>
								<td align="left" className={styles.donationsTable__tbody__FullName}>
									<span>{donation.volunteerName}</span>
								</td>
								<td align="left" className={styles.donationsTable__tbody__ShownAs}>
									<span>{donation.isAnonymous ? 'Anonymous' : 'Public'}</span>
								</td>
								<td align="left" className={styles.donationsTable__tbody__Sum}>
									<span>{donation.coinAmount}</span>
								</td>
								<td align="left" className={styles.donationsTable__tbody__Date}>
									<div>
										<ClockSvg />
										<span>{moment(donation.createDate).format('MMMM D, YYYY, h:mm A')}</span>
									</div>
								</td>
								<td align="left" className={styles.donationsTable__tbody__Email}>
									<span>{donation.email}</span>
								</td>
								<td align="center" className={styles.donationsTable__tbody__Actions}>
									<CustomTooltip
										title="Copy Email"
										arrow={true}
										placement="top"
										style={{ cursor: 'pointer' }}
										PopperProps={{
											className: styles.donationsTable__tbody__Actions_tooltip,
										}}
									>
										<CopyIcon onClick={() => handleCopyEmail(donation.email)} />
									</CustomTooltip>
								</td>
							</tr>
						))
					)}
					{!isFetching && (
						<tr style={{ height: 1 }} ref={ref}>
							<td style={{ padding: 0, height: 1 }}>
								<div style={{ height: '1px', visibility: 'hidden' }} />
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{isFetchingNextPage || status === 'pending' ? <Loader /> : null}

			{!isFetching && !totalFetchedCoupons && (
				<TableEmptyState
					message={
						status === 'error' ? 'Something went wrong.' : 'There are currently no donations in the list.'
					}
				/>
			)}

			<SnackbarSuccess
				open={isOpenSnackbar}
				onClose={setIsOpenSnackbar}
				message="Email has been copied successfully!"
				width={308}
			/>
		</section>
	);
};

export default DonationInfoTable;
