import { useInfiniteQuery } from '@tanstack/react-query';
import { INITIAL_PAGINATION, STORE_COUPONS_QUERY_KEY } from '../../../core/constants';
import {
	SearchStoreCouponsRequest,
	SearchStoreCouponsResponse,
	StoreCouponSort,
} from '@joc/api-gateway/lib/api-client';
import { API } from '../../../core/API';

const fetchCoupons = async ({
	storeItemId,
	skip,
	take,
	search,
	sort,
}: {
	storeItemId: number;
	skip: number;
	take: number;
	search?: string;
	sort?: StoreCouponSort;
}): Promise<SearchStoreCouponsResponse> =>
	API.searchStoreCoupons(
		storeItemId,
		SearchStoreCouponsRequest.fromJS({
			pagination: {
				skip,
				take,
			},
			sort,
			...(search ? { search } : {}),
		})
	);

export const useCoupons = (storeItemId: number, search: string, sort?: StoreCouponSort) =>
	useInfiniteQuery<SearchStoreCouponsResponse, unknown>({
		queryKey: [STORE_COUPONS_QUERY_KEY, { storeItemId, search, sort }],
		initialPageParam: 0,
		refetchOnWindowFocus: false,
		queryFn: ({ pageParam = 0 }) =>
			fetchCoupons({ storeItemId, skip: pageParam as number, take: INITIAL_PAGINATION.take, search, sort }),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.records.length < INITIAL_PAGINATION.take ? undefined : allPages.length * 10;
		},
	});
