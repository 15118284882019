import * as Yup from 'yup';
import i18n from '../../../../../components/translate';
import { SUPPORTED_MEDIA_FORMAT } from '@joc/api-gateway/lib/api-client';

const validationT = i18n.getFixedT(null, 'validation');

export const MAX_CAPTION_LENGTH = 60;

const captionSchema = Yup.string()
	.min(2, 'Status caption should be at least 2 characters')
	.max(MAX_CAPTION_LENGTH, `Status caption must be at most ${MAX_CAPTION_LENGTH} characters`)
	.test('required', 'Status caption is required', function (value) {
		return !(!value && (!!this.parent.link?.length || !!this.parent.linkTitle?.length));
	})
	.trim()
	.nullable(true);
// .required('Status title is required');

const linkTitleSchema = Yup.string()
	.min(2, 'Link title should be at least 2 characters')
	.test('required', 'Link title is required', function (value) {
		return !(!value && !!this.parent.link?.length);
	})
	.trim()
	.nullable(true);

// .required('Link title is required');

const urlSchema = Yup.string()
	.url('Please, fill valid URL-Link')
	.test('required', 'URL-Link is required', function (value) {
		return !(!value && !!this.parent.linkTitle?.length);
	})
	.nullable(true);

const validateVideo = async (file: File) => {
	if (!file) return false;

	const MAX_VIDEO_DURATION = 91; // Maximum allowed duration in seconds

	const isVideo = file.type.startsWith('video/');
	if (!isVideo) return true;

	const url = URL.createObjectURL(file);
	return new Promise(resolve => {
		const video = document.createElement('video');
		video.src = url;
		video.onloadedmetadata = () => {
			URL.revokeObjectURL(url); // Clean up the URL
			resolve(video.duration <= MAX_VIDEO_DURATION);
		};
		video.onerror = () => {
			URL.revokeObjectURL(url);
			resolve(false);
		};
	});
};

export const supportedMimeTypes = `${SUPPORTED_MEDIA_FORMAT.Image_bmp}, ${SUPPORTED_MEDIA_FORMAT.Image_jpeg}, ${SUPPORTED_MEDIA_FORMAT.Image_png}, ${SUPPORTED_MEDIA_FORMAT.Video_mp4}`;
export const supportedPhotoMimeTypes = `${SUPPORTED_MEDIA_FORMAT.Image_bmp}, ${SUPPORTED_MEDIA_FORMAT.Image_jpeg}, ${SUPPORTED_MEDIA_FORMAT.Image_png}`;

const fileSchema = Yup.mixed()
	// .test('fileSize', 'File too large. Max size is 5MB.', (value) => !value || (value && value.size <= FILE_SIZE))
	.test(
		'fileFormat',
		`Unsupported format. Only ${supportedMimeTypes} are allowed.`,
		value => !value || !(value instanceof File) || (value && supportedMimeTypes.includes(value.type))
	)
	.test('isValidVideo', 'Video exceeds maximum duration of 1 minute and 30 seconds.', async value => {
		if (!value || !(value instanceof File)) return true; // Skip validation if no file
		return (await validateVideo(value)) as boolean;
	})
	.required('File is required');

const dateSchema = Yup.date()
	.required('Publication Date is required')
	.typeError('Please, fill full date')
	.nullable(true);

export const timeSchema = Yup.string().required(validationT('totalHoursSchema.required')).nullable(true);

export const statusValidationSchema = Yup.object().shape({
	mediaPath: fileSchema,
	caption: captionSchema,
	linkTitle: linkTitleSchema,
	link: urlSchema,
	startDate: dateSchema,
});
