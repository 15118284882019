import { FC, useEffect, useState } from 'react';
import { Formik } from 'formik';
import cx from 'classnames';

// hooks
import { useCreateCoupons } from 'hooks/store';

// components
import { StoreCouponForm, StoreCouponsListForm } from '../../../StoreForms';
import { SnackbarError } from 'shared/components/_Snackbars';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';

// helpers
import { couponsListValidationSchema, singleCouponValidationSchema } from '../../../helpers';

// types
import { StoreCouponRequest } from '@joc/api-gateway/lib/api-client';

// styles
import styles from './StoreAddCoupons.module.scss';

type StoreAddCouponsProps = {
	storeItemId: number;
	onClose?: () => void;
	// eslint-disable-next-line no-unused-vars
	onCouponsUploaded: (isSingle: boolean) => void;
};

const StoreAddCoupons: FC<StoreAddCouponsProps> = ({ storeItemId, onClose, onCouponsUploaded }) => {
	const [isSingle, setIsSingle] = useState(true);
	const [isApiErrorSnackbar, setIsApiErrorSnackbar] = useState(false);

	const { createCoupons, isSuccess, isPending, isError } = useCreateCoupons();

	const handleSubmitSingle = async ({ couponCode }: { couponCode: string }) => {
		try {
			createCoupons({ storeItemId, coupons: [StoreCouponRequest.fromJS({ couponCode: couponCode.trim() })] });
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const handleSubmitList = async ({ coupons }: { coupons: Array<StoreCouponRequest> }) => {
		try {
			createCoupons({ storeItemId, coupons });
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	useEffect(() => {
		if (isSuccess) onCouponsUploaded(isSingle);
		setIsApiErrorSnackbar(isError);
	}, [isSuccess, isError]);

	return (
		<section className={styles.addCoupons}>
			<div className={styles.addCoupons__switch}>
				<ButtonOutlined
					title="Add Single"
					parentClassName={cx(styles.addCoupons__switch_button, {
						[styles.addCoupons__switch_button_active]: isSingle,
					})}
					clickHandler={() => setIsSingle(true)}
				/>
				<ButtonOutlined
					title="Add by list"
					parentClassName={cx(styles.addCoupons__switch_button, {
						[styles.addCoupons__switch_button_active]: !isSingle,
					})}
					clickHandler={() => setIsSingle(false)}
				/>
			</div>
			{isSingle ? (
				<Formik
					initialValues={{ couponCode: '' }}
					onSubmit={handleSubmitSingle}
					validationSchema={singleCouponValidationSchema}
					enableReinitialize={true}
				>
					<StoreCouponForm isSaving={isPending} isFinishedSaving={isSuccess} onClose={onClose} />
				</Formik>
			) : (
				<Formik
					initialValues={{ coupons: [] }}
					onSubmit={handleSubmitList}
					validationSchema={couponsListValidationSchema}
					enableReinitialize={true}
				>
					<StoreCouponsListForm isSaving={isPending} onClose={onClose} />
				</Formik>
			)}

			<SnackbarError open={isApiErrorSnackbar} onClose={setIsApiErrorSnackbar} message="Something went wrong." />
		</section>
	);
};

export default StoreAddCoupons;
