import { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from '@tanstack/react-query';

// context
import { useStatusesContext } from '../Statuses.context';

// components
import StatusMedia from './StatusMedia';
import StatusAction from './StatusActions';
import StatusPreview from '../StatusPreview';
import Loader from 'shared/components/Loader';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import Dialog from 'shared/components/newUI/Dialog';

// hooks
import { useStatuses } from '../../../../../hooks/statuses';

// icons
import { ReactComponent as ClockSvg } from 'assets/icons/clock.svg';
import { ReactComponent as NoStatusImage } from 'assets/image/no-items-image.svg';

// types
import { StatusResponse } from '@joc/api-gateway';

// styles
import styles from './StatusesTable.module.scss';
import stylesAction from './StatusActions/StatusActions.module.scss';

type StatusesTableProps = { orgId: string };

const StatusesTable: FC<StatusesTableProps> = ({ orgId }) => {
	const { search, filters } = useStatusesContext();
	const [preview, setStatusPreview] = useState<StatusResponse | null>(null);
	const [totalFetchedStatuses, setTotalFetchedStatuses] = useState(0);

	const { ref, inView } = useInView({
		threshold: 1.0, // Trigger only when the element is fully in view
	});

	const { data, isFetching, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useStatuses(
		orgId,
		search,
		filters
	);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	useEffect(() => {
		return () => {
			// queryClient.resetQueries().finally();
		};
	}, []);

	useEffect(() => {
		setTotalFetchedStatuses(
			([] as Array<StatusResponse>).concat(...(data?.pages.map(page => page.records) || [])).length
		);
	}, [data]);

	const phase = (createDate: Date) =>
		moment().isSameOrAfter(createDate)
			? moment(createDate).add(1, 'day').isBefore(moment())
				? 'Archived'
				: 'Published'
			: 'Scheduled';

	const formatDate = (date: Moment) => date.format('MMMM DD, YYYY, hh:mm A');

	return (
		<section className={cx(styles.statuses__wrapper, { [styles.overflow__hidden]: !totalFetchedStatuses })}>
			{status === 'pending' ? (
				<Loader loadProps={{ stylePageCenter: true }} />
			) : status === 'error' ? (
				<></>
			) : (
				<table className={styles.statuses}>
					<thead>
						<tr>
							<th align="left" className={styles.statuses_media}>
								Content Type
							</th>
							<th align="left" className={styles.statuses_caption}>
								Caption
							</th>
							<th align="left" className={styles.statuses_link}>
								Link
							</th>
							<th align="left" className={styles.statuses_startDate}>
								Publication Date
							</th>
							<th align="left" className={styles.statuses_endDate}>
								End Date
							</th>
							<th align="left" className={styles.statuses_phase}>
								Phase
							</th>
							<th align="left" aria-label="Actions" />
						</tr>
					</thead>
					<tbody>
						{data?.pages.flatMap(page =>
							page.records.map(status => (
								<tr key={status.id} className={styles.statuses__table__row}>
									<td align="center" className={styles.statuses_media}>
										<div onClick={() => setStatusPreview(status)}>
											<StatusMedia url={status.mediaPath} />
										</div>
									</td>
									<td
										className={cx(styles.statuses_caption, styles.statuses_row_caption)}
										title={status.caption}
									>
										<span>{status.caption}</span>
									</td>
									<td
										className={cx(styles.statuses_link, styles.statuses_row_link)}
										title={status.linkTitle}
									>
										<a href={status.link} target="_blank" rel="noreferrer">
											{status.linkTitle}
										</a>
									</td>
									<td className={styles.statuses_startDate}>
										<div className={styles.statuses_startDate_wrapper}>
											<ClockSvg />
											<span>{formatDate(moment(status.startDate))}</span>
										</div>
									</td>
									<td className={cx(styles.statuses_endDate)}>
										<div className={styles.statuses_endDate_wrapper}>
											<ClockSvg />
											<span>{formatDate(moment(status.startDate).add(1, 'day'))}</span>
										</div>
									</td>
									<td
										className={cx(styles.statuses_phase, styles.statuses_row_phase)}
										title={phase(status.startDate)}
									>
										<span>{phase(status.startDate)}</span>
									</td>
									<td align="center">
										<StatusAction status={status} />
									</td>
								</tr>
							))
						)}
						<tr>
							<td>
								<div ref={ref} style={{ height: '1px', visibility: 'hidden' }} />
							</td>
						</tr>
					</tbody>
				</table>
			)}

			{isFetchingNextPage ? <Loader loadProps={{ parentClassName: styles.pagination__loader }} /> : null}

			{!isFetching && !totalFetchedStatuses ? (
				<div className={styles.noItemsContainer}>
					<div className={styles.noItemsContainer__gradient} />
					<NoStatusImage className={styles.noItemsImage} />
					<div className={styles.noItemsTextContainer}>
						<span className={styles.noItemsTitle}>Oops</span>
						<span className={styles.noItemsText}>
							There are currently no statuses in the list. Create them to see them here.
						</span>
					</div>
				</div>
			) : null}

			{preview && (
				<Dialog open={!!preview} maxWidth="xl">
					<div className={stylesAction.dialog__header}>
						<ButtonCross
							clickHandler={() => setStatusPreview(null)}
							parentClassName={stylesAction.dialog__header_button}
						/>
					</div>
					<div className={stylesAction.dialog__content}>
						<StatusPreview status={preview} />
					</div>
				</Dialog>
			)}
		</section>
	);
};

export default StatusesTable;
