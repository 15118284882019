import { styled } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// styles
import { CommonFieldStyles } from '../styles';

export const DateTimePickerStyled = styled(DateTimePicker)({
	...CommonFieldStyles,
	'.MuiOutlinedInput-root': {
		...CommonFieldStyles['.MuiOutlinedInput-root'],
		width: '100%',
	},
});
