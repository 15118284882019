import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IUpdateDonationRequest, UpdateDonationRequest } from '@joc/api-gateway';
import { API } from '../../../core/API';
import { STORE_DONATIONS_QUERY_KEY } from '../../../core/constants';
import { DonationResponse, IUpdateStoreItemRequest } from '@joc/api-gateway/lib/api-client';

interface IUseEditDonationItem {
	// eslint-disable-next-line no-unused-vars
	editDonationItem: ({ id, donationItem }: { id: number; donationItem: IUpdateDonationRequest }) => void;
}

const editDonationItemApi = async (id: number, donationItem: IUpdateStoreItemRequest) => {
	const { expirationDate, ...data } = donationItem;
	const body = UpdateDonationRequest.fromJS(data);

	return API.updateDonation(id, { ...body, expirationDate } as unknown as UpdateDonationRequest);
};

export const useEditDonationItem = (): IUseEditDonationItem => {
	const queryClient = useQueryClient();
	const { mutate: editDonationItem } = useMutation<
		DonationResponse,
		Error,
		{ id: number; donationItem: IUpdateDonationRequest }
	>({
		mutationFn: ({ id, donationItem }) => editDonationItemApi(id, donationItem),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_DONATIONS_QUERY_KEY] }),
	});

	return { editDonationItem };
};
