import * as Yup from 'yup';
import { string } from 'yup';

export const MAX_BADGE_TITLE_LENGTH = 20;

export const MAX_TITLE_LENGTH = 20;
export const MAX_BUTTON_NAME_LENGTH = 20;

export const specialOfferValidationSchema = Yup.object().shape({
	mediaPath: string().required('Image is required'),
	name: Yup.string()
		.min(2, 'Offer Badge Title should be no less than 2 characters')
		.max(MAX_BADGE_TITLE_LENGTH, `Offer Badge Title must be at most ${MAX_BADGE_TITLE_LENGTH} characters`)
		.required('Offer Badge Title is required'),
	title: Yup.string()
		.min(2, 'Special Offer Title should be no less than 2 characters')
		.max(MAX_TITLE_LENGTH, `Special Offer Title must be at most ${MAX_TITLE_LENGTH} characters`)
		.required('Special Offer Title is required'),
	storeItemId: Yup.string().test({
		name: 'storeItemIdRequired',
		message: 'Item should have either a Shop Item or a URL-Link.',
		test(value) {
			return !!value || !!this.parent.link;
		},
	}),
	link: Yup.string()
		.url('Invalid URL format')
		.test({
			name: 'linkRequired',
			message: 'Item should have either a Shop Item or a URL-Link.',
			test(value) {
				return !!value || !!this.parent.storeItemId;
			},
		}),
	linkTitle: Yup.string()
		.min(2, 'Button Name should be no less than 2 characters')
		.max(MAX_BUTTON_NAME_LENGTH, `Button Name must be at most ${MAX_BUTTON_NAME_LENGTH} characters`)
		.required('Button Name is required'),
});
