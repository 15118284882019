import { IStoreItemRequest, IStoreItemResponse } from '@joc/api-gateway';
import { IImageItem } from 'shared/components/SortableImagesListField';
import { IStoreCouponRequest } from '@joc/api-gateway/lib/api-client';

export type StoreItemResponseType = Omit<IStoreItemResponse, 'mediaPathes'> & { mediaPathes: Array<string> };
export type StoreItemFormType = Omit<IStoreItemRequest, 'mediaPathes' | 'price'> & {
	mediaPathes: Array<IImageItem>;
	price: number | null;
	endDate?: Date;
	endTime?: string;
};

type ValidationErrors<Type> = {
	// eslint-disable-next-line no-unused-vars
	[Property in keyof Type]?: string;
};

export type UploadedCoupon = IStoreCouponRequest & {
	errors?: ValidationErrors<IStoreCouponRequest>;
};

export enum StoreTabs {
	Store = 'Store',
	Donation = 'Donation',
}
