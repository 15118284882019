import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { getLiveScreen, resetLiveScreen, updateLiveScreen } from 'redux/dashboard-service/action';
import { selectorGetLiveScreen } from 'redux/dashboard-service/selector';
import { SET_ERROR } from 'redux/actions-types';
import { ILiveScreenInfo } from 'redux/dashboard-service/types';
// Formik
import { Formik } from 'formik';
// Components
import { LiveScreenForm } from './LiveScreenForm';
// Api
import { API } from 'core/API';
// Styles
import CongratsPopup from 'components/Organization/CongratsPopup';
import useThunkDispatch from '../../../core/customHooks/useThunkDispatch';

const validationScheme = Yup.object().shape({
	title: Yup.string().required(),
	subTitle: Yup.string().required(),
});

type LiveScreenProps = {
	// eslint-disable-next-line no-unused-vars
	onChangeEditMode: (value: boolean) => void;
	editMode: boolean;
};

export const LiveScreen: FC<LiveScreenProps> = ({ onChangeEditMode, editMode }) => {
	const dispatch = useThunkDispatch();

	const liveScreenInfo = useSelector(selectorGetLiveScreen);
	const [file, setFile] = useState<Blob | null>(null);
	const [isCongrats, setIsCongrats] = useState(false);

	const [formValue, setFormValue] = useState<Partial<ILiveScreenInfo>>({
		title: '',
		subTitle: '',
		mediaPathes: [],
	});

	useEffect(() => {
		dispatch(getLiveScreen());

		return () => {
			dispatch(resetLiveScreen());
		};
	}, []);

	useEffect(() => {
		if (liveScreenInfo) initFormData();
	}, [liveScreenInfo]);

	const initFormData = () => {
		setFormValue({
			title: '',
			subTitle: '',
			mediaPathes: [],
		});
		if (liveScreenInfo) {
			const data = {
				title: liveScreenInfo.title,
				subTitle: liveScreenInfo.subTitle,
				mediaPath: liveScreenInfo.mediaPathes,
			};
			setFormValue(data);
		}
	};

	const onCancelEditMode = () => {
		initFormData();
		setFile(null);
		onChangeEditMode(false);
	};

	const handleSubmit = async (values: ILiveScreenInfo) => {
		try {
			const body = { ...values };
			if (file) {
				const fileName = (file as any).name;
				const responseUpload = await API.uploadFile({ data: file, fileName });
				body.mediaPathes = [`${process.env.REACT_APP_S3_API}/${responseUpload.fileName}`];
				setFile(null);
			} else {
				// @ts-ignore
				delete body.mediaPathes;
			}

			body.title = body.title.trim();
			body.subTitle = body.subTitle.trim();

			await dispatch(updateLiveScreen(body));
			onChangeEditMode(false);
			setIsCongrats(true);
			await dispatch(getLiveScreen());
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error.message } });
		}
	};

	return (
		<>
			<Formik
				enableReinitialize={true}
				validationSchema={validationScheme}
				initialValues={formValue}
				onSubmit={() => {}}
			>
				<LiveScreenForm
					file={file}
					setFile={setFile}
					mediaPathes={liveScreenInfo?.mediaPathes || []}
					editMode={editMode}
					onCancelEditMode={onCancelEditMode}
					onSaveChanges={handleSubmit}
				/>
			</Formik>
			{isCongrats && (
				<CongratsPopup
					title="Success!"
					subtitle="Information has been updated."
					doneButtonClickHandler={() => setIsCongrats(false)}
					isFullHeight={true}
					isLiveScreen={true}
				/>
			)}
		</>
	);
};
