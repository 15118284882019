import { FC, useState } from 'react';
import StoreActions from './StoreActions';
import { StoreItemsTable } from './StoreTables';
import { StoreItemPopup } from './StorePopups';

const Store: FC = () => {
	const [popupCreateOpen, setPopupCreateOpen] = useState(false);
	const [search, setSearch] = useState('');
	const togglePopupHandler = () => setPopupCreateOpen(!popupCreateOpen);

	const searchHandler = (value: string) => setSearch(value);

	return (
		<>
			<StoreActions onCreateStoreItem={togglePopupHandler} onSearchStoreItem={searchHandler} />
			<StoreItemsTable search={search} />
			{popupCreateOpen ? <StoreItemPopup open={popupCreateOpen} onClose={togglePopupHandler} /> : null}
		</>
	);
};

export default Store;
