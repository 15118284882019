import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { flip, shift, useFloating } from '@floating-ui/react-dom';
import { useClickAway } from 'react-use';
import cx from 'classnames';

// icons
import { ReactComponent as CopyIcon } from 'assets/image/icons/copy-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/image/icons/trash-new.svg';
import { ReactComponent as NoStatusImage } from 'assets/image/no-items-image.svg';
import { ReactComponent as ArrowSortSVG } from 'assets/image/icons/arrow-sort.svg';

// hooks
import { useCoupons, useDeleteCoupon, usePublishStoreItem } from 'hooks/store';

// components
import { SnackbarSuccess } from 'shared/components/_Snackbars';
import Loader from 'shared/components/Loader';

// helpers
import { handleCopy } from '../../helpers';

// types
import {
	SortDirection,
	STORE_COUPON_POSSIBLE_SORT,
	StoreCouponResponse,
	StoreCouponSort,
} from '@joc/api-gateway/lib/api-client';

// styles
import styles from './StoreCouponsTable.module.scss';

type StoreCouponsTableProps = {
	storeItemId: number;
	isPublished: boolean;
};

const StoreCouponsTable: FC<StoreCouponsTableProps> = ({ storeItemId, isPublished }) => {
	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
	const [isSortSelectOpen, setIsSortSelectOpen] = useState(false);
	const [totalFetchedCoupons, setTotalFetchedCoupons] = useState(0);

	const { refs, floatingStyles } = useFloating({
		placement: 'bottom-start',
		middleware: [flip(), shift()],
	});

	useClickAway(refs.floating, () => setIsSortSelectOpen(false));

	const [sort, setSort] = useState<StoreCouponSort>();

	const { deleteCoupon } = useDeleteCoupon();
	const { data, isFetching, isFetched, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useCoupons(
		storeItemId,
		'',
		sort
	);
	const { publishStoreItem } = usePublishStoreItem();

	const { ref, inView } = useInView();

	useEffect(() => {
		if (isPublished && data && !hasNextPage && data.pages.length === 1 && !data.pages[0].records.length) {
			publishStoreItem({ id: storeItemId });
		}

		setTotalFetchedCoupons(
			([] as Array<StoreCouponResponse>).concat(...(data?.pages.map(page => page?.records) || [])).length
		);
	}, [data]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const handleCopyCoupon = async (couponCode: string) => {
		setIsOpenSnackbar(true);
		await handleCopy(couponCode);
	};

	const handleDeleteCoupon = async (id: number) => {
		try {
			deleteCoupon(id);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	const handleSortChange = (sortDir: SortDirection) => {
		setSort(
			sort?.sortDir !== sortDir
				? StoreCouponSort.fromJS({ sortBy: STORE_COUPON_POSSIBLE_SORT.STATUS, sortDir })
				: undefined
		);
	};

	return (
		<section
			className={cx(styles.couponsTable_wrapper, {
				[styles.overflow__hidden]: status === 'error' || (isFetched && !totalFetchedCoupons),
				[styles.overflow_y__hidden]: status === 'pending' && !totalFetchedCoupons,
			})}
		>
			<table className={styles.couponsTable}>
				<thead className={styles.couponsTable__thead}>
					<tr>
						<th align="left" className={styles.couponsTable__thead__ID}>
							ID
						</th>
						<th align="left" className={styles.couponsTable__thead__Coupons}>
							Coupons
						</th>
						<th align="left" className={styles.couponsTable__thead__Status}>
							<div
								ref={refs.setReference}
								onClick={() => setIsSortSelectOpen(prev => !prev)}
								className={cx(styles.couponsTable__thead__Status__sortable, {
									[styles.couponsTable__thead__Status__sortable_active]: sort,
								})}
							>
								<span className={styles.couponsTable__thead__Status__sortable_title}>Status</span>
								<ArrowSortSVG />
								{isSortSelectOpen && (
									<div
										ref={refs.setFloating}
										style={floatingStyles}
										className={cx(styles.couponsTable__thead__Status__sortable__select)}
									>
										<span
											className={cx(styles.couponsTable__thead__Status__sortable__select_item, {
												[styles.couponsTable__thead__Status__sortable__select_item_active]:
													sort?.sortDir === SortDirection.DESC,
											})}
											onClick={() => handleSortChange(SortDirection.DESC)}
										>
											Active
										</span>
										<span
											className={cx(styles.couponsTable__thead__Status__sortable__select_item, {
												[styles.couponsTable__thead__Status__sortable__select_item_active]:
													sort?.sortDir === SortDirection.ASC,
											})}
											onClick={() => handleSortChange(SortDirection.ASC)}
										>
											Claimed
										</span>
									</div>
								)}
							</div>
						</th>
						<th align="left" className={styles.couponsTable__thead__Actions}>
							Actions
						</th>
					</tr>
				</thead>
				<tbody className={styles.couponsTable__tbody}>
					{data?.pages.flatMap(page =>
						page?.records.map(coupon => (
							<tr key={coupon.id}>
								<td align="left" className={styles.couponsTable__tbody__ID}>
									<span>{coupon.id}</span>
								</td>
								<td align="left" className={styles.couponsTable__tbody__Coupons}>
									<span>{coupon.couponCode}</span>
								</td>
								<td align="left" className={styles.couponsTable__tbody__Status}>
									{coupon.purchaser && coupon.purchaseDate ? (
										<span>
											Claimed{' '}
											<a
												className={styles.couponsTable__tbody__Status_link}
												href={`mailto:${coupon.purchaser.email}`}
											>
												{coupon.purchaser.email}
											</a>
										</span>
									) : (
										<span>Active</span>
									)}
								</td>
								<td align="left" className={styles.couponsTable__tbody__Actions}>
									<div>
										<CopyIcon onClick={() => handleCopyCoupon(coupon.couponCode)} />
										<TrashIcon onClick={() => handleDeleteCoupon(coupon.id)} />
									</div>
								</td>
							</tr>
						))
					)}
					{!isFetching && (
						<tr style={{ height: 1 }} ref={ref}>
							<td style={{ padding: 0 }}>
								<div style={{ height: '1px', visibility: 'hidden' }} />
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{isFetchingNextPage || status === 'pending' ? <Loader /> : null}

			{!isFetching && !totalFetchedCoupons ? (
				<div className={styles.noItemsContainer}>
					<div className={styles.noItemsContainer__gradient} />
					<NoStatusImage className={styles.noItemsImage} />
					<div className={styles.noItemsTextContainer}>
						<span className={styles.noItemsTitle}>Oops</span>
						<span className={styles.noItemsText}>
							{status === 'error'
								? 'Something went wrong.'
								: 'There are currently no coupons in the list. Create them to see them here.'}
						</span>
					</div>
				</div>
			) : null}

			<SnackbarSuccess
				open={isOpenSnackbar}
				onClose={setIsOpenSnackbar}
				message="Coupon code has been copied successfully!"
				width={308}
			/>
		</section>
	);
};

export default StoreCouponsTable;
