import { useInfiniteQuery } from '@tanstack/react-query';

// types
import { SearchStatusesRequest, SearchStatusResponse } from '@joc/api-gateway/lib/api-client';
import { IDateRange } from '@joc/api-gateway';

// core
import { API } from 'core/API';
import { STATUSES_QUERY_KEY } from 'core/constants';

const fetchStatuses = async ({
	skip,
	take,
	orgId,
	search,
	startDay,
}: {
	skip: number;
	take: number;
	orgId: string;
	search?: string;
	startDay?: IDateRange;
}): Promise<SearchStatusResponse> =>
	API.searchStatusesByAdmin(
		undefined,
		orgId,
		SearchStatusesRequest.fromJS({
			pagination: {
				skip,
				take,
			},
			search,
			startDay,
		})
	);

export const useStatuses = (orgId: string, search: string, startDay: IDateRange) =>
	useInfiniteQuery<SearchStatusResponse, unknown>({
		queryKey: [STATUSES_QUERY_KEY, { search, startDay }],
		initialPageParam: 0,
		queryFn: ({ pageParam = 0 }) => fetchStatuses({ skip: pageParam as number, take: 10, orgId, startDay, search }),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.records.length < 10 ? undefined : allPages.length * 10;
		},
	});
