export const TABLE_HEADERS = {
	avatar: 'Avatar',
	fullName: 'Full name',
	email: 'Email',
	phoneNumber: 'Phone number',
	age: 'Age',
	gender: 'Gender',
	location: 'Location',
	forms: 'Forms',
	totalTimeSpent: 'Total',
	completedHours: 'Completed hours',
	select: 'Select',
	chesedName: 'Chesed name',
	date: 'Date',
	start: 'Start at',
	end: 'End at',
	coordinator: 'Coordinator',
	volunteers: 'Possible Volunteers',
	logo: 'Logo',
	orgName: 'Organization name',
	owner: 'Owner',
	typeOrg: 'Organization type',
	contacts: 'Contacts',
	listOrganizations: 'List Organizations',
	totalVolunteers: 'Volonteers',
	totalUsers: 'Users',
	totalOppo: 'Oppotunities',
	totalOppoHours: 'Oppotunities` hours',
	role: 'Role',
	position: 'Position',
	organization: 'Organization',
	admin: 'Admin',
	marketer: 'Marketer',
	member: 'Member',
	volunteerRole: 'Volunteer',
	organizations: 'Organizations',
	grade: 'School grade',
	spendTime: 'Spend time',
	// suspend: 'Active status',
};
