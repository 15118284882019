import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'core/API';
import { STORE_SPECIAL_OFFER_QUERY_KEY } from 'core/constants';

interface IUseDeleteOffer {
	// eslint-disable-next-line no-unused-vars
	deleteOffer: (id: number) => void;
}

const deleteOfferApi = async (id: number) => {
	return API.deleteOffer(id);
};

export const useDeleteOffer = (): IUseDeleteOffer => {
	const queryClient = useQueryClient();
	const { mutate: deleteOffer } = useMutation<void, Error, number>({
		mutationFn: offerId => deleteOfferApi(offerId),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_SPECIAL_OFFER_QUERY_KEY] }),
	});

	return { deleteOffer };
};
