import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll, BodyScrollOptions } from 'body-scroll-lock';
import cx from 'classnames';

// hooks

// components
import ButtonCross from 'shared/components/Buttons/ButtonCross';

// styles
import styles from './PopupContainer.module.scss';
import { useClickAway } from 'react-use';

const disableScrollOptions: BodyScrollOptions = {
	reserveScrollBarGap: true,
};

type PopupContainerPropsType = {
	children?: ReactNode;
	setIsShowPopup?: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
	parentClassName?: string;
	containerClassName?: string;
	isDisablePadding?: boolean;
	isEnableClickOutside?: boolean;
	isDisableCloseButton?: boolean;
	isFixedStyle?: boolean;
	isPhotoStyle?: boolean;
	isBroadcastStyle?: boolean;
	isPhotoStyleUpdating?: boolean;
	isContentCenter?: boolean;
	isContentHeightAuto?: boolean;
	isContentWidthAuto?: boolean;
	closeButtonParentClickHandler?: () => void;
	isClosePopupByParent?: boolean;
	isDisableContentMarginTop?: boolean;
	isDisableContentPadding?: boolean;
	isContentFullWidth?: boolean;
};

const PopupContainer: FC<PopupContainerPropsType> = ({
	setIsShowPopup,
	children,
	isDisablePadding,
	isDisableCloseButton,
	isEnableClickOutside,
	isFixedStyle,
	isPhotoStyle,
	isBroadcastStyle,
	isPhotoStyleUpdating,
	isContentCenter,
	isContentHeightAuto,
	isContentWidthAuto,
	closeButtonParentClickHandler,
	isClosePopupByParent,
	isDisableContentMarginTop,
	parentClassName,
	containerClassName,
	isContentFullWidth,
}) => {
	const [isClosePopup, setIsClosePopup] = useState<boolean>(false);

	const containerRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	useClickAway(contentRef, () => closeButtonParentClickHandler?.());

	const closePopupClickHandler = () => {
		setIsClosePopup(true);
		if (setIsShowPopup) setTimeout(() => setIsShowPopup(false), 200);
	};

	useEffect(() => {
		disableBodyScroll(document.body, disableScrollOptions);
		const currentPageYOffset = pageYOffset;
		window.scrollTo(0, currentPageYOffset);
		return () => {
			enableBodyScroll(document.body);
		};
	}, []);

	useEffect(() => {
		if (isClosePopupByParent) setIsClosePopup(isClosePopupByParent);
	}, [isClosePopupByParent]);

	return (
		<div
			className={cx(styles.wrapper, parentClassName, {
				[styles.close]: isClosePopup,
				[styles.padding_disabled]: isDisablePadding,
				[styles.fixed]: isFixedStyle,
				[styles.broadcast]: isBroadcastStyle,
				[styles.photo_container]: isPhotoStyle && !isPhotoStyleUpdating,
				[styles.photo_container_updating]: isPhotoStyleUpdating,
				[styles.contentCenter]: isContentCenter,
				[styles.contentHeightAuto]: isContentHeightAuto,
				[styles.contentWidthAuto]: isContentWidthAuto,
			})}
			ref={containerRef}
		>
			<div
				className={cx(styles.content__container, containerClassName, {
					[styles.marginTop_disabled]: isDisableContentMarginTop,
					[styles.broadcast]: isBroadcastStyle,
					[styles.photo]: isPhotoStyle || isPhotoStyleUpdating,
					[styles.contentFullWidth]: isContentFullWidth,
				})}
				ref={contentRef}
			>
				{!isDisableCloseButton && (
					<ButtonCross
						clickHandler={closeButtonParentClickHandler || closePopupClickHandler}
						parentClassName={styles.button_cross}
					/>
				)}
				{children}
			</div>
		</div>
	);
};

export default PopupContainer;
