import { FC, ReactNode } from 'react';

// icons
import { ReactComponent as NoTableItemsImage } from 'assets/image/no-items-image.svg';

// styles
import styles from './TableEmptyState.module.scss';

interface TableEmptyStateProps {
	title?: string; // Custom title (e.g., "No Items", "Oops")
	message?: string; // Custom message (e.g., "No data available")
	status?: 'error' | 'empty'; // State type (error or empty)
	icon?: ReactNode; // Allow custom icons/SVGs
	className?: string; // Custom class override
}

export const TableEmptyState: FC<TableEmptyStateProps> = ({
	title = 'Oops',
	message,
	status = 'empty',
	icon,
	className,
}) => {
	return (
		<div className={`${styles.noItemsContainer} ${className || ''}`}>
			<div className={styles.noItemsContainer__gradient} />
			{icon || <NoTableItemsImage className={styles.noItemsImage} />}
			<div className={styles.noItemsTextContainer}>
				<span className={styles.noItemsTitle}>{title}</span>
				<span className={styles.noItemsText}>
					{message ||
						(status === 'error' ? 'Something went wrong.' : 'There are currently no items available.')}
				</span>
			</div>
		</div>
	);
};
