import * as Yup from 'yup';
import { DONATION_TYPE } from '@joc/api-gateway/lib/api-client';

export const MAX_TITLE_LENGTH = 40;
export const MAX_SUB_TITLE_LENGTH = 30;

const mediaPathesSchema = Yup.array().test('required', 'Image is required', value => {
	if (value) {
		return !!value.length;
	}

	return true;
});

const titleSchema = Yup.string()
	.min(2, 'Title should be no less than 2 characters')
	.max(MAX_TITLE_LENGTH, `Title must be at most ${MAX_TITLE_LENGTH} characters`)
	.required('Title is required');

const subTitleSchema = Yup.string()
	.min(2, 'Subtitle should be no less than 2 characters')
	.max(MAX_SUB_TITLE_LENGTH, `Subtitle must be at most ${MAX_SUB_TITLE_LENGTH} characters`)
	.optional();

const descriptionSchema = Yup.string()
	.test('Description is required', 'Description is required', description => {
		const element = document.createElement('div');
		element.innerHTML = description?.trim() || '';

		return !!element.innerText.trim()?.length;
	})
	.test('minDescription', 'Description should be no less than 2 characters', description => {
		const element = document.createElement('div');
		element.innerHTML = description || '';

		return !(element.innerText?.length !== 0 && element.innerText?.length <= 1);
	})
	.required('Description is required');

const expirationDate = Yup.date().required('Expiration Date is required').typeError('Please, select valid date');

export const donationItemValidationSchema = (donationType: DONATION_TYPE) =>
	Yup.object().shape({
		mediaPathes: mediaPathesSchema,
		title: titleSchema,
		subtitle: subTitleSchema,
		description: descriptionSchema,
		price:
			donationType === DONATION_TYPE.CAMPAIGN
				? Yup.number()
						.integer('Donation goal should have digits only')
						.typeError('Donation goal must be a number')
						.min(1, 'Donation goal must be at least 1')
						.max(999999, 'Donation goal must not exceed 999,999')
						.nullable()
				: Yup.number()
						.integer('Donation Amount should have digits only')
						.required('Donation Amount is required')
						.typeError('Donation Amount must be a number')
						.min(1, 'Donation Amount must be at least 1')
						.max(999999, 'Donation Amount must not exceed 999,999')
						.nullable(),

		...(donationType === DONATION_TYPE.CAMPAIGN ? { expirationDate } : {}),
	});

export enum DonationTypeEnum {
	CampaignDonation = 'Campaign Donation',
	DedicatedDonation = 'Dedicated Donation',
}
