import { FC, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';

// new material-ui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// components
import FieldWrapper from 'shared/components/Inputs/FieldWrapper';

// types
import { AppDateTimePickerProps } from '../types';

// styled
import { DatePickerStyled } from './styles';

export const DatePicker: FC<AppDateTimePickerProps> = fieldBaseProps => {
	const [value, setValue] = useState<Moment | null>(null);
	const { field, form, disabled } = fieldBaseProps;
	const { setFieldValue, setFieldTouched } = form;

	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const [isHighlight, setIsHighlight] = useState(false);
	const handleClosePicker = () => setIsPickerOpen(false);

	const inputRef = useRef<HTMLImageElement>();

	const onChange = (date: Moment | null) => {
		if (fieldBaseProps.disablePast) {
			setFieldValue(field.name, date?.isBefore(moment()) ? moment().toDate() : date?.toDate()).finally();
		} else {
			setFieldValue(field.name, date).finally();
		}
		setIsHighlight(true);
	};

	const onTouchHandler = () => {
		setFieldTouched(field.name, true).finally();
	};

	useEffect(() => {
		setIsHighlight(!!field?.value);
		setValue(moment(field.value));
	}, [field.value]);

	return (
		<FieldWrapper {...fieldBaseProps}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DatePickerStyled
					disabled={disabled}
					value={value}
					onChange={onChange}
					onSelectedSectionsChange={onTouchHandler}
					selectedSections={null}
					open={isPickerOpen}
					onOpen={() => setIsPickerOpen(!isPickerOpen)}
					disablePast={fieldBaseProps?.disablePast}
					disableFuture={fieldBaseProps?.disableFuture}
					onAccept={handleClosePicker}
					onClose={() => handleClosePicker()}
					views={['month', 'year', 'day']}
					slotProps={{
						textField: {
							inputRef,
							onClick: () => {
								inputRef.current?.blur();
								setIsPickerOpen(true);
							},
							sx: {
								'.MuiOutlinedInput-input': {
									color: isHighlight ? '#1C2F4C !important' : '#5B768B !important',
								},
							},
						},
						day: {
							sx: {
								'&.Mui-selected': {
									backgroundColor: '#214ba5 !important',

									'&:hover': {
										backgroundColor: '#214ba5',
									},
								},
							},
						},
						actionBar: {
							sx: {
								'& .MuiButtonBase-root': {
									color: '#214ba5',
								},
							},
						},
						dialog: {
							sx: {
								'.MuiPaper-root': {
									borderRadius: '10px',
								},
								'button, li': {
									borderRadius: '50%',

									'&:focus': {
										backgroundColor: 'unset',
									},
									'&.Mui-selected': {
										backgroundColor: '#214ba5 !important',

										'&:hover': {
											backgroundColor: '#214ba5',
										},
									},
								},
							},
						},
						popper: {
							sx: {
								'.MuiPaper-root': {
									borderRadius: '10px',
								},
								'button, li': {
									borderRadius: '50%',

									'&:focus': {
										backgroundColor: 'unset',
									},
									'&.Mui-selected': {
										backgroundColor: '#214ba5 !important',

										'&:hover': {
											backgroundColor: '#214ba5',
										},
									},
								},
							},
						},
					}}
				/>
			</LocalizationProvider>
		</FieldWrapper>
	);
};
