import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment-mini';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from '@tanstack/react-query';

// components
import ImageCell from 'shared/components/Table/CellRenderers/ImageCell';
import CustomTooltip from 'shared/components/Tooltip';
import Loader from 'shared/components/Loader';
import { SnackbarError, SnackbarSuccess } from 'shared/components/_Snackbars';
import SortableHeaderItem from './SortableHeaderItem';
import DonationItemPopup from '../../DonationPopups/DonationItemPopup';
import DonationInfoPopup from '../../DonationPopups/DonationInfoPopup';
import { TableActionItem, TableActions } from 'shared/components/Table/TableActions';
import VisibilityTableItem from '../../../VisibilityTableItem';
import { TableEmptyState } from 'shared/components/Table/TableEmptyState';

// hooks
import { useDeleteDonationItem, useDonationItems, usePublishDonationItem } from 'hooks/store';

// helpers
import { STORE_DONATIONS_QUERY_KEY } from 'core/constants';

// icons
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';
import { ReactComponent as EditSvg } from 'assets/image/icons/edit-new.svg';
import { ReactComponent as DeleteSvg } from 'assets/image/icons/trash-new.svg';

// types
import {
	DONATION_POSSIBLE_SORT,
	DONATION_TYPE,
	DonationResponse,
	DonationSort,
	SortDirection,
	IDonationResponse,
} from '@joc/api-gateway/lib/api-client';
import { DonationItemResponseType } from '../../types';

// styles
import styles from './DonationTable.module.scss';

type StoreTableProps = {
	search: string;
};

const DonationItemsTable: FC<StoreTableProps> = ({ search }) => {
	const queryClient = useQueryClient();

	const [isItemPublished, setIsItemPublished] = useState(false);
	const [isItemHidden, setIsItemHidden] = useState(false);
	const [isMissingCoupons, setIsMissingCoupons] = useState(false);
	const [isItemExpired, setIsItemExpired] = useState(false);
	const [isApiError, setIsApiError] = useState(false);

	const [popupEditOpen, setPopupEditOpen] = useState<DonationResponse | null>(null);
	const [popupDonationInfoOpen, setPopupDonationInfoOpen] = useState<DonationResponse | null>(null);

	const [totalFetchedDonationItems, setTotalFetchedDonationItems] = useState(0);
	const [sort, setSort] = useState<DonationSort>();

	const {
		data,
		isError: isFetchDonationItemsError,
		isFetching,
		isFetched,
		status,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useDonationItems(search, sort);
	const { deleteDonationItem } = useDeleteDonationItem();

	const {
		publishDonationItem,
		data: storeItemPublishResponse,
		isError: isPublishError,
		isSuccess: isPublishSuccess,
	} = usePublishDonationItem();

	const { ref, inView } = useInView({
		threshold: 0,
		delay: 200,
	});

	const handlePublishDonationItem = useCallback(async (donationItem: IDonationResponse) => {
		try {
			const itemIsExpired = moment(donationItem.expirationDate).isBefore(moment());
			if (!itemIsExpired) {
				publishDonationItem({ id: donationItem.id });
			} else {
				setIsItemExpired(true);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}, []);

	useEffect(() => {
		if (storeItemPublishResponse?.isPublished && isPublishSuccess) setIsItemPublished(true);
		if (!storeItemPublishResponse?.isPublished && isPublishSuccess) setIsItemHidden(true);

		if (isPublishError || isFetchDonationItemsError) setIsApiError(true);
	}, [storeItemPublishResponse, isPublishSuccess, isPublishError, isFetchDonationItemsError]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	useEffect(() => {
		setTotalFetchedDonationItems(
			([] as Array<DonationResponse>).concat(...(data?.pages.map(page => page?.records) || [])).length
		);
	}, [data]);

	const donationTypeDisplayName = (donationType: DONATION_TYPE) =>
		donationType === DONATION_TYPE.CAMPAIGN ? 'Campaign' : 'Dedicated Donation';

	const handleSortChange = useCallback((sortBy: DONATION_POSSIBLE_SORT, clickedDirection?: SortDirection) => {
		queryClient.removeQueries({ queryKey: [STORE_DONATIONS_QUERY_KEY] });

		setSort(prevSort => {
			if (prevSort?.sortBy === sortBy && clickedDirection && prevSort?.sortDir === clickedDirection) {
				return undefined;
			}

			if (prevSort?.sortBy === sortBy && !clickedDirection) {
				if (prevSort.sortDir === SortDirection.ASC) {
					return DonationSort.fromJS({ ...prevSort, sortDir: SortDirection.DESC });
				}
				return undefined;
			}

			return DonationSort.fromJS({
				sortBy,
				sortDir: clickedDirection ?? SortDirection.ASC,
			});
		});
	}, []);

	const editPopupHandler = (donationItem?: DonationResponse) => {
		setPopupEditOpen(donationItem || null);
	};

	const donationInfoPopupHandler = (donationItem?: DonationResponse) => {
		setPopupDonationInfoOpen(donationItem || null);
	};

	return (
		<section
			className={cx(styles.donationTable_wrapper, {
				[styles.overflow__hidden]: status === 'error' || (isFetched && !totalFetchedDonationItems),
				[styles.overflow_y__hidden]: status === 'pending' && !totalFetchedDonationItems,
			})}
		>
			<table className={styles.donationTable}>
				<thead className={styles.donationTable_thead}>
					<tr>
						<th align="left" className={styles.donationTable_thead_photo}>
							Photo
						</th>
						<th align="left" className={styles.donationTable_thead_title}>
							Title
						</th>
						<th align="left" className={styles.donationTable_thead_type}>
							Type
						</th>
						<th align="left" className={styles.donationTable_thead_coins}>
							<div className={styles.donationTable_thead_coins__wrapper}>
								<span>Coins</span>
								<CustomTooltip
									style={{ cursor: 'pointer' }}
									PopperProps={{
										className: cx(
											styles.donationTable_thead_tooltip,
											styles.donationTable_thead_coins__topltip
										),
									}}
									title={
										<div className={styles.donationTable_thead_tooltip_content}>
											<span>
												This column displays two types of coins depending on the Donation Type
												(Depends on Donation Type):
											</span>
											<span>
												• Campaign Goal – the total number of coins required to reach the
												campaign goal.
											</span>
											<span>
												• Suggested Coin Amount – the recommended amount of coins for
												contribution.
											</span>
										</div>
									}
									arrow={true}
									placement="top"
								>
									<InfoSvg width={16} height={16} />
								</CustomTooltip>
							</div>
						</th>
						<th align="left" className={styles.donationTable_thead_totalDonations}>
							<div className={styles.donationTable_thead_coins__wrapper}>
								<span>Total Donations</span>
								<CustomTooltip
									style={{ cursor: 'pointer' }}
									PopperProps={{
										className: cx(
											styles.donationTable_thead_tooltip,
											styles.donationTable_thead_totalDonations__topltip
										),
									}}
									title={
										<div
											className={styles.donationTable_thead_tooltip_content}
											style={{ textAlign: 'unset' }}
										>
											<span>Total Donations (depends on Donation Type):</span>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													paddingLeft: '12px',
												}}
											>
												<span>• Campaign – Number of Coins Raised.</span>
												<span>• Dedicated Donation – Number of Donations.</span>
											</div>
										</div>
									}
									arrow={true}
									placement="top"
								>
									<InfoSvg width={16} height={16} />
								</CustomTooltip>
							</div>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.donationTable_thead_visibility}>
							<SortableHeaderItem
								sortBy={DONATION_POSSIBLE_SORT.IsPublished}
								title="Visibility"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.donationTable_thead_actions} aria-label="Actions" />
					</tr>
				</thead>
				<tbody className={styles.donationTable_tbody}>
					{data?.pages.flatMap(page =>
						page?.records.map(item => (
							<tr
								key={item.id}
								className={cx({
									[styles.donationTable_tbody_suspended]: !item.isPublished
										? true
										: item.expirationDate
										? moment(item.expirationDate).isBefore(moment())
										: !item.isPublished,
								})}
							>
								<td align="left" className={styles.donationTable_tbody_photo}>
									<ImageCell
										imagePath={(item?.mediaPathes as unknown as Array<string>)?.[0]}
										title={item.title}
										withPopup={true}
									/>
								</td>
								<td align="left" className={styles.donationTable_tbody_title}>
									<span title={item.title}>{item.title}</span>
								</td>
								<td align="left" className={styles.donationTable_tbody_type}>
									<span title={String(donationTypeDisplayName(item.donationType))}>
										{donationTypeDisplayName(item.donationType)}
									</span>
								</td>
								<td align="left" className={styles.donationTable_tbody_coins}>
									<span>
										{item.donationType === DONATION_TYPE.CAMPAIGN
											? item.goal
											: item.suggestedCoinAmount}
									</span>
								</td>
								<td align="left" className={styles.donationTable_tbody_totalDonations}>
									<span>
										{item.donationType === DONATION_TYPE.CAMPAIGN
											? item.coinAmount
											: item.donaterCount}
									</span>
								</td>
								<td align="left" className={styles.donationTable_tbody_visibility}>
									<VisibilityTableItem
										isPublished={
											!(!item.isPublished
												? true
												: item.expirationDate
												? moment(item.expirationDate).isBefore(moment())
												: !item.isPublished)
										}
										onTogglePublishItem={() => handlePublishDonationItem(item)}
										tooltip="Toggle visible\hidden donation"
									/>
								</td>
								<td align="left" className={styles.donationTable_tbody_actions}>
									<TableActions>
										{!item.expirationDate ? (
											<TableActionItem
												icon={<EditSvg title="Edit" width={24} height={24} />}
												label="Edit"
												onClick={() => editPopupHandler(item)}
											/>
										) : moment(item.expirationDate).isAfter(moment()) ? (
											<TableActionItem
												icon={<EditSvg title="Edit" width={24} height={24} />}
												label="Edit"
												onClick={() => editPopupHandler(item)}
											/>
										) : null}

										<TableActionItem
											icon={<DeleteSvg title="Delete" width={24} height={24} />}
											label="Delete"
											onClick={() => deleteDonationItem(item.id)}
										/>
										<TableActionItem
											icon={<InfoSvg title="Info" width={24} height={24} />}
											label="Donations info"
											onClick={() => donationInfoPopupHandler(item)}
										/>
									</TableActions>
								</td>
							</tr>
						))
					)}
					{!isFetching && (
						<tr style={{ height: 1 }} ref={ref}>
							<td style={{ padding: 0 }}>
								<div style={{ height: '1px', visibility: 'hidden' }} />
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{isFetchingNextPage || status === 'pending' ? <Loader /> : null}

			{!isFetching && !totalFetchedDonationItems && (
				<TableEmptyState
					message={
						status === 'error'
							? 'Something went wrong.'
							: 'There are currently no donation items in the list. Create them to see them here.'
					}
				/>
			)}

			<SnackbarSuccess
				open={isItemPublished}
				onClose={setIsItemPublished}
				message="Item has been published successfully!"
				width={274}
			/>

			<SnackbarSuccess
				open={isItemHidden}
				onClose={setIsItemHidden}
				message="Item has been hidden successfully!"
				width={274}
			/>

			<SnackbarError
				open={isMissingCoupons}
				onClose={setIsMissingCoupons}
				message="This item cannot be displayed because it has no available coupons."
				width={300}
				height={64}
			/>

			<SnackbarError
				open={isItemExpired}
				onClose={setIsItemExpired}
				message="This item has expired and cannot be made visible."
				width={336}
			/>

			<SnackbarError open={isApiError} onClose={setIsApiError} message="Something went wrong." />

			{!!popupEditOpen && (
				<DonationItemPopup
					open={!!popupEditOpen}
					donationItem={popupEditOpen as unknown as DonationItemResponseType}
					onClose={editPopupHandler}
				/>
			)}

			{!!popupDonationInfoOpen && (
				<DonationInfoPopup
					fileName={popupDonationInfoOpen.title}
					donationItemId={popupDonationInfoOpen.id}
					open={!!popupDonationInfoOpen}
					onClose={donationInfoPopupHandler}
				/>
			)}
		</section>
	);
};

export default DonationItemsTable;
