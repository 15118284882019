import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

// helpers
import { specialOfferValidationSchema } from '../helpers';
import { API } from 'core/API';
import { sendFilesToS3Bucket } from 'core/functions';

// hooks
import { useCreateOffer, useEditOffer } from 'hooks/store';

// components
import Dialog from 'shared/components/newUI/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import SpecialOfferForm from '../SpecialOfferForm';
import Loader from 'shared/components/Loader';
import SpecialOfferPreview from '../SpecialOfferPreview';

// types
import { IOfferRequest, OfferResponse, SearchStoreItemsRequest } from '@joc/api-gateway/lib/api-client';
import { IUpdateOfferRequest, StoreItemResponse } from '@joc/api-gateway';

// styles
import styles from './SpecialOfferPopup.module.scss';
import moment from 'moment-mini';

type SpecialOfferPopupTypes = {
	specialOffer?: OfferResponse;
	open: boolean;
	onClose: () => void;
};

export type SpecialOfferFormType = Omit<IOfferRequest, 'mediaPath'> & {
	itemType: 'select' | 'url';
	mediaPath: File;
};

const SpecialOfferPopup: FC<SpecialOfferPopupTypes> = ({ specialOffer, open = true, onClose }) => {
	const [storeItems, setStoreItems] = useState<Array<StoreItemResponse>>([]);
	const { mutate: createOffer, isPending: isCreatePending, isSuccess: isCreateSuccess } = useCreateOffer();
	const { mutate: editOffer, isPending: isEditPending, isSuccess: isEditSuccess } = useEditOffer();
	const [isMediaUploading, setIsMediaUploading] = useState(false);

	const formikRef = useRef<FormikProps<SpecialOfferFormType>>(null);

	const [isPreview, setIsPreview] = useState(false);
	const [isItemsLoading, setIsItemsLoading] = useState(true);

	const initialValues: SpecialOfferFormType = useMemo(
		() =>
			specialOffer
				? ({
						name: specialOffer.name,
						title: specialOffer.title,
						linkTitle: specialOffer.linkTitle,
						mediaPath: specialOffer.mediaPath,
						link: specialOffer.link || '',
						storeItemId: specialOffer.storeItemId || '',
						itemType: specialOffer.link ? 'url' : 'select',
				  } as unknown as SpecialOfferFormType)
				: ({
						name: '',
						title: '',
						linkTitle: '',
						mediaPath: '',
						link: '',
						storeItemId: '',
						itemType: 'select',
				  } as unknown as SpecialOfferFormType),
		[specialOffer]
	);

	const handleSubmit = async (values: SpecialOfferFormType) => {
		setIsMediaUploading(true);
		const [imageUrl] =
			values.mediaPath instanceof File ? await sendFilesToS3Bucket([values.mediaPath]) : [values.mediaPath];
		setIsMediaUploading(false);

		const offer = {
			name: values.name,
			title: values.title,
			storeItemId: values.itemType === 'select' ? Number(values.storeItemId) : null,
			link: values.itemType === 'url' ? values.link : null,
			linkTitle: values.linkTitle,
			mediaPath: imageUrl,
		} as IOfferRequest;

		if (specialOffer) {
			editOffer({ id: specialOffer.id, offer: offer as unknown as IUpdateOfferRequest });
		} else {
			createOffer(offer);
		}
	};

	useEffect(() => {
		const fetchStoreItems = async () => {
			const { records } = await API.searchStoreItemByAdmin(SearchStoreItemsRequest.fromJS({}));
			setStoreItems(records);
			setIsItemsLoading(false);
		};

		fetchStoreItems().finally();
	}, []);

	const filteredStoreItems = useMemo(() => {
		if (storeItems.length) {
			return storeItems.filter(
				storeItem =>
					(storeItem.isPublished &&
						storeItem.quantity &&
						(storeItem.expirationDate ? moment(storeItem.expirationDate).isAfter(moment()) : true)) ||
					(specialOffer?.storeItemId && Number(specialOffer.storeItemId) === Number(storeItem.id))
			);
		}

		return [];
	}, [storeItems, specialOffer]);

	useEffect(() => {
		if (isCreateSuccess || isEditSuccess) onClose();
	}, [isCreateSuccess, isEditSuccess]);

	const togglePreview = () => {
		setIsPreview(!isPreview);
	};

	return (
		<Dialog open={open} maxWidth="xl" isFullScreen={true}>
			<section className={styles.specialOfferPopup}>
				<span className={styles.specialOfferPopup__title}>
					{specialOffer ? 'Edit' : 'Create'} Special Offer
				</span>
				<ButtonCross clickHandler={onClose} parentClassName={styles.specialOfferPopup__close} />

				{!isItemsLoading ? (
					<Formik
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={specialOfferValidationSchema}
						innerRef={formikRef}
						enableReinitialize={true}
					>
						<SpecialOfferForm
							isNew={!specialOffer}
							storeItems={filteredStoreItems}
							onCancel={onClose}
							onPreviewOpen={togglePreview}
							isSaving={isMediaUploading || isCreatePending || isEditPending}
						/>
					</Formik>
				) : (
					<Loader loadProps={{ stylePageCenter: true }} />
				)}
			</section>
			{isPreview
				? formikRef.current?.values && (
						<SpecialOfferPreview offer={formikRef.current.values} onClose={togglePreview} />
				  )
				: null}

			{isCreatePending || isEditPending ? <Loader loadProps={{ stylePageCenter: true }} /> : null}
		</Dialog>
	);
};

export default SpecialOfferPopup;
