import React, { FC, useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import cx from 'classnames';

// components
import { UploadCouponsField } from './UploadCouponsField';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import { SnackbarSuccess } from 'shared/components/_Snackbars';
import Loader from 'shared/components/Loader';

// icons
import { ReactComponent as CopyIcon } from 'assets/image/icons/copy-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/image/icons/trash-new.svg';

// helpers
import { handleCopy } from '../../helpers';

// types
import { StoreCouponRequest } from '@joc/api-gateway/lib/api-client';
import { UploadedCoupon } from '../../../types';

// styles
import styles from './StoreCouponsListForm.module.scss';

type SuperAdminStoreFormProps = {
	isSaving: boolean;
	onClose?: () => void;
};

const StoreCouponsListForm: FC<SuperAdminStoreFormProps> = ({ isSaving, onClose }) => {
	const { setFieldValue, dirty, isValid } = useFormikContext<Array<StoreCouponRequest>>();

	const [errorsCount, setErrorsCount] = useState(0);
	const [uploadedCoupons, setUploadedCoupons] = useState<Array<UploadedCoupon>>([]);
	const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);

	const handleParse = (coupons: Array<UploadedCoupon>) => setUploadedCoupons(coupons);

	const handleCancel = () => onClose?.();

	useEffect(() => {
		setFieldValue(
			'coupons',
			uploadedCoupons.map(coupon => StoreCouponRequest.fromJS(coupon))
		).finally();
	}, [uploadedCoupons]);

	const downloadTemplate = () => {
		const templateFileLink = `${process.env.PUBLIC_URL}/assets/documents/importCouponsTemplate.xlsx`;

		fetch(templateFileLink)
			.then(resp => (resp.status === 200 ? resp.blob() : Promise.reject(new Error('error'))))
			.then(blob => {
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = 'coupons_template.xlsx';

				document.body.appendChild(a);
				a.click();

				window.URL.revokeObjectURL(url);
			});
	};

	const handleCopyCoupon = async (coupon: string) => {
		setIsOpenSnackbar(true);
		await handleCopy(coupon);
	};

	const handleDeleteCoupon = (couponIndex: number) =>
		setUploadedCoupons(prevState => prevState.filter((_coupon, index) => index !== couponIndex));

	const handleClearAll = () => setUploadedCoupons([]);

	return (
		<Form className={styles.couponsListForm}>
			<div className={styles.couponsListForm__input}>
				<UploadCouponsField handleInput={handleParse} setErrorsCount={setErrorsCount} />
				<button className={styles.templateDownload} type="button" onClick={downloadTemplate}>
					Download list template
				</button>
			</div>

			{uploadedCoupons.length ? (
				<>
					<div className={styles.couponsListForm__table_header}>
						<span>
							Detected {uploadedCoupons.length} Coupons
							{errorsCount ? (
								<>
									{', '}
									<span style={{ color: '#D00074' }}>{`but ${errorsCount} have errors.`}</span>
								</>
							) : (
								<></>
							)}
						</span>

						<button
							title="Clear all"
							className={styles.couponsListForm__table_clearAllButton}
							onClick={handleClearAll}
						>
							Clear all
						</button>
					</div>

					<table className={styles.couponsListForm__table}>
						<thead className={styles.couponsListForm__table__thead}>
							<tr>
								<th align="left" className={styles.couponsListForm__table__thead_ID}>
									ID
								</th>
								<th align="left" className={styles.couponsListForm__table__thead_Coupons}>
									Coupons
								</th>
								<th align="left" className={styles.couponsListForm__table__thead_Actions}>
									Actions
								</th>
							</tr>
						</thead>
						<tbody className={styles.couponsListForm__table__tbody}>
							{uploadedCoupons.map((coupon, index) => (
								<tr key={`coupon_${coupon}_${index}`}>
									<td align="left" className={styles.couponsListForm__table__tbody_ID}>
										{index + 1}
									</td>
									<td align="left" className={styles.couponsListForm__table__tbody_Coupons}>
										{coupon.couponCode}
									</td>
									<td align="left" className={styles.couponsListForm__table__tbody_Actions}>
										<div>
											<CopyIcon onClick={() => handleCopyCoupon(coupon.couponCode)} />
											<TrashIcon onClick={() => handleDeleteCoupon(index)} />
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			) : null}
			<div className={styles.couponsListForm__actions}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.couponsListForm__actions_button}
					title="Cancel"
					clickHandler={handleCancel}
				/>
				<div className={styles.couponsListForm__actions_button}>
					{isSaving ? (
						<Loader
							loadProps={{
								disableCenterStyle: true,
								parentClassName: styles.couponsListForm__actions_button_loader,
							}}
						/>
					) : (
						<ButtonDefault
							submitType={true}
							classList={['primary', 'lg']}
							parentClassName={cx(styles.storeCouponForm__actions_button, {
								[styles.disable_submit]: !dirty || !isValid,
							})}
							title="Create"
							disabled={!dirty || !isValid}
						/>
					)}
				</div>
			</div>
			<SnackbarSuccess
				open={isOpenSnackbar}
				onClose={setIsOpenSnackbar}
				message="Coupon code has been copied successfully!"
				width={308}
			/>
		</Form>
	);
};

export default StoreCouponsListForm;
