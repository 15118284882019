import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { setFullName } from 'core/functions';
import styles from './FullNameCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';
import VolunteerBadges from '../../VolunteerBadges';

type FullNameCellProps = {
	firstName: string | undefined;
	lastName: string | undefined;
	createDate?: Date;
	disableTextStyle?: boolean;
	isShowMemberBadge?: boolean;
	isUnsetMaxWidth?: boolean;
	crop?: boolean;
};

const FullNameCell: FC<FullNameCellProps> = ({
	firstName,
	lastName,
	createDate,
	disableTextStyle,
	isShowMemberBadge,
	isUnsetMaxWidth,
	crop,
}: FullNameCellProps) => {
	const fullName = useMemo(() => setFullName(firstName, lastName), [firstName, lastName]);

	return (
		<div
			className={cx(styles.fullName, defaultStyles.main, {
				[styles.fullName_crop]: crop,
				[styles.unsetMaxWidth]: isUnsetMaxWidth,
			})}
		>
			<div className={styles.fullName__content}>
				<VolunteerBadges createDate={createDate} isShowMemberBadge={isShowMemberBadge} />
				<span className={cx(styles.text, !disableTextStyle && styles.styled_text)} title={fullName}>
					{fullName}
				</span>
			</div>
		</div>
	);
};

export default FullNameCell;
