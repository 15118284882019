import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import cx from 'classnames';

// hooks
import { useCachedTotalDonations, useCachedTotalOffers, useCachedTotalStoreItems } from 'hooks/store';

// components
import TabNavItem from 'shared/components/TabNav/TabNavItem';
import TabNav from 'shared/components/TabNav';

// types
import { StoreTabs } from './types';

// styles
import styles from './SuperAdminStore.module.scss';

type StoreManagementTabsProps = {
	activeTab: StoreTabs;
	// eslint-disable-next-line no-unused-vars
	setActiveTab: (tab: StoreTabs) => void;
};

export const StoreManagementTabs: FC<StoreManagementTabsProps> = ({ activeTab, setActiveTab }) => {
	const history = useHistory();
	const location = useLocation();

	const donationsTotal = useCachedTotalDonations();
	const storeItemsTotal = useCachedTotalStoreItems();

	const storeTabClickHandler = () => {
		history.push({ search: '?store' });
		setActiveTab(StoreTabs.Store);
	};
	const donationTabClickHandler = () => {
		history.push({ search: '?donation' });

		setActiveTab(StoreTabs.Donation);
	};

	useEffect(() => {
		if (location.search.includes('donation')) setActiveTab(StoreTabs.Donation);
		if (location.search.includes('store')) setActiveTab(StoreTabs.Store);
	}, [location.search]);

	useEffect(() => {
		if (!location.search) history.push({ search: '?store' });
	}, []);

	return (
		<TabNav className={styles.storeItems_tabs}>
			<TabNavItem
				parentClassName={styles.storeItems_tabs_item}
				title="Store"
				clickHandler={storeTabClickHandler}
				activeTab={activeTab === StoreTabs.Store}
				counter={storeItemsTotal}
			/>
			<TabNavItem
				parentClassName={styles.storeItems_tabs_item}
				title="Donation"
				clickHandler={donationTabClickHandler}
				activeTab={activeTab === StoreTabs.Donation}
				counter={donationsTotal}
			/>
		</TabNav>
	);
};
