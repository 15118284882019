import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOpposCounts, getOpportunitiesAll, getOpportunitiesAllInitial } from 'redux/opportunities-service/action';
import qs from 'qs';
// components
import TableHead from 'shared/components/Table/TableHead';
import TableRow from 'shared/components/Table/TableRow';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableBody from 'shared/components/Table/TableBody';
import TableMain from 'shared/components/Table/TableMain';
import MenuTable from 'components/Organization/TableMenu';
import ResponseFailure from 'shared/components/ResponseFailure';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import PossibleVolunteersCell from 'shared/components/Table/CellRenderers/PossibleVolunteersCell';
import OpportunityActionsCell from 'shared/components/Table/CellRenderers/OpportunityActionsCell';
import ManualOpportunityActions from 'shared/components/Table/CellRenderers/ManualOpportunityActions';
import OpportunityNameCell from 'shared/components/Table/CellRenderers/OpportunityNameCell';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import Observer from 'shared/components/Observer';
import Loader from 'shared/components/Loader';
// types
import {
	IFullTextSearchOpportunity,
	IHowFar,
	IOpportunityResponse,
	IPaginationRequest,
	ISearchOpportunitiesSort,
	ITimeRange,
	OPPORTUNITY_ACTIVE_STATUS,
	OPPORTUNITY_STATUSES,
	OPPORTUNITY_VOLUNTEER_STATUS,
	PaginationRequest,
	SEARCH_OPPORTUNITIES_POSSIBLE_SORT,
	SEARCH_OPPORTUNITY_POSSIBLE_SEARCH,
	SortDirection,
	WITH_WHO,
} from '@joc/api-gateway';
import { OPPORTUNITIES_TABS } from 'shared/components/OppListContent';
// core
import {
	convertOppoDateToHoursAndMinutes,
	formatDate,
	generateLocation,
	getImageType,
	getOpportunityImagePath,
	setSearchOrSortQueryString,
} from 'core/functions';
import { INITIAL_PAGINATION, TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES } from 'core/constants';
import { Pagination, SetPagination } from 'core/types';
// redux
import { Store } from 'redux/root';
import { resetError, setError } from 'redux/error-service/action';
import Actions from './Actions';
// styles
import styles from './index.module.scss';

const customErrorMessage = 'We couldn`t find the Chesed opportunities you were looking for this time.';

export type OpportunitiesRequestBody = {
	pagination?: IPaginationRequest;
	fullTextSearch?: IFullTextSearchOpportunity;
	organisationId?: number;
	volunteerStatus?: OPPORTUNITY_VOLUNTEER_STATUS[];
	weekDays?: number[];
	howFar?: IHowFar;
	whatTime?: ITimeRange;
	withWho?: WITH_WHO[];
	sort?: ISearchOpportunitiesSort;
	showVacancies?: boolean;
	volunteerRank?: number;
	status?: OPPORTUNITY_STATUSES[];
	oppoFilterType?: OPPORTUNITIES_TABS;
};

type OpportunitiesTableParentProps = {
	changeVisibilityFilters?: () => void;
	sort: ISearchOpportunitiesSort | undefined;
	pagination: Pagination;
	setPagination: SetPagination;
	requestBody: OpportunitiesRequestBody | undefined;
	setRequestBody: Dispatch<SetStateAction<OpportunitiesRequestBody | undefined>>;
	currentTab: OPPORTUNITIES_TABS;
};

const OpportunitiesTable: FC<OpportunitiesTableParentProps> = ({
	changeVisibilityFilters,
	sort,
	pagination,
	setPagination,
	requestBody,
	setRequestBody,
	currentTab,
}: OpportunitiesTableParentProps) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [isPrintableTable, setIsPrintableTable] = useState(false);
	const printRef = useRef<HTMLTableElement>(null);
	const [isLoading, setIsLoading] = useState(true);

	const opportunitiesRecordsList = useSelector((store: Store) => store.opportunitiesRedux.opportunitiesAll.records);
	const opportunitiesRecordsTotal = useSelector((store: Store) => store.opportunitiesRedux.opportunitiesAll.total);
	const errorMessage = useSelector((store: Store) => store.errorRedux.error.message);
	const errorState = useSelector((store: Store) => store.errorRedux.error.state);

	const buttonFailureClickHandler = () => {
		dispatch(resetError());
		history.push({ search: '' });
		setPagination(INITIAL_PAGINATION);
		setRequestBody(undefined);
	};

	const appendQueryString = (
		newQueryParams: IFullTextSearchOpportunity | ISearchOpportunitiesSort,
		searchBy: string
	): void => {
		const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
		history.push({ search: searchQuery });
	};

	const searchChangeHandler = (value: string): void => {
		const fullTextSearchParams: IFullTextSearchOpportunity = {
			value,
			fields: Object.values(SEARCH_OPPORTUNITY_POSSIBLE_SEARCH),
		};
		if (fullTextSearchParams.value.length) {
			appendQueryString(fullTextSearchParams, 'fullTextSearch');
		} else {
			const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
			delete searchParams.fullTextSearch;
			history.push({ search: qs.stringify(searchParams) });
		}
	};

	const getOpportunitiesAllRequest = useCallback(async () => {
		setIsLoading(true);
		try {
			await dispatch(
				(pagination.skip === 0 ? getOpportunitiesAll : getOpportunitiesAllInitial)({
					...requestBody,
					pagination: PaginationRequest.fromJS(pagination),
				})
			);
			setIsLoading(false);
		} catch (error: any) {
			setError(error?.response?.message || error.message, error?.response?.code || error.code);
			setIsLoading(false);
		}
	}, [pagination, requestBody]);

	useEffect(() => {
		getOpportunitiesAllRequest();
	}, [getOpportunitiesAllRequest]);

	const getCounts = () => {
		dispatch(getAllOpposCounts());
	};

	useEffect(() => {
		getCounts();
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<MenuTable searchChangeHandler={searchChangeHandler} changeVisibilityFilters={changeVisibilityFilters}>
					<Actions printRef={printRef} setIsPrintableTable={setIsPrintableTable} requestBody={requestBody} />
				</MenuTable>
				{(errorState && !isLoading) || (!isLoading && !opportunitiesRecordsList.length) ? (
					<ResponseFailure
						styleTable={true}
						message={errorMessage || customErrorMessage}
						buttonTitle="Get all"
						buttonClickHandler={buttonFailureClickHandler}
					/>
				) : !opportunitiesRecordsList.length && isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<TableMain ref={printRef}>
						<TableHead callChild="oppotunities">
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.logo}
								text={TABLE_HEADERS.logo}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.chesedName}
								text={TABLE_HEADERS.chesedName}
								clickHandler={() => {
									const newQueryParams: ISearchOpportunitiesSort = {
										sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.OpportunityName,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.date}
								text={TABLE_HEADERS.date}
								clickHandler={() => {
									const newQueryParams: ISearchOpportunitiesSort = {
										sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartDay,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.time}
								text={TABLE_HEADERS.start}
								clickHandler={() => {
									const newQueryParams: ISearchOpportunitiesSort = {
										sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.StartTime,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.time}
								text={TABLE_HEADERS.end}
								clickHandler={() => {
									const newQueryParams: ISearchOpportunitiesSort = {
										sortBy: SEARCH_OPPORTUNITIES_POSSIBLE_SORT.EndTime,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.location}
								text={TABLE_HEADERS.location}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.coordinator}
								text={TABLE_HEADERS.coordinator}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.volunteers}
								text={TABLE_HEADERS.volunteers}
								isSortDisabled={true}
							/>
							{!isPrintableTable && <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />}
						</TableHead>
						<TableBody>
							{opportunitiesRecordsList.map((opportunity: IOpportunityResponse) => {
								return (
									<TableRow
										key={opportunity.id}
										callChild="oppotunities"
										suspend={
											opportunity.opportunityActiveStatus.status ===
											OPPORTUNITY_ACTIVE_STATUS.SUSPENDED
										}
									>
										<AvatarCell
											imagePath={getOpportunityImagePath(opportunity)}
											opportunityType={getImageType(opportunity)}
											opportunity={opportunity}
											withPopup={true}
										/>
										<OpportunityNameCell
											opportunityName={opportunity.opportunityName}
											opportunityType={opportunity.opportunityType}
											volunteers={opportunity.volunteers}
											adminId={opportunity?.user?.id}
											isInitialOppo={opportunity.isInitial}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.date}
											text={formatDate(opportunity.endDate)}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.time}
											text={
												convertOppoDateToHoursAndMinutes(
													opportunity.startDate,
													opportunity.endDate
												).startDate
											}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.time}
											text={
												convertOppoDateToHoursAndMinutes(
													opportunity.startDate,
													opportunity.endDate
												).endDate
											}
										/>
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.location}
											text={
												opportunity.isVirtual
													? opportunity.opportunityUrl
													: generateLocation(opportunity.address)
											}
										/>
										<FullNameCell
											firstName={opportunity.user?.firstName}
											lastName={opportunity.user?.lastName}
											disableTextStyle={true}
										/>
										<PossibleVolunteersCell
											volunteers={opportunity.volunteers}
											opportunityId={opportunity.id}
											isApprovalRequired={opportunity.isApprovalRequired}
											opportunityName={opportunity.opportunityName}
											opportunityStatus={opportunity.status}
											opportunityType={opportunity.opportunityType}
											inPastOppoList={
												currentTab === OPPORTUNITIES_TABS.PAST ||
												opportunity.status === OPPORTUNITY_STATUSES.CLOSED
											}
										/>
										{!isPrintableTable && opportunity.status === OPPORTUNITY_STATUSES.PENDING ? (
											<ManualOpportunityActions opportunityId={opportunity.id} />
										) : (
											!isPrintableTable && (
												<OpportunityActionsCell opportunityData={opportunity} />
											)
										)}
									</TableRow>
								);
							})}
						</TableBody>
					</TableMain>
				)}
				{opportunitiesRecordsList.length !== opportunitiesRecordsTotal && (
					<Observer
						paginationSkip={opportunitiesRecordsList.length}
						setPagination={setPagination}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default OpportunitiesTable;
