import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IStoreItemRequest, StoreItemRequest, StoreItemResponse } from '@joc/api-gateway';
import { API } from '../../../core/API';
import { STORE_ITEMS_QUERY_KEY } from '../../../core/constants';

interface IUseCreateStoreItem {
	// eslint-disable-next-line no-unused-vars
	createStoreItem: (storeItem: IStoreItemRequest) => void;
}

const createNewStoreItem = async (storeItem: IStoreItemRequest) => {
	const body = {
		...StoreItemRequest.fromJS(storeItem),
		expirationDate: storeItem.expirationDate,
	} as unknown as StoreItemRequest;

	if (!body.countryIds?.length) delete body.countryIds;

	return API.createStoreItem(body);
};

export const useCreateStoreItem = (): IUseCreateStoreItem => {
	const queryClient = useQueryClient();
	const { mutate: createStoreItem } = useMutation<StoreItemResponse, Error, IStoreItemRequest>({
		mutationFn: storeItem => createNewStoreItem(storeItem),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_ITEMS_QUERY_KEY] }),
	});

	return { createStoreItem };
};
