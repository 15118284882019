import { FC, useEffect, useMemo, useState } from 'react';

// hooks
import { useDonationItems, useSpecialOffers } from '../../../hooks/store';

// components
import MainPageLayout from 'components/Organization/MainPageLayout';
import Donation from './Donation';
import Store from './Store';
import { StoreManagementTabs } from './StoreManagementTabs';

// types & enums
import { StoreTabs } from './types';

// styles
import styles from './SuperAdminStore.module.scss';

const SuperAdminStoreManagement: FC = () => {
	const [activeTab, setActiveTab] = useState<StoreTabs>(StoreTabs.Store);

	const { refetch: fetchDonations } = useDonationItems('', undefined, false);

	useEffect(() => {
		fetchDonations().finally();
	}, []);

	const ActiveTabComponent = useMemo(() => {
		switch (activeTab) {
			case StoreTabs.Donation:
				return <Donation />;
			case StoreTabs.Store:
				return <Store />;
			default:
				return <></>;
		}
	}, [activeTab]);

	return (
		<MainPageLayout>
			<div className={styles.storeItems}>
				<span className={styles.storeItems_title}>Store management</span>
				<StoreManagementTabs activeTab={activeTab} setActiveTab={tab => setActiveTab(tab)} />
				<section className={styles.storeItems_container}>{ActiveTabComponent}</section>
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminStoreManagement;
