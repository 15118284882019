import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router';
import moment from 'moment-mini';

// icons
import { ReactComponent as DeleteSvg } from 'assets/image/icons/trash-new.svg';
import { ReactComponent as EditSvg } from 'assets/image/icons/edit-new.svg';
import { ReactComponent as PreviewSvg } from 'assets/image/preview.svg';
import { ReactComponent as CopySvg } from 'assets/image/icons/copy-link.svg';

// components
import { TableActionItem, TableActions } from 'shared/components/Table/TableActions';
import { TableEmptyState } from 'shared/components/Table/TableEmptyState';
import Loader from 'shared/components/Loader';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import SpecialOfferPopup from '../SpecialOfferPopup';
import SpecialOfferPreview from '../SpecialOfferPreview';
import VisibilityTableItem from '../../../../../pages/Admin/SuperAdminStoreManagement/VisibilityTableItem';
import { SnackbarError } from '../../../_Snackbars';

// hooks
import { useSpecialOffers, usePublishOffer, useDeleteOffer } from 'hooks/store';

// constants
import { urls } from 'core/appUrls';

// types
import { OfferResponse, OfferSort } from '@joc/api-gateway/lib/api-client';

// styles
import styles from './SpecialOfferTable.module.scss';

const SpecialOfferTable: FC = () => {
	const history = useHistory();
	const [isStoreItemExpired, setIsItemExpired] = useState(false);

	const [openOfferPopup, setOpenOfferPopup] = useState<{ open: boolean; isPreview: boolean; offer?: OfferResponse }>({
		open: false,
		isPreview: false,
	});

	const [sort] = useState<OfferSort>();

	const { data, hasNextPage, fetchNextPage, isFetching, isFetched, status, refetch } = useSpecialOffers('', sort);
	const { publishOffer } = usePublishOffer();
	const { deleteOffer } = useDeleteOffer();

	const offerRecords = useMemo(() => data?.pages?.flatMap(page => page.records) || [], [data]);

	const { ref, inView } = useInView({
		threshold: 0,
		delay: 200,
	});

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	const handlePreviewClick = useCallback((offer: OfferResponse) => {
		setOpenOfferPopup({ open: true, isPreview: true, offer });
	}, []);

	const handleEditClick = useCallback((offer: OfferResponse) => {
		setOpenOfferPopup({ open: true, isPreview: false, offer });
	}, []);

	const handleDeleteClick = useCallback((offer: OfferResponse) => {
		deleteOffer(offer.id);
	}, []);

	const handlePublishOffer = useCallback((offer: OfferResponse) => {
		const storeItem = offer.storeItem;

		if (storeItem || offer.storeItemId) {
			if (
				!storeItem ||
				!storeItem.isPublished ||
				(storeItem.expirationDate ? moment(storeItem.expirationDate).isBefore(moment()) : false)
			) {
				setIsItemExpired(true);
			} else {
				publishOffer({ id: offer.id });
			}
		} else {
			publishOffer({ id: offer.id });
		}
		refetch();
	}, []);

	const togglePopupCreateHandler = useCallback(() => {
		setOpenOfferPopup({ open: true, isPreview: false });
	}, []);

	const togglePopupEditHandler = useCallback(() => {
		setOpenOfferPopup({ open: true, offer: offerRecords[0], isPreview: false });
	}, [offerRecords]);

	const handleCloseOfferPopup = () => {
		setOpenOfferPopup({ open: false, isPreview: false, offer: undefined });
	};

	const navigateToStoreItem = (storeItemId: number) => {
		history.push(`${urls.store}?store&item=${storeItemId}`);
	};

	const renderTableBody = () => {
		if (!offerRecords?.length) return null;

		return offerRecords.map(offer => (
			<tr
				key={offer.id}
				className={cx(styles.specialOfferTable__tbody__Row, {
					[styles.specialOfferTable__tbody_suspended]: !(offer.link
						? offer.isPublished
						: !!(offer.storeItemId && offer.storeItem && offer.storeItem.isPublished && offer.isPublished)),
				})}
			>
				<td align="left" className={styles.specialOfferTable__tbody__Photo}>
					<AvatarCell
						offerTitle={offer.title}
						imagePath={offer.mediaPath}
						withPopup={true}
						parentClassName={styles.specialOfferTable__tbody__Photo}
					/>
				</td>
				<td align="left" className={styles.specialOfferTable__tbody__Title}>
					<span className={styles.specialOfferTable__tbody__Text}>{offer.title}</span>
				</td>
				<td align="left" className={styles.specialOfferTable__tbody__ButtonName}>
					<span className={styles.specialOfferTable__tbody__Text}>{offer.linkTitle}</span>
				</td>
				<td align="left" className={styles.specialOfferTable__tbody__Visibility}>
					<span
						className={
							offer.isPublished
								? styles.specialOfferTable__statusPublished
								: styles.specialOfferTable__statusUnpublished
						}
					>
						<VisibilityTableItem
							isPublished={
								offer.link
									? offer.isPublished
									: !!(
											offer.storeItemId &&
											offer.storeItem &&
											offer.storeItem.isPublished &&
											offer.isPublished
									  )
							}
							onTogglePublishItem={() => handlePublishOffer(offer)}
							tooltip="Toggle visible\hidden special offer"
						/>
					</span>
				</td>
				<td align="left" className={styles.specialOfferTable__tbody__Url}>
					<span className={styles.specialOfferTable__tbody__Text}>
						{offer.storeItemId ? (
							<CopySvg
								width={24}
								height={24}
								style={{ cursor: 'pointer' }}
								onClick={() => navigateToStoreItem(offer.storeItemId as number)}
							/>
						) : (
							<a href={offer.link} target="_blank" rel="noreferrer" aria-label={offer.link}>
								<CopySvg title={offer.linkTitle} width={24} height={24} />
							</a>
						)}
					</span>
				</td>
				<td align="left" className={styles.specialOfferTable__tbody__Actions}>
					<TableActions>
						<TableActionItem
							icon={<PreviewSvg />}
							label="Preview"
							onClick={() => handlePreviewClick(offer)}
						/>
						<TableActionItem icon={<EditSvg />} label="Edit" onClick={() => handleEditClick(offer)} />
						<TableActionItem icon={<DeleteSvg />} label="Delete" onClick={() => handleDeleteClick(offer)} />
					</TableActions>
				</td>
			</tr>
		));
	};

	return (
		<>
			<div className={styles.specialOfferTable__header}>
				<ButtonDefault
					title={offerRecords?.length ? 'Edit' : '+ Create'}
					classList={['primary', 'sm']}
					parentClassName={styles.specialOfferTable__header_createButton}
					clickHandler={offerRecords?.length ? togglePopupEditHandler : togglePopupCreateHandler}
				/>
			</div>
			<div
				className={cx(styles.specialOfferTable_wrapper, {
					[styles.overflow__hidden]: status === 'error' || (isFetched && !offerRecords?.length),
					[styles.overflow_y__hidden]: status === 'pending' || isFetching,
				})}
			>
				{offerRecords?.length ? (
					<table className={styles.specialOfferTable}>
						<thead className={styles.specialOfferTable__thead}>
							<tr>
								<th align="left" className={styles.specialOfferTable__thead__Photo}>
									Photo
								</th>
								<th align="left" className={styles.specialOfferTable__thead__Title}>
									Special Offer Title
								</th>
								<th align="left" className={styles.specialOfferTable__thead__ButtonName}>
									Button Name
								</th>
								<th align="left" className={styles.specialOfferTable__thead__Visibility}>
									Visibility
								</th>
								<th align="left" className={styles.specialOfferTable__thead__Url}>
									URL/Linked item
								</th>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<th align="left" className={styles.specialOfferTable__thead__Actions} />
							</tr>
						</thead>
						<tbody className={styles.specialOfferTable__tbody}>
							{renderTableBody()}
							{!isFetching && (
								<tr style={{ height: 1 }} ref={ref}>
									<td style={{ padding: 0 }}>
										<div style={{ height: '1px', visibility: 'hidden' }} />
									</td>
								</tr>
							)}
						</tbody>
					</table>
				) : null}

				<SnackbarError
					open={isStoreItemExpired}
					onClose={setIsItemExpired}
					message="Shop Item is expired or has no coupons. Please check."
					width={360}
				/>

				{(status === 'pending' || isFetching) && <Loader />}

				{!isFetching && !offerRecords?.length && <TableEmptyState />}

				{!openOfferPopup.isPreview ? (
					<SpecialOfferPopup
						open={openOfferPopup.open}
						specialOffer={openOfferPopup.offer}
						onClose={handleCloseOfferPopup}
					/>
				) : openOfferPopup.isPreview ? (
					openOfferPopup.offer ? (
						<SpecialOfferPreview offer={openOfferPopup.offer} onClose={handleCloseOfferPopup} />
					) : null
				) : null}
			</div>
		</>
	);
};

export default SpecialOfferTable;
