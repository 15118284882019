import { createContext, Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
import { Store } from 'redux/root';
// redux
import { resetError } from 'redux/error-service/action';
// types
import {
	IFullTextSearchUsersForAdmin,
	ISearchUsersForAdminRequest,
	ISearchUsersSort,
	IUserResponse,
	OrganisationResponse,
	PaginationRequest,
	SEARCH_USER_BY_ADMIN_POSSIBLE_SEARCH,
	SortDirection,
	USERS_POSSIBLE_SORT,
} from '@joc/api-gateway';
import { SetPagination } from 'core/types';
// functions
import { setSearchOrSortQueryString } from 'core/functions';
import { getAllUserRolesInEachOrg } from '../EditUserCell/EditRolePopup/helpers';
// constants
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES, INITIAL_PAGINATION } from 'core/constants';
// components
import TableMain from 'shared/components/Table/TableMain';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableBody from 'shared/components/Table/TableBody';
import TableRow from 'shared/components/Table/TableRow';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import OrgContactCell from 'shared/components/Table/CellRenderers/OrgContactCell';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import TableMenu from 'components/Organization/TableMenu';
import RoleCell from 'shared/components/Table/CellRenderers/RoleCell';
import Observer from 'shared/components/Observer';
import EditUserCell from '../EditUserCell';
// styles
import styles from './UsersTable.module.scss';

type UserTableDataContextType = {
	setIsNeedCloseModal?: Dispatch<SetStateAction<boolean>>;
	isNeedCloseModal?: boolean;
	userData: IUserResponse;
};

export const UserTableDataContext = createContext<UserTableDataContextType>(
	null as unknown as UserTableDataContextType
);

export interface ISimpleRoleResponse {
	roleName: string;
	roleId?: number;
}

export interface ISortedRolesItem {
	organizationId?: string;
	organization?: OrganisationResponse;
	roleList: Array<ISimpleRoleResponse>;
}

type UsersTableParentProps = {
	setPagination: SetPagination;
	isLoading: boolean;
};

const UsersTable: FC<UsersTableParentProps> = ({ setPagination, isLoading }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const printRef = useRef<HTMLTableElement>(null);
	const [isNeedCloseModal, setIsNeedCloseModal] = useState<boolean>(false);

	const [requestBody, setRequestBody] = useState<ISearchUsersForAdminRequest>();
	const companiesUsersList = useSelector((store: Store) => store.companiesUsersRedux.records);
	const companiesUsersTotals = useSelector((store: Store) => store.companiesUsersRedux.totals);
	const errorState = useSelector((store: Store) => store.errorRedux.error.state);
	const errorMessage = useSelector((store: Store) => store.errorRedux.error.message);

	const buttonFailureClickHandler = async () => {
		dispatch(resetError());
		history.push({ search: '' });
		setRequestBody(undefined);
		setPagination(INITIAL_PAGINATION);
	};

	const appendQueryString = (
		newQueryParams: IFullTextSearchUsersForAdmin | ISearchUsersSort,
		searchBy: string
	): void => {
		const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
		history.push({ search: searchQuery });
	};

	const searchUsersChangeHandler = (value: string): void => {
		const fullTextSearchParams: IFullTextSearchUsersForAdmin = {
			value,
			fields: Object.values(SEARCH_USER_BY_ADMIN_POSSIBLE_SEARCH),
		};
		if (fullTextSearchParams.value.length) {
			appendQueryString(fullTextSearchParams, 'fullTextSearch');
		} else {
			const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
			delete searchParams.fullTextSearch;
			history.push({ search: qs.stringify(searchParams) });
		}
	};

	const sortClickHandler = (sortBy: USERS_POSSIBLE_SORT) => () => {
		const newQueryParams: ISearchUsersSort = {
			sortBy,
			sortDir: requestBody?.sort?.sortDir === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
		};
		appendQueryString(newQueryParams, 'sort');
	};

	const currentTotal = useMemo(() => {
		if (location.search.includes('admins')) {
			return companiesUsersTotals.admins;
		}
		if (location.search.includes('coordinators')) {
			return companiesUsersTotals.coordinators;
		}
		if (location.search.includes('marketers')) {
			return companiesUsersTotals.marketers;
		}
		return companiesUsersTotals.all;
	}, [location, companiesUsersTotals]);

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<TableMenu searchChangeHandler={searchUsersChangeHandler} />
				{(errorState && !isLoading) || (!isLoading && !companiesUsersList.length) ? (
					<ResponseFailure
						styleTable={true}
						message={errorMessage || 'We couldn`t find the volunteers you were looking for this time.'}
						buttonClickHandler={buttonFailureClickHandler}
						buttonTitle="Get all"
					/>
				) : !companiesUsersList.length && isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<TableMain ref={printRef}>
						<TableHead className={styles.table__header} callChild="users">
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.avatar}
								text={TABLE_HEADERS.avatar}
								isSortDisabled={true}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
								text={TABLE_HEADERS.fullName}
								clickHandler={sortClickHandler(USERS_POSSIBLE_SORT.FirstName)}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.contacts}
								text={TABLE_HEADERS.contacts}
								clickHandler={sortClickHandler(USERS_POSSIBLE_SORT.PhoneNumber)}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.organizations}
								text={TABLE_HEADERS.organizations}
								isSortDisabled={true}
							/>
							<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} isSortDisabled={true} />
						</TableHead>
						<TableBody>
							{companiesUsersList.map(user => (
								<div key={user.id}>
									<TableRow key={user.id} callChild="users">
										<AvatarCell imagePath={user.imagePath} user={user} withPopup={true} />
										<FullNameCell firstName={user.firstName} lastName={user.lastName} />
										<OrgContactCell phone={user.phoneNumber} email={user.email} />
										<RoleCell
											parentClassName={TABLE_ITEMS_CLASSNAMES.organizations}
											roles={getAllUserRolesInEachOrg(user.roles)}
										/>
										<UserTableDataContext.Provider
											value={{ isNeedCloseModal, setIsNeedCloseModal, userData: user }}
										>
											<EditUserCell />
										</UserTableDataContext.Provider>
									</TableRow>
								</div>
							))}
						</TableBody>
					</TableMain>
				)}
				{!!companiesUsersList.length && companiesUsersList.length < currentTotal && !errorState && (
					<Observer
						paginationSkip={companiesUsersList.length}
						setPagination={setPagination}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default UsersTable;
