import { FC } from 'react';
import cx from 'classnames';

// icons
import { ReactComponent as ArrowSortASC } from 'assets/image/icons/arrow-sort-asc.svg';
import { ReactComponent as ArrowSortDESC } from 'assets/image/icons/arrow-sort-desc.svg';

// types
import { DonationVolunteerSort, DONATION_VOLUNTEER_POSSIBLE_SORT, SortDirection } from '@joc/api-gateway';

// styles
import styles from '../../../../Store/StoreTables/StoreItemsTable/StoreTable.module.scss';

type SortableHeaderItemProps = {
	sortBy: DONATION_VOLUNTEER_POSSIBLE_SORT;
	title: string;
	// eslint-disable-next-line no-unused-vars
	handleSortChange: (sortBy: DONATION_VOLUNTEER_POSSIBLE_SORT, direction?: SortDirection) => void;
	sort?: DonationVolunteerSort;
};

const SortableHeaderItem: FC<SortableHeaderItemProps> = ({ sortBy, title, handleSortChange, sort }) => {
	return (
		<div className={styles.storeTable_thead_withSort}>
			<span onClick={() => handleSortChange(sortBy)}>{title}</span>
			<div className={styles.storeTable_thead_withSort_icon}>
				<ArrowSortASC
					className={
						(sort?.sortBy === sortBy &&
							cx({
								[styles.storeTable_thead_withSort_ASC]: sort?.sortDir === SortDirection.ASC,
							})) ||
						''
					}
					onClick={() => handleSortChange(sortBy, SortDirection.ASC)}
				/>
				<ArrowSortDESC
					className={
						(sort?.sortBy === sortBy &&
							cx({
								[styles.storeTable_thead_withSort_DESC]: sort?.sortDir === SortDirection.DESC,
							})) ||
						''
					}
					onClick={() => handleSortChange(sortBy, SortDirection.DESC)}
				/>
			</div>
		</div>
	);
};

export default SortableHeaderItem;
