import { FC } from 'react';

// components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputSearch from 'shared/components/Inputs/InputSearch';

// styles
import styles from './StoreItemsTableActions.module.scss';

type StoreItemsActionsProps = {
	// eslint-disable-next-line no-unused-vars
	onSearchStoreItem: (search: string) => void;
	onCreateStoreItem?: () => void;
	onDownloadList?: () => void;
};

const StoreItemsActions: FC<StoreItemsActionsProps> = ({ onSearchStoreItem, onCreateStoreItem, onDownloadList }) => {
	return (
		<section className={styles.tableActions}>
			<InputSearch placeholder="Search..." changeHandler={onSearchStoreItem} />

			{onCreateStoreItem ? (
				<ButtonDefault
					title="+ Create"
					classList={['primary', 'sm']}
					parentClassName={styles.tableActions__button}
					clickHandler={onCreateStoreItem}
				/>
			) : null}
			{onDownloadList ? (
				<span className={styles.tableActions__download} onClick={onDownloadList}>
					Download list
				</span>
			) : null}
		</section>
	);
};

export default StoreItemsActions;
