import { FC, ReactNode, useState } from 'react';
import { flip, shift, useFloating } from '@floating-ui/react-dom';
import { useClickAway } from 'react-use';
import cx from 'classnames';

import { ReactComponent as ThreeDotsSvg } from 'assets/image/icons/menu.svg';

import styles from './TableActions.module.scss';

interface TableActionsProps {
	children?: ReactNode;
}

interface TableActionItemProps {
	icon: ReactNode;
	label: string;
	onClick: () => void;
}

const TableActions: FC<TableActionsProps> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { refs, floatingStyles } = useFloating({
		placement: 'bottom-end',
		middleware: [flip(), shift()],
	});

	useClickAway(refs.floating, () => setIsOpen(false));

	const toggleMenu = () => setIsOpen(prev => !prev);

	return (
		<div className={styles.container}>
			{/* Three Dots Button */}
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
			<button
				ref={refs.setReference}
				className={cx(styles.menuButton, { [styles.menuButton_active]: isOpen })}
				onClick={toggleMenu}
			>
				<ThreeDotsSvg />
			</button>

			{/* Floating Menu */}
			{isOpen && (
				<div ref={refs.setFloating} style={floatingStyles} className={styles.dropdownMenu} onClick={toggleMenu}>
					{children}
				</div>
			)}
		</div>
	);
};

const TableActionItem: FC<TableActionItemProps> = ({ icon, label, onClick }) => {
	return (
		<div className={styles.menuItem} onClick={onClick}>
			{icon}
			<span>{label}</span>
		</div>
	);
};

export { TableActions, TableActionItem };
