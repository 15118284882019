import React, { FC } from 'react';

import { ReactComponent as PlaySVG } from 'assets/image/close-eye.svg';
import { ReactComponent as PauseSVG } from 'assets/image/open-eye.svg';

import CustomTooltip from 'shared/components/Tooltip';

type VisibilityTableItemProps = {
	tooltip: string;
	isPublished: boolean;
	// eslint-disable-next-line no-unused-vars
	onTogglePublishItem: () => void;
};

const VisibilityTableItem: FC<VisibilityTableItemProps> = ({ isPublished, tooltip, onTogglePublishItem }) => (
	<CustomTooltip title={tooltip} arrow={true} placement="top">
		{!isPublished ? (
			<PlaySVG width={24} height={24} onClick={onTogglePublishItem} style={{ cursor: 'pointer' }} />
		) : (
			<PauseSVG width={24} height={24} onClick={onTogglePublishItem} style={{ cursor: 'pointer' }} />
		)}
	</CustomTooltip>
);

export default VisibilityTableItem;
