import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'core/API';
import { STATUSES_QUERY_KEY } from 'core/constants';

interface IUseDeleteStatus {
	// eslint-disable-next-line no-unused-vars
	deleteStatus: ({ statusId }: { statusId: number }) => void;
}

const deleteStatusApi = async (statusId: number) => {
	return API.deleteStatus(statusId, undefined);
};

export const useDeleteStatus = (): IUseDeleteStatus => {
	const queryClient = useQueryClient();
	const { mutate: deleteStatus } = useMutation<void, Error, { statusId: number }>({
		mutationFn: ({ statusId }) => deleteStatusApi(statusId),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STATUSES_QUERY_KEY] }),
	});

	return { deleteStatus };
};
