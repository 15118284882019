import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OfferResponse, OfferRequest, IOfferRequest } from '@joc/api-gateway/lib/api-client';
import { API } from 'core/API';
import { STORE_SPECIAL_OFFER_QUERY_KEY } from 'core/constants';

const createNewOffer = async (offer: IOfferRequest) => {
	return API.createOffer(OfferRequest.fromJS(offer));
};

export const useCreateOffer = () => {
	const queryClient = useQueryClient();
	return useMutation<OfferResponse, Error, IOfferRequest>({
		mutationFn: offer => createNewOffer(offer),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_SPECIAL_OFFER_QUERY_KEY] }),
	});
};
