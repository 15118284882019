import { Dispatch } from 'redux';

import { API } from 'core/API';
import {
	GET_VOLUNTEERS_ALL,
	UPDATE_VOLUNTEER,
	RESET_VOLUNTEERS,
	SET_ERROR,
	UPDATE_ACTIVE_STATUS_VOL,
	DELETE_VOLUNTEER,
} from 'redux/actions-types';
import {
	IUpdatePasswordRequest,
	IUpdateVolunteerRequest,
	IVolunteerActiveStatusRequest,
	SearchVolunteersRequest,
	UpdatePasswordRequest,
	UpdateVolunteerRequest,
	VolunteerActiveStatusRequest,
} from '@joc/api-gateway';
import { UpdateDefaultOrganizationRequest } from '@joc/api-gateway/lib/api-client';

export const getVolunteers =
	(searchBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const volunteersResponse = await API.adminSearchVolunteers(SearchVolunteersRequest.fromJS(searchBody));

			dispatch({
				type: GET_VOLUNTEERS_ALL,
				payload: { records: volunteersResponse.records, total: volunteersResponse.total },
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const updateVolunteer =
	(id: number, userId: number, values: IUpdateVolunteerRequest & IUpdatePasswordRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const { password, defaultOrganizationId, ...updateValues } = values;
			const requestBody = { ...updateValues, phoneNumber: updateValues.phoneNumber || null };
			const updatedData = await API.updateVolunteer(id, undefined, UpdateVolunteerRequest.fromJS(requestBody));
			if (password)
				await API.updatePasswordByAdmin(userId, undefined, UpdatePasswordRequest.fromJS({ password }));

			if (defaultOrganizationId && defaultOrganizationId !== 'Private') {
				await API.updateDefaultOrganizationyAdmin(
					UpdateDefaultOrganizationRequest.fromJS({
						volunteerId: updatedData.id,
						organizationId: defaultOrganizationId,
					})
				);
			}
			dispatch({
				type: UPDATE_VOLUNTEER,
				payload: {
					...updatedData,
					...(defaultOrganizationId && defaultOrganizationId !== 'Private' ? { defaultOrganizationId } : {}),
				},
			});
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const updateActiveStatus = (body: IVolunteerActiveStatusRequest) => async (dispatch: Dispatch) => {
	try {
		const updateStatusResponse = await API.updateVolunteerActiveStatus(VolunteerActiveStatusRequest.fromJS(body));
		dispatch({
			type: UPDATE_ACTIVE_STATUS_VOL,
			payload: updateStatusResponse,
		});
	} catch (error: any) {
		throw error;
	}
};

export const deleteVolunteer =
	(volunteerId: string) =>
	(dispatch: Dispatch): void => {
		API.deleteUser(Number(volunteerId)).finally();

		dispatch({
			type: DELETE_VOLUNTEER,
			payload: volunteerId,
		});
	};

export const resetVolunteers =
	() =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: RESET_VOLUNTEERS,
		});
	};

// export const getVolunteersByGroup =
// 	(requestBody: ISearchVolunteersByGroupRequest) =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		try {
// 			const volunteersResponse = await API.getAllVolunteersByGroup(
// 				SearchVolunteersByGroupRequest.fromJS(requestBody)
// 			);
// 			dispatch({
// 				type: GET_VOLUNTEERS_BY_GROUP,
// 				payload: { records: volunteersResponse.records, total: volunteersResponse.total },
// 			});
// 		} catch (error: any) {
// 			throw error;
// 		}
// 	};
