import React, { FC, useCallback, useState } from 'react';

// api
import { IDateRange } from '@joc/api-gateway';

// context
import { StatusesContextProvider } from './Statuses.context';
// constants

// components
import InputSearch from 'shared/components/Inputs/InputSearch';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import StatusesTable from './StatusesTable';
import StatusesPopup from './StatusesPopup';
import { StatusesSidebar } from './StatusesSidebar';

// icons
import FilterSvg from 'assets/image/filter.svg';

// styles
import styles from './Statuses.module.scss';

const Statuses: FC = () => {
	const [isClearSearch, setIsClearSearch] = useState(true);

	const [isShowStatusPopup, setIsShowStatusPopup] = useState(false);
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState<IDateRange>({ fromDate: undefined, toDate: undefined });
	const [sidebarVisibility, setSidebarVisibility] = useState(false);

	const closePopup = useCallback(() => {
		setIsShowStatusPopup(false);
	}, []);

	const filterClickHandler = () => setSidebarVisibility(!sidebarVisibility);

	return (
		<section className={styles.statuses}>
			<StatusesContextProvider
				value={{ search, setSearch, filters, setFilters, sidebarVisibility, setSidebarVisibility }}
			>
				<div className={styles.statuses__header}>
					<div className={styles.statuses__header__filters}>
						<InputSearch
							placeholder="Search..."
							classList={['big']}
							parentClass={styles.statuses__search}
							changeHandler={value => setSearch(value)}
							isClearSearch={isClearSearch}
							setIsClearSearch={setIsClearSearch}
						/>
						<img src={FilterSvg} alt="filter" style={{ cursor: 'pointer' }} onClick={filterClickHandler} />
					</div>
					<ButtonDefault
						title="Create"
						classList={['primary', 'sm']}
						parentClassName={styles.statuses__create}
						clickHandler={() => setIsShowStatusPopup(true)}
					/>
				</div>
				<StatusesTable orgId={''} />

				{isShowStatusPopup && <StatusesPopup isModalOpen={isShowStatusPopup} onClose={closePopup} />}
				<StatusesSidebar />
			</StatusesContextProvider>
		</section>
	);
};

export default Statuses;
