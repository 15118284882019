import { FC, useState } from 'react';
import StoreActions from '../Store/StoreActions';
import DonationItemsTable from './DonationTables/DonationItemTable';
import DonationItemPopup from './DonationPopups/DonationItemPopup';

const Donation: FC = () => {
	const [popupCreateOpen, setPopupCreateOpen] = useState(false);
	const [search, setSearch] = useState('');
	const togglePopupHandler = () => setPopupCreateOpen(!popupCreateOpen);

	const searchHandler = (value: string) => setSearch(value);

	return (
		<>
			<StoreActions onCreateStoreItem={togglePopupHandler} onSearchStoreItem={searchHandler} />
			<DonationItemsTable search={search} />
			{popupCreateOpen ? <DonationItemPopup open={popupCreateOpen} onClose={togglePopupHandler} /> : null}
		</>
	);
};

export default Donation;
