import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StoreItemResponse } from '@joc/api-gateway';
import { API } from '../../../core/API';
import { STORE_ITEMS_QUERY_KEY } from '../../../core/constants';
import { IUpdateStoreItemRequest, UpdateStoreItemRequest } from '@joc/api-gateway/lib/api-client';

interface IUseEditStoreItem {
	// eslint-disable-next-line no-unused-vars
	editStoreItem: ({ id, storeItem }: { id: number; storeItem: IUpdateStoreItemRequest }) => void;
}

const editStoreItemApi = async (id: number, storeItem: IUpdateStoreItemRequest) => {
	const { expirationDate, ...data } = storeItem;
	const body = UpdateStoreItemRequest.fromJS(data);

	return API.updateStoreItem(id, { ...body, expirationDate } as unknown as UpdateStoreItemRequest);
};

export const useEditStoreItem = (): IUseEditStoreItem => {
	const queryClient = useQueryClient();
	const { mutate: editStoreItem } = useMutation<
		StoreItemResponse,
		Error,
		{ id: number; storeItem: IUpdateStoreItemRequest }
	>({
		mutationFn: ({ id, storeItem }) => editStoreItemApi(id, storeItem),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_ITEMS_QUERY_KEY] }),
	});

	return { editStoreItem };
};
