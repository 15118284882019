import React, { FC, useEffect, useState } from 'react';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';
import cx from 'classnames';
import PopupVolunteers from 'components/Organization/Opportunities/OpportunityPopups/PopupVolunteers';
import { setUserPhotoSrc } from 'core/functions';
import {
	OPPORTUNITY_VOLUNTEER_STATUS,
	IGetVolunteerByIdResponse,
	IOrganisationResponse,
	OPPORTUNITY_STATUSES,
	OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
import { changeStatusVolunteers } from 'redux/opportunities-service/action';
import { connect, ConnectedProps } from 'react-redux';
import VolunteerBadges from 'shared/components/Table/VolunteerBadges';
import styles from './VolunteerRenderer.module.scss';

type SingleVolunteerParentProps = {
	photo: string | undefined;
	volunteerName: string;
	isOptionsDisable?: boolean;
	isApprovalRequired?: boolean;
	volunteerId: string;
	opportunityId: number;
	setIsPreviewOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	opportunityName: string;
	volunteersArray: Array<IGetVolunteerByIdResponse>;
	volunteerStatus: OPPORTUNITY_VOLUNTEER_STATUS;
	opportunityStatus: OPPORTUNITY_STATUSES;
	volunteerCreatedDate: Date;
	volunteerOrganizations: Array<IOrganisationResponse> | undefined;
	opportunityType: OPPORTUNITY_TYPES;
	isViewVolunteersDisable?: boolean;
	inPastOppoList?: boolean;
};

const SingleVolunteer: FC<SingleVolunteerProps> = ({
	photo,
	volunteerName,
	isOptionsDisable,
	opportunityId,
	setIsPreviewOpen,
	opportunityName,
	volunteersArray,
	opportunityStatus,
	volunteerCreatedDate,
	opportunityType,
	isViewVolunteersDisable,
	inPastOppoList = false,
}: SingleVolunteerProps) => {
	const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

	useEffect(() => {
		if (setIsPreviewOpen) if (setIsPreviewOpen) setIsPreviewOpen(isShowPopup);
	}, [isShowPopup]);

	return (
		<div className={styles.volunteer}>
			<div className={cx(styles.volunteer__info, !isOptionsDisable && styles.volunteer__info__mb)}>
				<img className={styles.volunteer__info__photo} src={setUserPhotoSrc(photo)} alt="avatar" />
				<div className={styles.volunteer__info_name}>
					<VolunteerBadges createDate={volunteerCreatedDate} />
					<span title={volunteerName}>{volunteerName}</span>
				</div>
			</div>
			{!isViewVolunteersDisable && (
				<ButtonOutlined
					title="View more"
					isDisableLastChildMargin={true}
					clickHandler={() => setIsShowPopup(true)}
				/>
			)}
			{isShowPopup && (
				<PopupVolunteers
					opportunityName={opportunityName}
					setIsShowPopup={setIsShowPopup}
					opportunityId={opportunityId}
					volunteersArray={volunteersArray}
					opportunityStatus={opportunityStatus}
					opportunityType={opportunityType}
					inPastOppoList={inPastOppoList}
				/>
			)}
		</div>
	);
};

const mapDispatchToProps = {
	changeStatusVolunteers,
};

const connector = connect(null, mapDispatchToProps);

type SingleVolunteerProps = ConnectedProps<typeof connector> & SingleVolunteerParentProps;

export default connector(SingleVolunteer);
