import { useInfiniteQuery } from '@tanstack/react-query';
import {
	DonationVolunteerSort,
	IDonationVolunteerSort,
	SearchDonationVolunteersRequest,
	SearchDonationVolunteersResponse,
} from '@joc/api-gateway/lib/api-client';
import { API } from 'core/API';
import { INITIAL_PAGINATION, STORE_VOLUNTEER_DONATIONS_QUERY_KEY } from 'core/constants';

const fetchVolunteersDonations = async ({
	donationItemId,
	skip,
	take,
	search,
	sort,
}: {
	donationItemId: number;
	skip: number;
	take: number;
	search?: string;
	sort?: DonationVolunteerSort;
}): Promise<SearchDonationVolunteersResponse> =>
	API.searchDonationVolunteers(
		donationItemId,
		SearchDonationVolunteersRequest.fromJS({
			pagination: {
				skip,
				take,
			},
			...(sort ? { sort } : {}),
			...(search ? { search } : {}),
		})
	);

export const useVolunteersDonations = (search: string, donationItemId: number, sort?: DonationVolunteerSort) =>
	useInfiniteQuery<SearchDonationVolunteersResponse, unknown>({
		queryKey: [STORE_VOLUNTEER_DONATIONS_QUERY_KEY, { search, sort, donationItemId }],
		initialPageParam: 0,
		refetchOnWindowFocus: false,
		queryFn: ({ pageParam = 0 }) =>
			fetchVolunteersDonations({
				donationItemId,
				skip: pageParam as number,
				take: INITIAL_PAGINATION.take,
				search,
				sort,
			}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.records.length < INITIAL_PAGINATION.take ? undefined : allPages.length * 10;
		},
	});
