import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../core/API';
import { STORE_DONATIONS_QUERY_KEY } from '../../../core/constants';
import { DonationResponse } from '@joc/api-gateway/lib/api-client';

interface IUsePublishStoreItem {
	// eslint-disable-next-line no-unused-vars
	publishDonationItem: ({ id }: { id: number }) => void;
	isError: boolean;
	isSuccess: boolean;
	data?: DonationResponse;
}

const publishStoreItemApi = async (id: number) => {
	return API.publishDonation(id);
};

export const usePublishDonationItem = (): IUsePublishStoreItem => {
	const queryClient = useQueryClient();
	const {
		mutate: publishDonationItem,
		data,
		isError,
		isSuccess,
	} = useMutation<DonationResponse, Error, { id: number }>({
		mutationFn: ({ id }) => publishStoreItemApi(id),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_DONATIONS_QUERY_KEY] }),
	});

	return { publishDonationItem, data, isError, isSuccess };
};
