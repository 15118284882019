import { useMutation, useQueryClient } from '@tanstack/react-query';
import { STORE_SPECIAL_OFFER_QUERY_KEY } from 'core/constants';
import { API } from 'core/API';
import { OfferResponse } from '@joc/api-gateway/lib/api-client';

interface IUsePublishOffer {
	// eslint-disable-next-line no-unused-vars
	publishOffer: ({ id }: { id: number }) => void;
	isError: boolean;
	isSuccess: boolean;
	data?: OfferResponse;
}

const publishOfferApi = async (id: number) => {
	return API.publishOffer(id);
};

export const usePublishOffer = (): IUsePublishOffer => {
	const queryClient = useQueryClient();
	const {
		mutate: publishOffer,
		data,
		isError,
		isSuccess,
	} = useMutation<OfferResponse, Error, { id: number }>({
		mutationFn: ({ id }) => publishOfferApi(id),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_SPECIAL_OFFER_QUERY_KEY] }),
	});

	return { publishOffer, data, isError, isSuccess };
};
