import { FC, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';

// hooks
import { useCreateStatus, useEditStatus } from '../../../../../hooks/statuses';

// components
import DialogStyled from 'shared/components/newUI/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import StatusForm from './StatusForm';
import StatusPreview, { Status } from '../StatusPreview';
import Loader from 'shared/components/Loader';

// helpers
import { IStatusResponse, SUPPORTED_MEDIA_FORMAT } from '@joc/api-gateway';
import { statusValidationSchema } from './validation';
import { sendFilesToS3Bucket } from 'core/functions';

// styles
import styles from './StatusesPopup.module.scss';

type StatusesPopupProps = {
	status?: IStatusResponse;
	isModalOpen: boolean;
	onClose: () => void;
};

const StatusesPopup: FC<StatusesPopupProps> = ({ status, isModalOpen, onClose }) => {
	const closeDialog = () => onClose();
	const formRef = useRef<FormikProps<Status>>(null);
	const [currentValues, setCurrentValues] = useState<Partial<IStatusResponse>>();
	const [formState, setFormState] = useState<{ isValid: boolean; dirty: boolean }>();
	const [isLoading, setIsLoading] = useState(false);
	const { createStatus } = useCreateStatus();
	const { editStatus } = useEditStatus();
	const initialData = useMemo<Status>(() => {
		if (status) {
			formRef.current?.setFieldTouched('mediaPath', true, true);
			return {
				mediaPath: status.mediaPath || '',
				caption: status.caption || '',
				linkTitle: status.linkTitle || '',
				link: status.link || '',
				startDate: status.startDate || new Date(),
				duration: status.duration || 0,
				fileType: status.fileType || SUPPORTED_MEDIA_FORMAT.Image_jpeg,
			};
		}

		return {
			mediaPath: '',
			caption: '',
			linkTitle: '',
			link: '',
			startDate: new Date(),
			duration: 0,
			fileType: SUPPORTED_MEDIA_FORMAT.Image_jpeg,
		};
	}, [status]);

	const submitClickHandler = async (values: Status) => {
		try {
			setIsLoading(true);
			if (status) {
				if (values.mediaPath instanceof File) {
					const [mediaUrl] = await sendFilesToS3Bucket([values.mediaPath]);

					editStatus({
						statusId: status.id,
						data: {
							caption: values.caption,
							link: values.link,
							linkTitle: values.linkTitle,
							mediaPath: mediaUrl,
							startDate: values.startDate,
							duration: values.duration,
							fileType: values.fileType,
						},
					});
				} else {
					editStatus({
						statusId: status.id,
						data: {
							caption: values.caption,
							link: values.link,
							linkTitle: values.linkTitle,
							mediaPath: values.mediaPath,
							startDate: values.startDate,
							duration: values.duration,
							fileType: values.fileType,
						},
					});
				}
			} else {
				const [mediaUrl] = await sendFilesToS3Bucket([values.mediaPath as File]);
				createStatus({
					organizationId: '',
					status: {
						caption: values.caption,
						link: values.link,
						linkTitle: values.linkTitle,
						mediaPath: mediaUrl,
						startDate: values.startDate,
						duration: values.duration,
						fileType: values.fileType,
					},
				});
			}
			setIsLoading(false);
			onClose();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	};

	return (
		<DialogStyled open={isModalOpen} maxWidth="xl" isFullScreen={true}>
			<section className={styles.dialog}>
				<div className={styles.dialog__header}>
					<ButtonCross clickHandler={closeDialog} parentClassName={styles.dialog__header_button} />
				</div>
				<div className={styles.dialog__content}>
					<div className={styles.dialog__content_left}>
						<span className={styles.dialog__title}>{!status ? 'Create new Status' : 'Edit Status'}</span>
						<Formik
							innerRef={formRef}
							enableReinitialize={true}
							initialValues={initialData}
							validationSchema={statusValidationSchema}
							onSubmit={submitClickHandler}
							className={styles.dialog__form}
						>
							<StatusForm
								currentValues={values => setCurrentValues(values)}
								formState={state => setFormState(state)}
								isEditMode={!!status}
							/>
						</Formik>
					</div>
					<div className={styles.dialog__content_right}>
						<StatusPreview status={currentValues} />
					</div>
				</div>
				<div className={styles.dialog__actions}>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<ButtonDefault
								classList={['secondary', 'lg']}
								clickHandler={() => onClose()}
								title="Cancel"
								parentClassName={styles.dialog__actions_button}
							/>

							<ButtonDefault
								submitType={true}
								title="Publish"
								clickHandler={() => formRef.current?.submitForm()}
								disabled={!formState?.isValid || !formState.dirty}
								classList={['primary', 'lg']}
								parentClassName={styles.dialog__actions_button}
							/>
						</>
					)}
				</div>
			</section>
		</DialogStyled>
	);
};

export default StatusesPopup;
