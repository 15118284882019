import { FC, useState, MouseEvent } from 'react';
import moment from 'moment/moment';
import { IconButton } from '@mui/material';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

// icons
import { ReactComponent as ThreeDotsSvg } from 'assets/icons/menu.svg';
import { ReactComponent as PreviewSvg } from 'assets/icons/preview.svg';
import { ReactComponent as EditSvg } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteSvg } from 'assets/icons/trash.svg';

// hooks
import { useDeleteStatus } from '../../../../../../hooks/statuses';

// components
import StatusesPopup from '../../StatusesPopup';
import Dialog from 'shared/components/newUI/Dialog';
import StatusPreview from '../../StatusPreview';
import ButtonCross from 'shared/components/Buttons/ButtonCross';

// types
import { IStatusResponse } from '@joc/api-gateway';

// styles
import styles from './StatusActions.module.scss';

type StatusActionsTypes = {
	status: IStatusResponse;
};

const StatusAction: FC<StatusActionsTypes> = ({ status }) => {
	const [isShowStatusPopup, setIsShowStatusPopup] = useState(false);
	const [isShowStatusPreview, setIsShowStatusPreview] = useState(false);
	const { deleteStatus } = useDeleteStatus();

	const [openItemId, setOpenItemId] = useState<number | null>(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleEditStatus = () => {
		setIsShowStatusPopup(true);
		handleActionsButtonClose();
	};

	const handlePreviewStatus = () => {
		setIsShowStatusPreview(true);
		handleActionsButtonClose();
	};

	const handleDeleteStatus = () => {
		handleActionsButtonClose();
		deleteStatus({ statusId: status.id });
	};

	const handleActionsButtonClose = () => {
		setAnchorEl(null);
		setOpenItemId(null);
	};

	const handleActionsButtonClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpenItemId(status.id);
	};

	const handleCloseStatusPopup = () => {
		setIsShowStatusPopup(false);
	};
	const handleCloseStatusPreview = () => setIsShowStatusPreview(false);

	return (
		<>
			<IconButton
				aria-label="actions"
				aria-controls={openItemId === status.id ? 'long-menu' : undefined}
				onClick={event => handleActionsButtonClick(event)}
			>
				<ThreeDotsSvg className={openItemId === status.id ? styles.active : ''} />
			</IconButton>
			<Menu
				open={openItemId === status.id}
				anchorEl={anchorEl}
				onClose={handleActionsButtonClose}
				elevation={0}
				MenuListProps={{
					className: styles.actionsList,
				}}
				PaperProps={{
					className: styles.paper,
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: -27,
					horizontal: 152,
				}}
			>
				<MenuItem className={styles.menuItem} onClick={handlePreviewStatus}>
					<div className={styles.actionsContainer}>
						<PreviewSvg title="Preview" />
						<span className={styles.actionsText}>Preview</span>
					</div>
				</MenuItem>
				{!moment(status.startDate).add(1, 'day').isBefore(moment()) ? (
					<MenuItem className={styles.menuItem} onClick={handleEditStatus}>
						<div className={styles.actionsContainer}>
							<EditSvg title="Edit status" />
							<span className={styles.actionsText}>Edit</span>
						</div>
					</MenuItem>
				) : null}
				<MenuItem className={styles.menuItem} onClick={handleDeleteStatus}>
					<div className={styles.actionsContainer}>
						<DeleteSvg title="Delete status" />
						<span className={styles.actionsText}>Delete</span>
					</div>
				</MenuItem>
			</Menu>
			{isShowStatusPopup && (
				<StatusesPopup status={status} isModalOpen={isShowStatusPopup} onClose={handleCloseStatusPopup} />
			)}
			{isShowStatusPreview && (
				<Dialog open={isShowStatusPreview} maxWidth="xl">
					<div className={styles.dialog__header}>
						<ButtonCross
							clickHandler={handleCloseStatusPreview}
							parentClassName={styles.dialog__header_button}
						/>
					</div>
					<div className={styles.dialog__content}>
						<StatusPreview status={status} />
					</div>
				</Dialog>
			)}
		</>
	);
};

export default StatusAction;
