import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../core/API';
import { STORE_DONATIONS_QUERY_KEY } from '../../../core/constants';

interface IUseDeleteDonationItem {
	// eslint-disable-next-line no-unused-vars
	deleteDonationItem: (id: number) => void;
}

const deleteDonationItemApi = async (id: number) => {
	return API.deleteDonation(id);
};

export const useDeleteDonationItem = (): IUseDeleteDonationItem => {
	const queryClient = useQueryClient();
	const { mutate: deleteDonationItem } = useMutation<void, Error, number>({
		mutationFn: storeItem => deleteDonationItemApi(storeItem),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_DONATIONS_QUERY_KEY] }),
	});

	return { deleteDonationItem };
};
