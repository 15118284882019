import { FC, useEffect, useMemo } from 'react';
import { Field, Form, useFormikContext } from 'formik';
import moment from 'moment/moment';
import cx from 'classnames';

// components
import Input from 'shared/components/Inputs/InputText';
import { DatePicker } from 'shared/components/Pickers/NewUI/DatePicker';
import MediaInput from 'shared/components/MediaInput';
import { StatusTimePicker } from './StatusTimePicker';

// constants
import { MAX_CAPTION_LENGTH } from '../validation';

// types
import { FieldBaseProps } from 'core/types';
import { IStatusResponse } from '@joc/api-gateway';

// styles
import styles from './StatusForm.module.scss';

type StatusFormProps = {
	// eslint-disable-next-line no-unused-vars
	currentValues: (values: Partial<IStatusResponse>) => void;
	// eslint-disable-next-line no-unused-vars
	formState: (state: { isValid: boolean; dirty: boolean }) => void;
	isEditMode: boolean;
};

const StatusForm: FC<StatusFormProps> = ({ currentValues, formState, isEditMode }) => {
	const { values, isValid, initialValues, dirty, setFieldTouched } = useFormikContext<
		Partial<IStatusResponse> & { startTime: string }
	>();

	useEffect(() => {
		currentValues(values as Partial<IStatusResponse>);
	}, [values]);

	useEffect(() => {
		setFieldTouched('caption', true).finally();
		setFieldTouched('link', true).finally();
		setFieldTouched('linkTitle', true).finally();

		formState({ isValid, dirty });
	}, [isValid, dirty]);

	const disableDateTime = useMemo(
		() =>
			moment().isSameOrAfter(initialValues.startDate) &&
			moment(initialValues.startDate).add(1, 'day').isBefore(moment().add(1, 'day')),
		[initialValues]
	);

	return (
		<Form className={styles.statusForm}>
			<Field
				name="mediaPath"
				label="Photo or Video"
				maxMegabyteSize={100}
				toS3bucket={true}
				isRequired={true}
				placeholder="Photos are displayed for 60 seconds, Videos - 1 minute and 30 seconds"
				parentClassName={styles.statusForm__feild}
				component={MediaInput}
			/>

			<Field name="caption">
				{(props: FieldBaseProps) => (
					<div className={styles.statusForm__caption}>
						<label className={styles.statusForm__caption_label} htmlFor="caption">
							Status caption
						</label>
						<Input
							value={props.field.value}
							setValue={value => props.form.setFieldValue(props.field.name, value)}
							placeholder="Status caption"
							parentClassName={styles.statusForm__feild}
							{...props}
						/>
						{!props.meta.error || !props.meta.touched ? (
							<span
								className={cx(styles.statusForm__caption_limit, {
									[styles.statusForm__caption_limit_error]:
										props.field.value.trim().length > MAX_CAPTION_LENGTH,
								})}
							>
								Maximum characters: {props.field.value.trim().length}/{MAX_CAPTION_LENGTH}
							</span>
						) : (
							<></>
						)}

						{props.meta.error && props.meta.touched ? (
							<span className={styles.statusForm__feild_error}>{props.meta.error}</span>
						) : null}
					</div>
				)}
			</Field>
			<Field name="linkTitle">
				{(props: FieldBaseProps) => (
					<div className={styles.statusForm__caption}>
						<label className={styles.statusForm__caption_label} htmlFor="linkTitle">
							Link title
						</label>
						<Input
							value={props.field.value}
							setValue={value => props.form.setFieldValue(props.field.name, value)}
							placeholder="Link title"
							parentClassName={styles.statusForm__feild}
							{...props}
						/>
						{props.meta.error && props.meta.touched ? (
							<span className={styles.statusForm__feild_error}>{props.meta.error}</span>
						) : null}
					</div>
				)}
			</Field>
			<Field name="link">
				{(props: FieldBaseProps) => (
					<div className={styles.statusForm__caption}>
						<label className={styles.statusForm__caption_label} htmlFor="linkTitle">
							URL-Link
						</label>
						<Input
							value={props.field.value}
							setValue={value => props.form.setFieldValue(props.field.name, value)}
							label="URL-Link"
							placeholder="https://"
							parentClassName={styles.statusForm__feild}
							{...props}
						/>
						{props.meta.error && props.meta.touched ? (
							<span className={styles.statusForm__feild_error}>{props.meta.error}</span>
						) : null}
					</div>
				)}
			</Field>

			<div className={styles.statusForm__pickers}>
				<Field
					name="startDate"
					label="Publication Date"
					availableFuture={true}
					disabled={isEditMode && disableDateTime}
					disablePast={true}
					parentClassName={cx(styles.statusForm__feild, styles.statusForm__pickers__field)}
					component={DatePicker}
				/>
				<Field
					name="startDate"
					label="Publication time"
					parentClassName={cx(styles.statusForm__feild, styles.statusForm__pickers__field)}
					disablePast={true}
					disabled={isEditMode && disableDateTime}
					component={StatusTimePicker}
				/>
			</div>
		</Form>
	);
};

export default StatusForm;
