// images
import UserPhotoTemplate from 'assets/image/default-photo.svg';
import ManualOppoPhotoTemplate from 'assets/image/default-manual-oppo.png';
import LogoImage from 'assets/newLogos/upper-left-corner.png';

// types
import { IMAGE_WITH_POPUP_TYPE } from 'core/types';
import { CreateUploadLinkRequest, IOpportunityResponse, OPPORTUNITY_TYPES } from '@joc/api-gateway';

// constants
import { defaultPhotoTypes, REGEX_URL } from 'core/constants';
import { API } from 'core/API';
import axios from 'axios';

export const validateFileType = (file: File, acceptableTypes?: string) => {
	const validTypes = acceptableTypes || defaultPhotoTypes;
	return !(validTypes.indexOf(file.type) === -1);
};

export const setNewUserPhotoSrc = (imagePath: string | undefined | null, type?: IMAGE_WITH_POPUP_TYPE): string => {
	if (!imagePath || imagePath === 'null') return getDefaultImage(type);

	if (!!imagePath.match(REGEX_URL)?.length) return imagePath;

	return `${process.env.REACT_APP_S3_API}/${imagePath}`;
};

export const setUserChatPhoto = (imagePath: string | undefined): string | null => imagePath || null;

export const setUserPhotoSrcPromise = (
	imagePath: string | undefined,
	type?: IMAGE_WITH_POPUP_TYPE
): Promise<string> => {
	return new Promise(resolve => {
		const url = setNewUserPhotoSrc(imagePath, type);
		const image = document.createElement('img');
		image.src = url as string;

		image.onerror = () => {
			const defaultImage = getDefaultImage(type);
			resolve(defaultImage);
		};
		image.onload = () => {
			resolve(url);
		};
	});
};

export const convertBase64ToBlob = async (data: any) => {
	const base64Data = data;
	const base64 = await fetch(base64Data);
	const blob = await base64.blob();
	return { photo: blob, fileName: blob.type };
};

export const getDefaultImage = (type?: IMAGE_WITH_POPUP_TYPE): string => {
	switch (type) {
		case IMAGE_WITH_POPUP_TYPE.DEFAULT:
			return UserPhotoTemplate;

		case IMAGE_WITH_POPUP_TYPE.MANUAL_OPPO:
			return ManualOppoPhotoTemplate;

		case IMAGE_WITH_POPUP_TYPE.LOGO:
			return LogoImage;

		default:
			return UserPhotoTemplate;
	}
};

export const getImageType = (opportunity: IOpportunityResponse): IMAGE_WITH_POPUP_TYPE =>
	opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL
		? IMAGE_WITH_POPUP_TYPE.MANUAL_OPPO
		: IMAGE_WITH_POPUP_TYPE.DEFAULT;

export const getOpportunityImagePath = (opportunity: IOpportunityResponse) => {
	if (
		!opportunity?.filePathes?.length &&
		!opportunity?.imagePath &&
		opportunity.opportunityType !== OPPORTUNITY_TYPES.MANUAL
	) {
		return opportunity?.organisation?.organizationLogoPath || '';
	}
	return opportunity.imagePath || opportunity.filePathes?.[0] || '';
};

export const sendFilesToS3Bucket = async (files: Array<File>): Promise<Array<string>> => {
	const presignedUrlsResponse = await Promise.all(
		files.map(file =>
			API.createPreSignedUrl(
				CreateUploadLinkRequest.fromJS({
					fileName: file.name,
					fileType: file.type,
				})
			)
		)
	);

	await Promise.all(
		presignedUrlsResponse.map((url, i) =>
			axios.put(url.upoloadLink, files[i], {
				headers: {
					'Access-Control-Allow-Origin': null,
					'Content-Type': files[i].type,
				},
			})
		)
	);

	const urls = presignedUrlsResponse.map(url => `${process.env.REACT_APP_S3_API}/${url.newFileName}`);

	return urls;
};
