import { FC, ReactNode, useMemo } from 'react';
import cx from 'classnames';

// types
import { IGetLocationIdByAddressRequest } from '@joc/api-gateway';
import { FieldBaseProps } from 'core/types/field';
import { FormikErrors } from 'formik';

// components
import InputErrorHint from 'shared/components/InputErrorHint';

// styles
import styles from './styles.module.scss';

type FieldWrapperProps = FieldBaseProps<{
	children: ReactNode;
	eachErrorMessageToNewLine?: boolean;
	smallLabel?: string;
	isRequired?: boolean;
	isMarginLeftUnset?: boolean;
}>;

const FieldWrapper: FC<FieldWrapperProps> = ({
	children,
	label,
	smallLabel,
	disabled,
	disableErrorText,
	isAddressErrorText,
	uploadLogoStyle,
	textAreaStyle,
	isSmallHeight,
	isDisabledMargin,
	isDisableHeight,
	isLabelBold,
	parentClassName,
	field,
	form,
	dir,
	eachErrorMessageToNewLine,
	isRequired,
	isMarginLeftUnset,
}) => {
	const { errors, touched } = form;

	const error = useMemo(() => {
		return (
			(errors[field.name] &&
				touched[field.name] &&
				!disableErrorText &&
				((eachErrorMessageToNewLine
					? ((errors[field.name] as string)
							.split('.')
							.map((errorMessage: string, index: number, array: Array<string>) =>
								index === array.length - 1 ? errorMessage.trim() : `${errorMessage.trim()}.`
							) as string | FormikErrors<IGetLocationIdByAddressRequest | string>)
					: errors[field.name]) as string | FormikErrors<IGetLocationIdByAddressRequest | string>)) ||
			''
		);
	}, [errors, touched, field, disableErrorText, eachErrorMessageToNewLine]);

	return (
		<div
			className={cx(styles.main, parentClassName, {
				[styles.uploadLogo]: uploadLogoStyle,
				[styles.textArea]: textAreaStyle,
				[styles.margin_disabled]: isDisabledMargin,
				[styles.height_small]: isSmallHeight,
				[styles.disableHeight]: isDisableHeight,
				[styles.disable]: disabled,
				[styles.margin_bottom_small]: smallLabel,
			})}
			dir={dir}
		>
			{label && (
				<label className={cx(isLabelBold && styles.label_bold)} htmlFor={field.name}>
					<span>
						{label}
						{(isRequired && <span className={styles.label_required}>*</span>) || <></>}
					</span>
				</label>
			)}
			{smallLabel && <span className={cx(styles.small_label, styles.ml_26)}>{smallLabel}</span>}
			{children}
			{error && (
				<div
					className={cx(...(smallLabel ? [styles.ml_26, styles.mb_12] : []), {
						[styles.marginLeftUnset]: isMarginLeftUnset,
					})}
				>
					{isAddressErrorText ? (
						<InputErrorHint addressErrorText={error} />
					) : (
						<InputErrorHint errorText={typeof error === 'string' ? error : ''} />
					)}
				</div>
			)}
		</div>
	);
};

export default FieldWrapper;
