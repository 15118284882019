import { useInfiniteQuery } from '@tanstack/react-query';
import {
	ISearchOffersRequest,
	OfferSort,
	PaginationRequest,
	SearchOffersRequest,
	SearchOffersResponse,
} from '@joc/api-gateway/lib/api-client';
import { API } from 'core/API';
import { INITIAL_PAGINATION, STORE_SPECIAL_OFFER_QUERY_KEY } from 'core/constants';

const fetchOfferItems = async (body: ISearchOffersRequest) => API.searchOffersByAdmin(SearchOffersRequest.fromJS(body));

export const useSpecialOffers = (search: string, sort?: OfferSort, enabled = true) =>
	useInfiniteQuery<SearchOffersResponse, unknown>({
		queryKey: [STORE_SPECIAL_OFFER_QUERY_KEY, { search, sort }],
		initialPageParam: 0,
		enabled,
		queryFn: ({ pageParam = 0 }) =>
			fetchOfferItems({
				pagination: PaginationRequest.fromJS({ skip: pageParam as number, take: INITIAL_PAGINATION.take }),
				search,
				sort,
			}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.records.length < INITIAL_PAGINATION.take ? undefined : allPages.length * 10;
		},
	});
