import { FC } from 'react';
import SpecialOfferTable from './SpecialOfferTable';

import styles from './SpecialOffer.module.scss';

const SpecialOffer: FC = () => {
	return (
		<section className={styles.specialOffer}>
			<SpecialOfferTable />
		</section>
	);
};

export default SpecialOffer;
