import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateOfferRequest, OfferResponse, IUpdateOfferRequest } from '@joc/api-gateway';
import { API } from 'core/API';
import { STORE_SPECIAL_OFFER_QUERY_KEY } from 'core/constants';

const editOfferApi = async (id: number, offer: IUpdateOfferRequest) => {
	return API.updateOffer(id, offer as UpdateOfferRequest);
};

export const useEditOffer = () => {
	const queryClient = useQueryClient();
	return useMutation<OfferResponse, Error, { id: number; offer: IUpdateOfferRequest }>({
		mutationFn: ({ id, offer }) => editOfferApi(id, offer),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_SPECIAL_OFFER_QUERY_KEY] }),
	});
};
