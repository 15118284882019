import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// hooks
import { useSpecialOffers } from 'hooks/store';

// redux
import { useDispatch } from 'react-redux';
import { getChallenges } from 'redux/challenges-service/actions';
import { getDailyQuotes } from 'redux/dailyQuotes-service/actions';

// components
import DailyQuotes from './DailyQuotes';
import Challenges from './Challenges';
import Statuses from './Statuses';
import SpecialOffer from './SpecialOffer';

// types
import { MarketingTabs } from './MarketingTabs';
import { MARKETING_TABS } from './types';

// styles
import styles from './MarketingContent.module.scss';

const MarketingContent: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState<MARKETING_TABS>(MARKETING_TABS.Statuses);
	const { refetch: fetchOffers } = useSpecialOffers('', undefined, false);

	useEffect(() => {
		if (!location.search) history.push({ search: '?statuses' });
		dispatch(getChallenges());
		dispatch(getDailyQuotes(''));
		fetchOffers().finally();
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.content__wrapper}>
				<div className={styles.content}>
					<MarketingTabs activeTab={activeTab} setActiveTab={setActiveTab} />

					{activeTab === MARKETING_TABS.Statuses ? <Statuses /> : null}
					{activeTab === MARKETING_TABS.CHALLENGES ? <Challenges /> : null}
					{activeTab === MARKETING_TABS.DAILY_QUOTES ? <DailyQuotes /> : null}
					{activeTab === MARKETING_TABS.SPECIAL_OFFER ? <SpecialOffer /> : null}
				</div>
			</div>
		</div>
	);
};

export default MarketingContent;
