import React, { FC, useMemo, useState } from 'react';
import cx from 'classnames';
import { isSameDay } from 'date-fns';
import moment from 'moment-timezone';
// redux
import { useSelector } from 'react-redux';
import useThunkDispatch from '../../../../../../core/customHooks/useThunkDispatch';
import { selectorGetQuotesAll } from 'redux/dailyQuotes-service/selector';
import { createDailyQuote, deleteDailyQuote } from 'redux/dailyQuotes-service/actions';
// components
import { ImportedQuotesTable } from './ImportedQuotesTable';
import ButtonDefault from '../../../../Buttons/ButtonsDefault';
import { UploadedQuote, UploadQuotesInput } from './UploadQuotesInput';
import Loader from '../../../../Loader';
// types
import { IQuoteRequest, QuoteRequest } from '@joc/api-gateway';
// styles
import styles from './ImportQuotesTab.module.scss';

interface IImportQuoteTabProps {
	onClose: () => void;
	isEditing?: boolean;
}

export const ImportQuotesTab: FC<IImportQuoteTabProps> = ({ onClose, isEditing = false }) => {
	const dispatch = useThunkDispatch();

	const dailyQuotes = useSelector(selectorGetQuotesAll);

	const [uploadedQuotes, setUploadedQuotes] = useState<Array<UploadedQuote>>([]);
	const [errorsCount, setErrorsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const sortedQuotes = useMemo(() => {
		const remappedQuotes = uploadedQuotes.map(q => {
			const { errors, ...body } = q;

			if (
				q.message &&
				q.message.length <= 80 &&
				q.startDate &&
				moment(q.startDate).isValid() &&
				uploadedQuotes.filter(processQuote => isSameDay(q.startDate, processQuote.startDate)).length <= 1
			) {
				return body;
			}
			return q;
		});

		setErrorsCount(remappedQuotes.filter(q => Object.hasOwn(q, 'errors')).length);
		return remappedQuotes.sort((a, b) => a.startDate?.getTime() - b.startDate?.getTime());
	}, [uploadedQuotes]);

	const handleInput = (quotes: Array<IQuoteRequest>) => setUploadedQuotes(quotes);

	const handleImport = async () => {
		setIsLoading(true);
		await Promise.all(
			uploadedQuotes.map(async uploadedQuote => {
				const quotesWithSameDate = dailyQuotes.filter(quote =>
					isSameDay(quote.startDate, uploadedQuote.startDate)
				);

				return Promise.all(quotesWithSameDate.map(async quote => dispatch(deleteDailyQuote(quote))));
			})
		);

		await dispatch(
			createDailyQuote(
				uploadedQuotes.map(quote => ({
					...quote,
					startDate: moment(quote.startDate)
						.utcOffset(0, true) // Convert back to UTC
						.toISOString(),
				})) as unknown as Array<QuoteRequest>
			)
		);

		setIsLoading(false);

		onClose();
	};

	const downloadTemplate = () => {
		const templateFileLink = `${process.env.PUBLIC_URL}/assets/documents/importDailyQuotesTemplate.xlsx`;

		fetch(templateFileLink)
			.then(resp => (resp.status === 200 ? resp.blob() : Promise.reject(new Error('error'))))
			.then(blob => {
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = url;
				a.download = 'daily_quotes_template.xls';

				document.body.appendChild(a);
				a.click();

				window.URL.revokeObjectURL(url);
			});
	};

	const handleQuoteDelete = (quoteIndex: number) => {
		setUploadedQuotes(sortedQuotes.filter((quote, index) => index !== quoteIndex));
	};

	const handleClearAll = () => {
		setUploadedQuotes([]);
	};

	return (
		<div className={styles.wrap}>
			<UploadQuotesInput handleInput={handleInput} setErrorsCount={setErrorsCount} />

			<button className={styles.templateDownload} onClick={downloadTemplate}>
				Download list template
			</button>

			{uploadedQuotes.length ? (
				<>
					<div className={styles.tableHeader}>
						<span>
							Detected {uploadedQuotes.length} Daily Quotes
							{errorsCount ? (
								<>
									{', '}
									<span style={{ color: '#D00074' }}>{`but ${errorsCount} have errors.`}</span>
								</>
							) : (
								<></>
							)}
						</span>

						<button title="Clear all" className={styles.clearAllButton} onClick={handleClearAll}>
							Clear all
						</button>
					</div>

					<ImportedQuotesTable quotes={sortedQuotes} handleQuoteDelete={handleQuoteDelete} />
				</>
			) : null}

			<div className={styles.footer}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.footer_button}
					title="Cancel"
					clickHandler={onClose}
				/>
				{isLoading ? (
					<Loader loadProps={{ disableCenterStyle: true, disableMargin: true }} />
				) : (
					<ButtonDefault
						classList={['primary', 'lg']}
						parentClassName={cx(styles.footer_button, {
							[styles.disable_submit]: uploadedQuotes.length,
						})}
						title={isEditing ? 'Save' : 'Create'}
						disabled={!uploadedQuotes.length || !!errorsCount}
						clickHandler={handleImport}
					/>
				)}
			</div>
		</div>
	);
};
