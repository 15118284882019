import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { STORE_ITEMS_QUERY_KEY } from '../../../core/constants';

export const useCachedTotalStoreItems = () => {
	const queryClient = useQueryClient();
	const [totalItems, setTotalItems] = useState(0);

	queryClient.getQueryCache().subscribe(cache => {
		if (cache?.query?.queryKey?.includes(STORE_ITEMS_QUERY_KEY))
			setTimeout(() => setTotalItems(cache?.query?.state?.data?.pages?.[0]?.total ?? 0), 300);
	});

	return totalItems;
};
