import React, { FC, useEffect, useState } from 'react';

// images
import { ReactComponent as OfferButtonIcon } from 'assets/icons/offer-button-icon.svg';

import Dialog from 'shared/components/newUI/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';

import { SpecialOfferFormType } from '../SpecialOfferPopup';

import { OfferResponse } from '@joc/api-gateway/lib/api-client';

import styles from './SpecialOfferPreview.module.scss';
import cx from 'classnames';

type SpecialOfferPreviewProps = {
	offer: SpecialOfferFormType | OfferResponse;
	onClose: () => void;
};

const SpecialOfferPreview: FC<SpecialOfferPreviewProps> = ({ offer, onClose }) => {
	const [img, setImg] = useState('');

	useEffect(() => {
		if (offer.mediaPath)
			setImg(offer.mediaPath instanceof File ? URL.createObjectURL(offer.mediaPath) : offer.mediaPath);

		return () => {
			URL.revokeObjectURL(img);
		};
	}, [offer]);
	return (
		<Dialog open={true} maxWidth="sm">
			<section className={styles.specialOfferPreview}>
				<ButtonCross clickHandler={onClose} parentClassName={styles.specialOfferPreview__close} />

				<span className={styles.specialOfferPreview__note}>
					Note: Font sizes may differ from the mobile app.
				</span>

				<div
					className={cx(styles.specialOfferPreview__item, {
						[styles.specialOfferPreview__item_noImage]: !offer.mediaPath,
					})}
					style={{ backgroundImage: `url(${img})` }}
				>
					<div className={styles.specialOfferPreview__item_left}>
						<div className={styles.specialOfferPreview__item_badge}>
							<span>{offer.name}</span>
						</div>
						{/*<SpecialOfferIcon className={styles.specialOfferPreview__item_offerIcon} />*/}

						<span
							className={styles.specialOfferPreview__title}
							style={{
								fontSize: offer.title.length < 10 ? '40px' : offer.title.length < 15 ? '30px' : '20px',
							}}
						>
							{offer.title}
						</span>

						<button className={styles.specialOfferPreview__button}>
							<span>{offer.linkTitle}</span>
							<OfferButtonIcon height={16} width={16} />
						</button>
					</div>
					{/*<div className={styles.specialOfferPreview__item_right}>*/}
					{/*	<img src={img} alt="Special offer" title="Recommended size: 328×164px (Aspect ratio 2:1)" />*/}
					{/*</div>*/}
				</div>
			</section>
		</Dialog>
	);
};

export default SpecialOfferPreview;
