import { FC } from 'react';

import { ReactComponent as Checked } from 'assets/icons/checked.svg';
import { ReactComponent as UnChecked } from 'assets/icons/unchecked.svg';

type RadioButtonProps = {
	checked: boolean;
	onClick: () => void;
};

export const RadioButton: FC<RadioButtonProps> = ({ checked, onClick }) =>
	checked ? <Checked /> : <UnChecked style={{ cursor: 'pointer' }} onClick={onClick} />;
