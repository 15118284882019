import React, { FC, useMemo, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import cx from 'classnames';

// components
import Dialog from 'shared/components/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import DonationItemForm from '../../DonationForms/DonationItemForm';
import ButtonOutlined from 'shared/components/Buttons/ButtonOutlined';

// hooks
import { useCreateDonationItem, useEditDonationItem } from 'hooks/store';

// helpers
import { sendFilesToS3Bucket } from 'core/functions';
import { prepareImages } from 'shared/components/SortableImagesListField';
import { DONATION_TYPE } from '@joc/api-gateway/lib/api-client';

// validation
import { donationItemValidationSchema, DonationTypeEnum } from '../../helpers';

// types
import { IDonationResponse, IUpdateDonationRequest } from '@joc/api-gateway';
import { DonationItemFormType, DonationItemResponseType } from '../../types';

import styles from './DonationItemPopup.module.scss';

type DonationItemPopupProps = {
	donationItem?: DonationItemResponseType;
	open?: boolean;
	onClose?: () => void;
};

const DonationItemPopup: FC<DonationItemPopupProps> = ({ donationItem, open = true, onClose }) => {
	const { createDonationItem } = useCreateDonationItem();
	const { editDonationItem } = useEditDonationItem();
	const [isSaving, setIsSaving] = useState(false);
	const [donationType, setDonationType] = useState<DONATION_TYPE>(DONATION_TYPE.CAMPAIGN);
	const formikRef = useRef<FormikProps<DonationItemFormType>>(null);

	const initValues = {
		title: '',
		subtitle: '',
		description: '',
		productUrl: '',
		mediaPathes: [],
		price: null,
		expirationDate: null,
		countryIds: [],
	} as unknown as DonationItemFormType;

	const initialValues: DonationItemFormType = useMemo(() => {
		if (donationItem) {
			setDonationType(donationItem.donationType);

			return {
				...donationItem,
				price:
					donationItem.donationType === DONATION_TYPE.CAMPAIGN
						? donationItem.goal
						: donationItem.suggestedCoinAmount,
				mediaPathes: prepareImages(donationItem.mediaPathes),
			} as unknown as DonationItemFormType;
		}

		return initValues;
	}, [donationItem]);

	const validationSchema = useMemo(() => {
		formikRef.current?.setValues(initValues);
		formikRef.current?.setErrors({});
		formikRef.current?.setTouched({});
		return donationItemValidationSchema(donationType);
	}, [donationType]);

	const handleSubmitClickHandler = async (values: DonationItemFormType) => {
		setIsSaving(true);
		const { mediaPathes, price, expirationDate, ...body } = values;

		const uploadedImages = await Promise.all(
			mediaPathes.map(async image => {
				if (image.file) {
					const [imageUrl] = await sendFilesToS3Bucket([image.file]);

					return imageUrl;
				}

				return Promise.resolve(image.src);
			})
		);

		const adjustDate = (selectedDate?: Date) => {
			const today = moment().startOf('day'); // Today at 00:00:00
			const selectedDateMoment = moment(selectedDate);

			if (selectedDate) {
				if (selectedDateMoment.isSame(today, 'day')) {
					return selectedDateMoment.endOf('day').toDate(); // Set to 23:59:59 if it's today
				}
				return selectedDateMoment.toDate();
			}
			return null;
		};

		if (donationItem) {
			editDonationItem({
				id: donationItem.id,
				donationItem: {
					...body,
					...(donationType === DONATION_TYPE.CAMPAIGN
						? { goal: price ? Number(price) : 0, suggestedCoinAmount: null, donationType }
						: { suggestedCoinAmount: price ? Number(price) : 0, goal: null, donationType }),
					expirationDate: adjustDate(expirationDate),
					mediaPathes: uploadedImages,
				} as unknown as IUpdateDonationRequest,
			});
		} else {
			createDonationItem({
				...body,
				...(donationType === DONATION_TYPE.CAMPAIGN
					? { goal: price ? Number(price) : 0, suggestedCoinAmount: null, donationType }
					: { suggestedCoinAmount: price ? Number(price) : 0, goal: null, donationType }),
				isPublished: false,
				expirationDate: adjustDate(expirationDate),
				mediaPathes: uploadedImages,
			} as unknown as IDonationResponse);
		}

		closePopupClickHandler();
		setIsSaving(false);
	};

	const closePopupClickHandler = () => onClose?.();

	return (
		<Dialog open={open} maxWidth="xl" isFullScreen={true}>
			<section className={styles.donationItemPopup}>
				<span className={styles.donationItemPopup__title}>{donationItem ? 'Edit' : 'Create'} Donation</span>
				<ButtonCross clickHandler={closePopupClickHandler} parentClassName={styles.donationItemPopup__close} />
				<div className={styles.donationItemPopup__switch}>
					<ButtonOutlined
						title={DonationTypeEnum.CampaignDonation}
						parentClassName={cx(styles.donationItemPopup__switch_button, {
							[styles.donationItemPopup__switch_button_active]: donationType === DONATION_TYPE.CAMPAIGN,
						})}
						clickHandler={() => setDonationType(DONATION_TYPE.CAMPAIGN)}
					/>
					<ButtonOutlined
						title={DonationTypeEnum.DedicatedDonation}
						parentClassName={cx(styles.donationItemPopup__switch_button, {
							[styles.donationItemPopup__switch_button_active]: donationType === DONATION_TYPE.DEDICATED,
						})}
						clickHandler={() => setDonationType(DONATION_TYPE.DEDICATED)}
					/>
				</div>
				<Formik
					innerRef={formikRef}
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmitClickHandler}
				>
					<DonationItemForm
						isNew={!donationItem}
						isSaving={isSaving}
						donationType={donationType}
						onCancel={closePopupClickHandler}
					/>
				</Formik>
			</section>
		</Dialog>
	);
};
export default DonationItemPopup;
