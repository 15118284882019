import React, { FC, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { getUserPhotoSrc } from 'core/functions';
import { IMAGE_WITH_POPUP_TYPE } from 'core/types';
import { IBasicVolunteerResponse, IOpportunityResponse, IUserResponse, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import ZoomPhotoPopup from '../../../ZoomPhotoPopup';

import styles from './AvatarCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';

type AvatarCellProps = {
	imagePath: string | undefined;
	opportunityType?: IMAGE_WITH_POPUP_TYPE;
	opportunity?: IOpportunityResponse;
	user?: IUserResponse | IBasicVolunteerResponse;
	donationVolunteerName?: string;
	offerTitle?: string;
	parentClassName?: string;
	withPopup?: boolean;
};

const AvatarCell: FC<AvatarCellProps> = ({
	imagePath,
	opportunityType,
	opportunity,
	user,
	donationVolunteerName,
	offerTitle,
	parentClassName,
	withPopup = false,
}: AvatarCellProps) => {
	const [isImageLoadError, setIsImageLoadError] = useState(false);
	const [correctImagePath, setImagePath] = useState<string>();
	const [isShowPopup, setIsShowPopup] = useState(false);
	const onError = () => setIsImageLoadError(true);

	useEffect(() => {
		if (
			opportunity?.opportunityType !== OPPORTUNITY_TYPES.MANUAL &&
			!opportunity?.filePathes?.length &&
			!opportunity?.imagePath
		) {
			const logoImagePath = opportunity?.organisation?.organizationLogoPath;
			setImagePath(
				logoImagePath
					? `${process.env.REACT_APP_S3_API}/${logoImagePath}`
					: getUserPhotoSrc(isImageLoadError ? null : imagePath, opportunityType)
			);
		} else {
			setImagePath(getUserPhotoSrc(isImageLoadError ? null : imagePath, opportunityType));
		}
	}, [imagePath, opportunityType, isImageLoadError]);

	const title = useMemo(() => {
		if (user) {
			return `${user.firstName} ${user.lastName}`;
		}

		if (donationVolunteerName) return donationVolunteerName;

		if (offerTitle) return offerTitle;

		return opportunity?.organisation?.organizationName || 'Private';
	}, [opportunity, user]);

	return withPopup ? (
		<ZoomPhotoPopup
			urlPhoto={correctImagePath}
			setIsShowPopup={setIsShowPopup}
			isShowPopup={isShowPopup}
			classNames={[styles.avatar, defaultStyles.main, (parentClassName && parentClassName) || '']}
			title={title}
		/>
	) : (
		<div className={cx(styles.avatar, defaultStyles.main, parentClassName)}>
			<img src={correctImagePath} alt="avatar" onError={onError} />
		</div>
	);
};

export default AvatarCell;
