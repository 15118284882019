import { useInfiniteQuery } from '@tanstack/react-query';
import { DonationSort, SearchDonationsRequest, SearchDonationsResponse } from '@joc/api-gateway/lib/api-client';
import { API } from 'core/API';
import { INITIAL_PAGINATION, STORE_DONATIONS_QUERY_KEY } from 'core/constants';

const fetchDonationItems = async ({
	skip,
	take,
	search,
	sort,
}: {
	skip: number;
	take: number;
	search?: string;
	sort?: DonationSort;
}): Promise<SearchDonationsResponse> =>
	API.searchDonationByAdmin(
		SearchDonationsRequest.fromJS({
			pagination: {
				skip,
				take,
			},
			sort,
			...(search ? { search } : {}),
		})
	);

export const useDonationItems = (search: string, sort?: DonationSort, enabled = true) =>
	useInfiniteQuery<SearchDonationsResponse, unknown>({
		queryKey: [STORE_DONATIONS_QUERY_KEY, { search, sort }],
		initialPageParam: 0,
		refetchOnWindowFocus: false,
		enabled,
		queryFn: ({ pageParam = 0 }) =>
			fetchDonationItems({ skip: pageParam as number, take: INITIAL_PAGINATION.take, search, sort }),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage.records.length < INITIAL_PAGINATION.take ? undefined : allPages.length * 10;
		},
	});
