import * as Yup from 'yup';
// functions
import { isValidPhoneNumber } from 'libphonenumber-js';
// constants
import { REGEX_WEBSITE } from 'core/constants';
import moment from 'moment-mini';

export const firstNameSchema = Yup.string()
	.min(2, 'First name should be more than 2 characters')
	.required('First name is required');

export const lastNameSchema = Yup.string()
	.min(2, 'Last name should be more than 2 characters')
	.required('Last name is required');

export const emailSchema = Yup.string().email('Invalid email').required('Email is required');

export const phoneNumberSchema = Yup.string()
	.test('validate phone number', 'Invalid phone number', phone => (phone ? isValidPhoneNumber(phone) : false))
	.required('Phone is required');

export const phoneNotRequiredNumberSchema = Yup.string()
	.nullable(true)
	.transform((_, phone) => (!!!phone ? null : phone))
	.test('validate phone number', 'Invalid phone number', phone => (!!phone ? isValidPhoneNumber(phone) : true))
	.max(15);

export const birthDateSchema = Yup.date()
	.nullable(true)
	.required('Date of birth is required')
	.typeError('Please, fill full date');

export const opportunityDateSchema = Yup.date()
	.nullable(true)
	.required('Start date is required')
	.typeError('Please, fill full date');

export const genderSchema = Yup.number().required('Gender is required').nullable(true);

export const addressSchema = Yup.object()
	.shape({
		countryName: Yup.string().required('Country is required'),
		stateName: Yup.string(),
		cityName: Yup.string().required('City is required'),
		streetName: Yup.string().required('Street is required'),
		buildingNumber: Yup.string(),
		zipCode: Yup.string(),
		pobox: Yup.string(),
		longitude: Yup.number().typeError(''),
	})
	.required('Address is required')
	.nullable(true);

export const positionSchema = Yup.number().required('Position is required').moreThan(0, 'Position is required');

export const organizationNameShema = Yup.string()
	.min(2, 'Organization name should be more than 2 characters')
	.required('Organization name is required');

export const imagePathSchema = Yup.string().required('Logo is required');

export const organizationDescriptionSchema = Yup.string()
	.min(1, 'Organization description should be more than 1 character')
	.required('Organization description  is required');

export const websiteSchema = Yup.string()
	.matches(REGEX_WEBSITE, 'Invalid website link')
	.required('Website is required');

export const passwordSchema = Yup.string().required('Password is required');

export const passwordNotRequiredSchema = Yup.string().min(6, 'Password must include 6 or more characters');

export const opportunityNameSchema = Yup.string()
	.min(2, 'Opportunitie name should be more than 2 character')
	.required('Opportunitie name is required');

export const opportunityUrlSchema = Yup.string().required('Zoom link is required');

export const opportunityDescriptionSchema = Yup.string()
	.min(2, 'Opportunitie decription should be more than 1 character')
	.required('Opportunitie name is required');

export const opportunityApprovalRequired = Yup.boolean().required('Opportunity approval is required').nullable(true);

export const startTimeSchema = Yup.string().required('Start time is required');

export const endTimeSchema = Yup.string().required('End time is required');

export const opportunityTypesSchema = Yup.string()
	.nullable(true)
	.required('Opportunity type is required')
	.typeError('Opportunity type is required');

export const dailyQuoteMessageSchema = Yup.string()
	.max(80, 'Quote is too long')
	.required('Daily quote message is required');

export const dailyQuoteStartDateSchema = Yup.date()
	.nullable(true)
	.required('Start date is required')
	.typeError('Please, fill full date');

export const FiltersStartDateSchema = Yup.date()
	.nullable()
	.test({
		name: 'lessThanEndTime',
		message: 'Start time must be before end time',
		test(value) {
			if (
				value &&
				moment(value).isValid() &&
				moment(this.parent.toDate).isValid() &&
				moment(value, 'DD.MM.YYYY').isSame(moment(this.parent.toDate, 'DD.MM.YYYY'), 'day')
			) {
				return moment(value, 'HH:mm').isBefore(moment(this.parent.toDate, 'HH:mm'));
			}

			return true;
		},
	})
	.typeError('Please provide the full date');

export const FiltersEndDateSchema = Yup.date()
	.nullable()
	.test({
		name: 'moreThenStartDate',
		message: 'End date must be after start date',
		test(value) {
			if (value) {
				return moment(value, 'DD.MM.YYYY').isSameOrAfter(moment(this.parent.fromDate, 'DD.MM.YYYY'), 'day');
			}

			return true;
		},
	})
	.typeError('Please provide the full date');

export const DateRangeFiltersValidationSchema = Yup.object().shape({
	fromDate: FiltersStartDateSchema,
	toDate: FiltersEndDateSchema,
});

export const opportunityUserSchema = Yup.number().required('Coordinator is required').nullable(true);

export const roleSchema = Yup.number().required('Role is required');

export const organisationIdSchema = Yup.string().required('Organisation is required');

export const opportunityValidationSchemaWithAddress = Yup.object().shape({
	opportunityName: opportunityNameSchema,
	opportunityDescription: opportunityDescriptionSchema,
	opportunityTypes: opportunityTypesSchema,
	isApprovalRequired: opportunityApprovalRequired,
	startTime: startTimeSchema,
	endTime: endTimeSchema,
	user: opportunityUserSchema,
	startDay: opportunityDateSchema,
	address: addressSchema,
	organisationId: organisationIdSchema,
});

export const opportunityValidationSchemaWithUrl = Yup.object().shape({
	opportunityName: opportunityNameSchema,
	opportunityDescription: opportunityDescriptionSchema,
	opportunityTypes: opportunityTypesSchema,
	isApprovalRequired: opportunityApprovalRequired,
	startTime: startTimeSchema,
	endTime: endTimeSchema,
	user: opportunityUserSchema,
	startDay: opportunityDateSchema,
	opportunityUrl: opportunityUrlSchema,
	organisationId: organisationIdSchema,
});

export const opportunityManualValidationSchema = Yup.object().shape({
	opportunityName: opportunityNameSchema,
	opportunityDescription: opportunityDescriptionSchema,
	isApprovalRequired: opportunityApprovalRequired,
	startTime: startTimeSchema,
	endTime: endTimeSchema,
	user: opportunityUserSchema,
	startDay: opportunityDateSchema,
	address: addressSchema,
	organisationId: organisationIdSchema,
});

export const creatOrgganizationToEditRolePopup = Yup.object().shape({
	organisationId: Yup.string(),
	invitedUserRole: Yup.number(),
});

export const userVolunteerInfoFormValidationSchema = Yup.object().shape({
	firstName: firstNameSchema,
	lastName: lastNameSchema,
	email: emailSchema,
	phoneNumber: phoneNotRequiredNumberSchema,
	password: passwordNotRequiredSchema,
});

export const dailyQuoteValidationSchema = Yup.object().shape({
	message: dailyQuoteMessageSchema,
	startDate: dailyQuoteStartDateSchema,
});
