import { FC, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';

// new material-ui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { dateTimePickerToolbarClasses } from '@mui/x-date-pickers';

// components
import FieldWrapper from 'shared/components/Inputs/FieldWrapper';

// icons
import { ReactComponent as CrossSvg } from 'assets/image/cross-button.svg';

// types
import { DateTimePickerProps } from './types';

// styled
import { DateTimePickerStyled } from './styles';
import { ClearIcon } from '@mui/x-date-pickers/icons';

export const DateTimePicker: FC<DateTimePickerProps> = ({ availableFuture, availablePast, ...fieldBaseProps }) => {
	const [value, setValue] = useState<Moment | null>(null);
	const [isHighlight, setIsHighlight] = useState(false);

	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const handleClosePicker = () => setIsPickerOpen(false);
	const popperRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLImageElement>();

	const { field, form, disabled } = fieldBaseProps;
	const { setFieldValue, setFieldTouched } = form;

	const onChange = (date: Moment | null) => {
		setFieldValue(field.name, date);
		setIsHighlight(true);
	};

	const onTouchHandler = () => {
		setFieldTouched(field.name, true, true);
	};

	useEffect(() => {
		setIsHighlight(!!field?.value);
		setValue(moment(field.value));
		setFieldTouched(field.name, true, true);
	}, [field.value]);

	return (
		<FieldWrapper {...fieldBaseProps} isMarginLeftUnset={true}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DateTimePickerStyled
					disabled={disabled}
					value={value}
					className={isHighlight ? 'selected' : ''}
					disablePast={!availablePast}
					disableFuture={!availableFuture}
					onChange={onChange}
					onClose={() => setIsPickerOpen(!isPickerOpen)}
					onOpen={() => setIsPickerOpen(!isPickerOpen)}
					onSelectedSectionsChange={onTouchHandler}
					timeSteps={{ hours: 1, minutes: 1 }}
					selectedSections={null}
					open={isPickerOpen}
					onAccept={handleClosePicker}
					views={['year', 'month', 'day', 'hours', 'minutes']}
					slotProps={{
						textField: {
							inputRef,
							onClick: () => {
								inputRef.current?.blur();
								setIsPickerOpen(true);
							},
							sx: {
								'.MuiOutlinedInput-input': {
									color: isHighlight ? '#1C2F4C !important' : '#5B768B !important',
								},
							},
						},
						popper: {
							ref: popperRef,
							placement: 'bottom-end',
							modifiers: [
								{
									name: 'flip',
									options: {
										fallbackPlacements: ['left', 'start'],
									},
								},
							],
							sx: {
								'.MuiPaper-root': {
									borderRadius: '10px',
								},
							},
						},
						layout: {
							sx: {
								'& .MuiPickersLayout-contentWrapper': {
									'button, li': {
										'&.Mui-selected': {
											backgroundColor: '#214ba5 !important',

											'&:hover': {
												backgroundColor: '#214ba5',
											},
										},
									},
								},
							},
						},
						actionBar: {
							sx: {
								button: {
									color: '#214ba5',
								},
							},
						},
						toolbar: {
							hidden: true,
							sx: {
								[`&.${dateTimePickerToolbarClasses.root} .MuiPickersToolbar-content .Mui-selected`]: {
									color: '#214ba5',
								},
							},
						},
					}}
				/>
			</LocalizationProvider>
		</FieldWrapper>
	);
};
