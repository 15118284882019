import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

// redux
import { Store } from '../../../redux/root';

// hooks
import { useCachedTotalOffers } from 'hooks/store';
import { useCachedTotalStatuses } from 'hooks/statuses';

// components
import TabNavItem from 'shared/components/TabNav/TabNavItem';
import TabNav from 'shared/components/TabNav';

// types
import { MARKETING_TABS } from './types';

// styles
import styles from './MarketingContent.module.scss';

type StoreManagementTabsProps = {
	activeTab: MARKETING_TABS;
	// eslint-disable-next-line no-unused-vars
	setActiveTab: (tab: MARKETING_TABS) => void;
};

export const MarketingTabs: FC<StoreManagementTabsProps> = ({ activeTab, setActiveTab }) => {
	const history = useHistory();
	const location = useLocation();

	const statusesTotal = useCachedTotalStatuses();
	const challengesTotal = useSelector((store: Store) => store.challengeRedux.challengesTotal);
	const dailyQuotesTotal = useSelector((store: Store) => store.quotesRedux.quotesTotal);
	const offersTotal = useCachedTotalOffers();

	useEffect(() => {
		if (location.search.includes('statuses')) setActiveTab(MARKETING_TABS.Statuses);
		if (location.search.includes('challenges')) setActiveTab(MARKETING_TABS.CHALLENGES);
		if (location.search.includes('daily-quotes')) setActiveTab(MARKETING_TABS.DAILY_QUOTES);
		if (
			location.search.includes('special-offer') &&
			!window.location.href.includes('https://adminapp.justonechesed.org')
		)
			setActiveTab(MARKETING_TABS.SPECIAL_OFFER);
	}, [location.search]);

	useEffect(() => {
		if (!location.search) history.push({ search: '?store' });
	}, []);

	return (
		<TabNav>
			<TabNavItem
				parentClassName={styles.generateGroup}
				title={MARKETING_TABS.Statuses}
				activeTab={activeTab === MARKETING_TABS.Statuses}
				counter={statusesTotal}
				clickHandler={() => {
					history.push({ search: '?statuses' });
					setActiveTab(MARKETING_TABS.Statuses);
				}}
			/>
			<TabNavItem
				parentClassName={styles.generateGroup}
				title={MARKETING_TABS.CHALLENGES}
				activeTab={activeTab === MARKETING_TABS.CHALLENGES}
				counter={challengesTotal}
				clickHandler={() => {
					history.push({ search: '?challenges' });
					setActiveTab(MARKETING_TABS.CHALLENGES);
				}}
			/>
			<TabNavItem
				parentClassName={styles.generateGroup}
				title={MARKETING_TABS.DAILY_QUOTES}
				activeTab={activeTab === MARKETING_TABS.DAILY_QUOTES}
				counter={dailyQuotesTotal}
				clickHandler={() => {
					history.push({ search: '?daily-quotes' });
					setActiveTab(MARKETING_TABS.DAILY_QUOTES);
				}}
			/>
			{!window.location.href.includes('https://adminapp.justonechesed.org') ? (
				<TabNavItem
					parentClassName={styles.generateGroup}
					title={MARKETING_TABS.SPECIAL_OFFER}
					activeTab={activeTab === MARKETING_TABS.SPECIAL_OFFER}
					counter={offersTotal}
					clickHandler={() => {
						history.push({ search: '?special-offer' });
						setActiveTab(MARKETING_TABS.SPECIAL_OFFER);
					}}
				/>
			) : null}
		</TabNav>
	);
};
