import { FC, useMemo, useRef } from 'react';
import { useStatusesContext } from '../../Statuses.context';
import { Form, Formik, FormikProps } from 'formik';

// components
import FiltersHeader from 'components/Organization/Filters/FiltersHeader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import { DateTimeFields } from 'shared/components/Pickers/NewUI/DateTimeFields';

// helpers
import { DateRangeFiltersValidationSchema } from 'core/validation';

// types
import { IDateRange } from '@joc/api-gateway';

// styles
import styles from './StatusesFilters.module.scss';

// eslint-disable-next-line no-unused-vars
type FormFilters = { [Key in keyof IDateRange]: Date | null };

const StatusesFilters: FC = () => {
	const formikRef = useRef<FormikProps<FormFilters>>(null);

	const { filters, setFilters, sidebarVisibility, setSidebarVisibility } = useStatusesContext();

	const changeVisibilityHandler = () => setSidebarVisibility(!sidebarVisibility);

	const resetFilters = () => {
		setFilters(prevState => ({ ...prevState, fromDate: undefined, toDate: undefined }));
		formikRef.current?.resetForm();
	};

	const initialValues = useMemo<FormFilters>(
		() => ({
			fromDate: filters?.fromDate ? filters.fromDate : null,
			toDate: filters?.toDate ? filters.toDate : null,
		}),
		[filters]
	);

	const handleSubmit = (values: FormFilters) => setFilters(values as IDateRange);

	return (
		<div className={styles.statusesFilters}>
			<FiltersHeader changeVisibilityFilters={changeVisibilityHandler} resetForm={resetFilters} />
			<hr className={styles.separator} />
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={DateRangeFiltersValidationSchema}
				onSubmit={handleSubmit}
				enableReinitialize={true}
			>
				{({ isValid }) => (
					<Form className={styles.statusesFilters__form}>
						<DateTimeFields showDateInThePast={true} showDateInTheFuture={true} />

						<div className={styles.statusesFilters__form_actions}>
							<ButtonDefault
								disabled={!isValid}
								title="Apply"
								submitType={true}
								classList="secondary md"
							/>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default StatusesFilters;
