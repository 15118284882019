import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@mui/material';
import moment from 'moment';

// components
import ImageWithPopup from 'shared/components/ImageWithPopup';

// helpers
import { isImage, isVideo } from '../helpers';

// icons
import BackButton from 'assets/icons/preview-back-button.png';
import { ReactComponent as ArrowRight } from 'assets/icons/preview-arrow-right.svg';

// types
import { OrganisationTinyResponse, SUPPORTED_MEDIA_FORMAT } from '@joc/api-gateway/lib/api-client';
import { IMAGE_WITH_POPUP_TYPE } from '../../../../../core/types';

// styles
import styles from './StatusPreview.module.scss';

export type Status = {
	id?: number;
	caption?: string;
	linkTitle?: string;
	link?: string;
	mediaPath?: File | string;
	startDate: Date;
	organization?: OrganisationTinyResponse;
	duration: number;
	fileType: SUPPORTED_MEDIA_FORMAT;
};

type StatusPreviewProps = {
	status?: Partial<Status>;
};

const useStyles = makeStyles({
	progress: {
		'&.MuiLinearProgress-determinate': {
			backgroundColor: '#7F7F7F',
			height: 2,
			borderRadius: 4,
			margin: '0 3px',

			'& .MuiLinearProgress-bar': {
				backgroundColor: 'white',
				borderRadius: 4,
			},
		},
	},
});

const StatusPreview: FC<StatusPreviewProps> = ({ status }) => {
	const classes = useStyles();
	const [progress, setProgress] = useState(0);
	const videoRef = useRef<HTMLVideoElement>(null);

	const mediaUrl = useMemo(
		() =>
			status?.mediaPath instanceof File ? URL.createObjectURL(status.mediaPath) : (status?.mediaPath as string),
		[status?.mediaPath]
	);

	useEffect(() => {
		let interval: NodeJS.Timeout;
		setProgress(0);

		const mediaPath = status?.mediaPath;
		if (mediaPath) {
			if (isVideo(mediaPath)) {
				const videoElement = videoRef.current;

				if (!videoElement) return;

				videoElement.play();

				// Обробник події timeupdate
				const handleTimeUpdate = () => {
					if (videoElement.duration > 0) {
						const percentage = (videoElement.currentTime / videoElement.duration) * 100;
						setProgress(Number(percentage.toFixed(2))); // Оновлення прогресу
					}
				};

				// Додаємо обробник
				videoElement.addEventListener('timeupdate', handleTimeUpdate);

				// Очищення обробника при демонтажі
				return () => {
					videoElement.removeEventListener('timeupdate', handleTimeUpdate);
				};
			}

			if (isImage(mediaPath)) {
				const intervalPeriod = 100;
				const startTime = performance.now();
				const totalDuration = 60 * 1000;
				interval = setInterval(() => {
					const elapsedTime = performance.now() - startTime; // Calculate elapsed time
					const calculatedProgress = (elapsedTime / totalDuration) * 100; // Calculate progress

					if (calculatedProgress >= 100) {
						setProgress(100);
						clearInterval(interval); // Clear interval when progress completes
					} else {
						setProgress(calculatedProgress); // Update progress
					}
				}, intervalPeriod);

				return () => {
					clearInterval(interval);
				};
			}
		}
		return () => {
			clearInterval(interval);
		};
		// setDuration(await getDuration(status?.mediaPath));
	}, [status?.mediaPath]);

	const formatDate = (date?: Date) => {
		return moment(date).calendar(null, {
			sameDay: '[Today], hh:mm A',
			lastDay: '[Yesterday], hh:mm A',
			sameElse: 'MMMM Do, YYYY, hh:mm A',
		});
	};

	return (
		<section
			className={styles.statusPreview}
			style={
				status?.mediaPath && isImage(status.mediaPath)
					? {
							backgroundImage: `url('${mediaUrl}')`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
					  }
					: {}
			}
		>
			{status?.mediaPath && isVideo(status.mediaPath) ? (
				// eslint-disable-next-line jsx-a11y/media-has-caption
				<video ref={videoRef} className={styles.statusPreview__video} src={mediaUrl} />
			) : (
				<></>
			)}
			<div className={styles.statusPreview__header}>
				<LinearProgress className={classes.progress} variant="determinate" value={progress} />
				<div className={styles.statusPreview__header__details}>
					<img
						src={BackButton}
						alt="preview-back-button"
						className={styles.statusPreview__header__details_back}
					/>
					<ImageWithPopup
						imagePath={undefined}
						classNames={styles.statusPreview__header__details_image}
						alt={'Just One Chesed'}
						type={IMAGE_WITH_POPUP_TYPE.LOGO}
					/>
					<div className={styles.statusPreview__header__details__org}>
						<span className={styles.statusPreview__header__details__org_title}>{'Just One Chesed'}</span>
						<span className={styles.statusPreview__header__details__org_startDate}>
							{formatDate(status?.startDate)}
						</span>
					</div>
				</div>
			</div>
			{status?.caption ? (
				<div className={styles.statusPreview__footer}>
					<span className={styles.statusPreview__footer__caption}>{status.caption}</span>
					{status?.linkTitle || status?.link ? (
						<div className={styles.statusPreview__footer__link_wrap}>
							<a
								href={status.link}
								className={styles.statusPreview__footer__link}
								target="_blank"
								rel="noreferrer"
							>
								{status.linkTitle}
							</a>
							<ArrowRight width={16} height={16} className={styles.statusPreview__footer__link_img} />
						</div>
					) : (
						<></>
					)}
				</div>
			) : (
				<></>
			)}
		</section>
	);
};
export default StatusPreview;
