import { FC } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
// components
import { DateTimePicker } from 'shared/components/Pickers/NewUI/DateTimePicker';
import { DatePicker } from 'shared/components/Pickers/NewUI/DatePicker';

// styles
import styles from './index.module.scss';

interface IDateTimeFieldsProps {
	withoutTime?: boolean;
	isReadOnlyInput?: boolean;
	showDateInThePast: boolean;
	showDateInTheFuture?: boolean;
}

export const DateTimeFields: FC<IDateTimeFieldsProps> = ({
	showDateInThePast,
	isReadOnlyInput = false,
	showDateInTheFuture = true,
	withoutTime,
}) => {
	return (
		<>
			<div className={styles.start__fields}>
				<p className={styles.title}>From:</p>
				<div className={styles.wrapper}>
					<div className={cx(styles.form__field, styles.field_picker, styles.field__date_start)}>
						<Field
							name="fromDate"
							isDisableHeight={true}
							isDisabledMargin={true}
							component={withoutTime ? DatePicker : DateTimePicker}
							isReadOnlyInput={isReadOnlyInput}
							availablePast={showDateInThePast}
							availableFuture={showDateInTheFuture}
						/>
					</div>
				</div>
			</div>
			<div className={styles.end__fields}>
				<p className={styles.title}>To:</p>
				<div className={styles.wrapper}>
					<div className={cx(styles.form__field, styles.field__date_end)}>
						<Field
							name="toDate"
							isDisableHeight={true}
							isDisabledMargin={true}
							component={withoutTime ? DatePicker : DateTimePicker}
							isReadOnlyInput={isReadOnlyInput}
							availablePast={showDateInThePast}
							availableFuture={showDateInTheFuture}
						/>
					</div>
				</div>
			</div>
		</>
	);
};
