import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { STATUSES_QUERY_KEY } from '../../core/constants';

export const useCachedTotalStatuses = () => {
	const queryClient = useQueryClient();
	const [totalItems, setTotalItems] = useState(0);

	queryClient.getQueryCache().subscribe(cache => {
		if (cache?.query?.queryKey?.includes(STATUSES_QUERY_KEY))
			setTimeout(() => setTotalItems(cache?.query?.state?.data?.pages?.[0]?.total ?? 0), 300);
	});

	return totalItems;
};
