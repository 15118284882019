import React, { FC, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

// components
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import Dialog from 'shared/components/Dialog';
import Loader from 'shared/components/Loader';
import DonationInfoTable from '../../DonationTables/DonationInfoTable';
import StoreActions from '../../../Store/StoreActions';

// types
import { SearchDonationVolunteersRequest } from '@joc/api-gateway/lib/api-client';

// styles
import styles from './DonationInfoPopup.module.scss';
import moment from 'moment/moment';

type DonationInfoPopupProps = {
	fileName: string;
	donationItemId: number;
	open?: boolean;
	onClose?: () => void;
};

const DonationInfoPopup: FC<DonationInfoPopupProps> = ({ fileName, donationItemId, open = true, onClose }) => {
	const closePopupClickHandler = () => onClose?.();

	const [search, setSearch] = useState('');

	const [isDownloading, setIsDownloading] = useState(false);

	const searchHandler = (value: string) => setSearch(value);
	const downloadListHandler = async () => {
		try {
			setIsDownloading(true);
			const accessToken = localStorage.getItem('accessToken');
			if (!accessToken) throw new Error('Sorry, we can`t find your access token');
			const timeZone = `${moment(new Date()).format('Z')}`;

			const volunteersResponse = await axios(
				`${process.env.REACT_APP_JOC_API}/donations/${donationItemId}/exportDonationVolunteers`,
				{
					method: 'POST',
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'x-time-zone-offset': timeZone.toString(),
					},
					data: SearchDonationVolunteersRequest.fromJS(search ? { search } : {}),
				}
			);

			saveAs(volunteersResponse.data, fileName);
			setIsDownloading(false);
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error);
			setIsDownloading(false);
		}
	};

	return (
		<>
			<Dialog open={open} maxWidth="xl" isInfo={true} isFullScreen={true}>
				<section className={styles.donationsInfoPopup}>
					<span className={styles.donationsInfoPopup__title}>Donations info</span>
					<ButtonCross
						clickHandler={closePopupClickHandler}
						parentClassName={styles.donationsInfoPopup__close}
					/>
					<StoreActions onSearchStoreItem={searchHandler} onDownloadList={downloadListHandler} />

					<DonationInfoTable donationItemId={donationItemId} search={search} />

					{isDownloading ? <Loader loadProps={{ stylePageCenter: true }} /> : null}
				</section>
			</Dialog>
		</>
	);
};

export default DonationInfoPopup;
