import React, { FC, useEffect, useState } from 'react';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import cx from 'classnames';

// helpers
import { MAX_BADGE_TITLE_LENGTH, MAX_BUTTON_NAME_LENGTH, MAX_TITLE_LENGTH } from '../helpers';

// components
import InputText from 'shared/components/FormInputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import MediaInput from 'shared/components/MediaInput';
import { RadioButton } from './RadioButton';
import StoreItemSelect from 'shared/components/Selects/SelectDropdown/StoreItemSelect';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import Tooltip from 'shared/components/Tooltip';

// types
import { SpecialOfferFormType } from '../SpecialOfferPopup';
import { StoreItemResponse } from '@joc/api-gateway';

// styles
import styles from './SpecialOfferForm.module.scss';

type SpecialOfferFormProps = {
	isNew: boolean;
	onCancel: () => void;
	onPreviewOpen: () => void;
	storeItems: Array<StoreItemResponse>;
	isSaving: boolean;
};

const SpecialOfferForm: FC<SpecialOfferFormProps> = ({
	isNew,
	onCancel,
	onPreviewOpen,
	storeItems = [],
	isSaving = false,
}) => {
	const { values, dirty, isValid, setFieldValue, setFieldTouched } = useFormikContext<SpecialOfferFormType>();
	const [isStoreItem, setIsStoreItem] = useState(true);
	const [isUrl, setIsUrl] = useState(false);
	const handlePreviewOpen = () => onPreviewOpen?.();
	const handleCancel = () => onCancel?.();

	useEffect(() => {
		if (!storeItems.length) {
			setIsUrl(true);
			setIsStoreItem(false);
			setFieldValue('itemType', 'url').finally();
			setFieldValue('storeItemId', '').finally();
		} else {
			setIsStoreItem(values.itemType === 'select');
			setIsUrl(values.itemType === 'url');
		}
	}, [values, storeItems]);

	return (
		<Form className={styles.specialOfferForm}>
			<Field
				name="mediaPath"
				label="Main Photo"
				maxMegabyteSize={100}
				toS3bucket={true}
				isRequired={true}
				placeholder="Aspect ratio 2:1 (328×164 px). Other proportions may be cropped."
				parentClassName={styles.specialOfferForm__item}
				onlyPhotos={true}
				component={MediaInput}
			/>
			<Field name="name">
				{({ field, meta, form }: FieldProps<string, SpecialOfferFormType>) => (
					<div className={styles.specialOfferForm__item}>
						<label className={styles.specialOfferForm__item_label} htmlFor="title">
							Offer Badge Title<span className={styles.specialOfferForm__item_required}>*</span>
						</label>
						<InputText
							id="title"
							field={field}
							placeholder="Enter badge title (e.g. Special Offer)"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{!meta.error || !meta.touched ? (
							<span className={cx(styles.specialOfferForm__item__fieldDescription)}>
								Maximum characters: {field.value.trim().length}/{MAX_BADGE_TITLE_LENGTH}
							</span>
						) : null}
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>
			<Field name="title">
				{({ field, meta, form }: FieldProps<string, SpecialOfferFormType>) => (
					<div className={styles.specialOfferForm__item}>
						<label className={styles.specialOfferForm__item_label} htmlFor="title">
							Special Offer Title<span className={styles.specialOfferForm__item_required}>*</span>
						</label>
						<InputText
							id="title"
							field={field}
							placeholder="Enter offer title"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{!meta.error || !meta.touched ? (
							<span className={cx(styles.specialOfferForm__item__fieldDescription)}>
								Maximum characters: {field.value.trim().length}/{MAX_TITLE_LENGTH}
							</span>
						) : null}
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<div className={styles.specialOfferForm__item}>
				<label className={styles.specialOfferForm__item_label} htmlFor="item">
					Item<span className={styles.specialOfferForm__item_required}>*</span>
				</label>
				<div className={styles.specialOfferForm__item_subFields}>
					<Tooltip
						title={
							!storeItems.length ? 'No available Store Items. Please create items or add coupons.' : false
						}
						arrow={true}
						placement="top"
						disableHoverListener={!!storeItems.length}
						PopperProps={{
							className: styles.specialOfferForm__item_subFields__storeItem_tooltip,
							style: { width: '380px' },
						}}
					>
						<div
							className={styles.specialOfferForm__item_subFields__storeItem}
							style={{ marginBottom: 25 }}
						>
							<RadioButton
								checked={isStoreItem}
								onClick={() => {
									if (storeItems.length) {
										setIsStoreItem(true);
										setIsUrl(false);
										setFieldValue('link', '').then(() => {
											setTimeout(() => setFieldTouched('storeItemId', true, true), 100);
										});
										setFieldValue('itemType', 'select').finally();
									}
								}}
							/>
							<Field name="storeItemId">
								{({ field, form }: FieldProps<string, SpecialOfferFormType>) => (
									<div
										className={cx(styles.specialOfferForm__item, {
											[styles.disabled]: !isStoreItem,
										})}
									>
										<label className={styles.specialOfferForm__item_label} htmlFor="item">
											Shop Item
										</label>
										<StoreItemSelect
											id="item"
											field={field}
											placeholder="Select store item"
											options={storeItems}
											setFieldValue={form.setFieldValue}
											setFieldTouched={form.setFieldTouched}
											disabled={!isStoreItem || !storeItems.length}
											hideValue={!isStoreItem}
										/>
									</div>
								)}
							</Field>
						</div>
					</Tooltip>
					<div className={cx(styles.specialOfferForm__item_subFields__item)} style={{ marginBottom: 4 }}>
						<RadioButton
							checked={isUrl}
							onClick={() => {
								setIsUrl(true);
								setIsStoreItem(false);
								setFieldValue('storeItemId', '').then(() => {
									setTimeout(() => setFieldTouched('link', true, true), 100);
								});
								setFieldValue('itemType', 'url').finally();
							}}
						/>
						<Field name="link">
							{({ field, form }: FieldProps<string, SpecialOfferFormType>) => (
								<div
									className={cx(styles.specialOfferForm__item, {
										[styles.disabled]: !isUrl,
									})}
								>
									<label className={styles.specialOfferForm__item_label} htmlFor="linkTitle">
										URL-Link
									</label>
									<InputText
										id="item"
										field={field}
										placeholder="https://"
										setFieldValue={form.setFieldValue}
										fieldValue={isUrl ? field.value || '' : ''}
										isMultiple={true}
										isDisabled={!isUrl}
										parentClassName={styles.specialOfferForm__item__textfield}
									/>
								</div>
							)}
						</Field>
					</div>
				</div>

				{isStoreItem ? (
					<Field name="storeItemId">
						{({ meta }: FieldProps<string, SpecialOfferFormType>) =>
							meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />
						}
					</Field>
				) : null}

				{isUrl ? (
					<Field name="link">
						{({ meta }: FieldProps<string, SpecialOfferFormType>) =>
							meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />
						}
					</Field>
				) : null}
			</div>

			<Field name="linkTitle">
				{({ field, form, meta }: FieldProps<string, SpecialOfferFormType>) => (
					<div className={styles.specialOfferForm__item}>
						<label className={styles.specialOfferForm__item_label} htmlFor="linkTitle">
							Button Name<span className={styles.specialOfferForm__item_required}>*</span>
						</label>
						<InputText
							id="linkTitle"
							field={field}
							placeholder="Enter button name"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{!meta.error || !meta.touched ? (
							<span className={cx(styles.specialOfferForm__item__fieldDescription)}>
								Maximum characters: {field.value.trim().length}/{MAX_BUTTON_NAME_LENGTH}
							</span>
						) : null}
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<div className={styles.specialOfferForm__footer_actions}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.specialOfferForm__footer_button}
					title="Preview"
					clickHandler={handlePreviewOpen}
				/>

				<div className={styles.specialOfferForm__footer_actions__right}>
					<ButtonDefault
						classList={['secondary', 'lg']}
						parentClassName={styles.specialOfferForm__footer_button}
						title="Cancel"
						clickHandler={handleCancel}
					/>
					<ButtonDefault
						submitType={true}
						classList={['primary', 'lg']}
						parentClassName={cx(styles.specialOfferForm__footer_button, {
							[styles.disable_submit]: !dirty || !isValid,
						})}
						title={isNew ? 'Create' : 'Save'}
						disabled={!dirty || !isValid || isSaving}
					/>
				</div>
			</div>
		</Form>
	);
};

export default SpecialOfferForm;
