import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DonationRequest, IDonationRequest } from '@joc/api-gateway';
import { API } from 'core/API';
import { STORE_DONATIONS_QUERY_KEY } from 'core/constants';
import { DonationResponse } from '@joc/api-gateway/lib/api-client';

interface IUseCreateDonationItem {
	// eslint-disable-next-line no-unused-vars
	createDonationItem: (donationItem: IDonationRequest) => void;
}

const createNewDonationItem = async (donationItem: IDonationRequest) => {
	const body = DonationRequest.fromJS(donationItem);
	if (!body.countryIds?.length) delete body.countryIds;

	return API.createDonation(body);
};

export const useCreateDonationItem = (): IUseCreateDonationItem => {
	const queryClient = useQueryClient();
	const { mutate: createDonationItem } = useMutation<DonationResponse, Error, IDonationRequest>({
		mutationFn: donationItem => createNewDonationItem(donationItem),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_DONATIONS_QUERY_KEY] }),
	});

	return { createDonationItem };
};
