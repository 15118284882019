import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';

// icons
import { ReactComponent as UploadIcon } from 'assets/icons/upload-lg.svg';

// components
import FieldWrapper from 'shared/components/Inputs/FieldWrapper';

// helpers
import { isVideo } from '../MarketingComponent/Statuses/helpers';
import { supportedMimeTypes, supportedPhotoMimeTypes } from '../MarketingComponent/Statuses/StatusesPopup/validation';

// types
import { FieldBaseProps } from 'core/types';

// styles
import styles from './MediaInput.module.scss';

type MediaInputProps = FieldBaseProps<
	Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder' | 'min' | 'max' | 'onKeyPress'> & {
		label: string;
		placeholder?: string;
		maxMegabyteSize?: number;
		toS3bucket?: boolean;
		onlyPhotos?: boolean;
	}
>;

const MediaInput: FC<MediaInputProps> = ({ placeholder, ...fieldBaseProps }) => {
	const { field, form } = fieldBaseProps;
	const inputRef = useRef<HTMLInputElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const mediaUrl = useMemo(
		() => (field.value instanceof File ? URL.createObjectURL(field.value) : (field.value as string)),
		[field.value]
	);

	const deleteMedia = async () => {
		await form.setFieldTouched(field.name, true);
		await form.setFieldValue(field.name, '');
		await form.setFieldValue('fileType', null);
		if (mediaUrl) URL.revokeObjectURL(mediaUrl);
	};

	const selectMediaHandler = () => {
		if (inputRef?.current) {
			inputRef.current.click();
		}
	};

	const changeMediaHandler = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const file = event.target.files?.item(0);
		if (file) {
			form.setFieldTouched(field.name, true, true).finally();
			form.setFieldValue(field.name, file).finally();
			form.setFieldValue('fileType', file.type).finally();
		}

		event.target.value = '';
	};

	const handleLoadedVideo = () => {
		const video = videoRef.current;
		if (!video) return;
		form.setFieldValue('duration', Number(video.duration.toFixed(0)) || 0).finally();
	};

	const handleLoadedImage = () => {
		form.setFieldValue('duration', 60).finally();
	};

	useEffect(() => {
		const makeFieldTouched = () => form.setFieldTouched(field.name, true).finally();
		inputRef.current?.addEventListener('cancel', makeFieldTouched);

		return () => {
			removeEventListener('cancel', makeFieldTouched);
			if (mediaUrl) URL.revokeObjectURL(mediaUrl);
		};
	}, []);

	return (
		<FieldWrapper {...fieldBaseProps} isMarginLeftUnset={true}>
			{field.value ? (
				<div className={styles.mediaInput}>
					<div className={styles.mediaInput__image_hover} onClick={deleteMedia}>
						{isVideo(field.value) ? (
							// eslint-disable-next-line jsx-a11y/media-has-caption
							<video
								ref={videoRef}
								className={cx(styles.mediaInput__image)}
								onLoadedMetadata={handleLoadedVideo}
								src={mediaUrl}
								muted={true}
							/>
						) : (
							<img
								className={cx(styles.mediaInput__image)}
								src={mediaUrl}
								onLoad={handleLoadedImage}
								alt={placeholder || ''}
							/>
						)}
					</div>
					<span className={styles.mediaInput_placeholder}>{placeholder}</span>
				</div>
			) : (
				<div className={styles.mediaInput}>
					<UploadIcon className={styles.mediaInput__image} onClick={selectMediaHandler} />
					<span className={styles.mediaInput_placeholder}>{placeholder}</span>
				</div>
			)}
			<input
				ref={inputRef}
				id="imagePath"
				type="file"
				hidden={true}
				accept={fieldBaseProps.onlyPhotos ? supportedPhotoMimeTypes : supportedMimeTypes}
				onChange={changeMediaHandler}
			/>
		</FieldWrapper>
	);
};

export default MediaInput;
