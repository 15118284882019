export const urls = {
	main: '/',
	admin: '/admin',
	loginSuperAdmin: '/login/super-admin',
	dashboard: '/admin/dashboard',
	supperAdminOppoList: '/admin/oppotunities',
	supperAdminUserList: '/admin/users',
	supperAdminVolList: '/admin/volunteers',
	supperAdminOrgList: '/admin/organizations',
	supperAdminInvitList: '/admin/invites',
	marketing: '/admin/marketing',
	store: '/admin/store-management',
	inbox: '/admin/inbox',
	live: '/admin/live-screen',
	inboxVolunteer: '/admin/chat',
	notFound: '/not-found',
	greetings: '/greetings',
	simpleCongrat: '/congrat',
	accessDenied: '/access-denied',
	feedViewLink: '/volunteer/feed/view',
};
