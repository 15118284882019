import { FC, ReactElement } from 'react';
import { ButtonBase } from '@material-ui/core';
import cx from 'classnames';

import styles from './index.module.scss';

type ButtonOutlinedProps = {
	title: string;
	clickHandler: () => void;
	parentClassName?: string;
	icon?: ReactElement;
	isDisableLastChildMargin?: boolean;
};
const ButtonOutlined: FC<ButtonOutlinedProps> = ({
	title,
	clickHandler,
	parentClassName,
	icon,
	isDisableLastChildMargin = false,
}: ButtonOutlinedProps) => {
	return (
		<ButtonBase
			type="button"
			className={cx(styles.outlined, parentClassName, {
				[styles.outlined__disableLastChildMargin]: isDisableLastChildMargin,
			})}
			onClick={clickHandler}
		>
			{icon || null}
			<span>{title}</span>
		</ButtonBase>
	);
};

export default ButtonOutlined;
