import * as XLSX from 'xlsx';
import { UploadedCoupon } from '../../types';

interface IExcelCouponRequest {
	couponCode: string;
}

const getParsedData = (sheet: XLSX.WorkSheet) => {
	const ref = sheet['!ref']!;

	return XLSX.utils.sheet_to_json(sheet, {
		range: ref,
		defval: '',
		blankrows: false,
	}) as Array<IExcelCouponRequest>;
};

export const parseCouponsXlsx = (file: File) => {
	return new Promise<Array<Partial<UploadedCoupon>>>(resolve => {
		const reader = new FileReader();
		reader.onload = e => {
			const data = e.target?.result;
			const excelData = XLSX.read(data, { type: 'binary' });
			const sheetName = excelData.SheetNames[0];
			const sheet = excelData.Sheets[sheetName];

			const parsedData = getParsedData(sheet);

			const normalizeParsedData = parsedData.map(parsedUserValuesObj => {
				let normalizedUserValuesObj = {} as IExcelCouponRequest;

				Object.keys(parsedUserValuesObj).forEach(key => {
					const normalizeKeyName = key
						.split(' ')
						.filter(k => k !== '-')
						.map((word, idx) => {
							word = word.toLowerCase().replace('*', '');
							if (idx !== 0) word = word[0].toUpperCase() + word.slice(1);
							return word;
						})
						.join('');

					normalizedUserValuesObj = {
						...normalizedUserValuesObj,
						[normalizeKeyName]: parsedUserValuesObj[key as keyof IExcelCouponRequest],
					};
				});

				return normalizedUserValuesObj;
			});

			const importedDailyQuotes: Array<Partial<UploadedCoupon>> = normalizeParsedData.map(quote => ({
				couponCode: quote.couponCode.replace(/\s/g, '').trim(),
			}));

			resolve(importedDailyQuotes);
		};

		reader.readAsBinaryString(file);
	});
};
