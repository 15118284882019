import React, { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as BlueArrow } from 'assets/image/selects/new-blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/new-white-arrow.svg';
import { FieldInputProps } from 'formik';
import { StoreItemResponse } from '@joc/api-gateway/lib/api-client';
import styles from './index.module.scss';
import ImageCell from '../../Table/CellRenderers/ImageCell';

type SingleSelectTypes = {
	field: FieldInputProps<string | number>;
	id?: string;
	placeholder?: string;
	setFieldValue: (field: string, value: string | number) => void;
	setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
	options: Array<StoreItemResponse>;
	classList?: Array<string | number>;
	disabled?: boolean;
	hideValue?: boolean;
};

const StoreItemSelect: FC<SingleSelectTypes> = ({
	field,
	id,
	placeholder,
	setFieldValue,
	setFieldTouched,
	options,
	classList,
	disabled,
	hideValue = false,
}: SingleSelectTypes) => {
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);
	const optionRef = useMemo(() => options.map(() => createRef<HTMLDivElement>()), [options]);

	const optionClickHandler = (option: StoreItemResponse): void => {
		setIsShowOptions(false);
		setFieldValue(field.name, option.id);
	};

	const handleClickOutside = (event: Event) => {
		if (isShowOptions) setFieldTouched(field.name, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};
	const titleClickHandler = () => {
		if (disabled) return;
		const valueIndex = options.findIndex(i => i.id === field.value);
		const target = optionRef[valueIndex]?.current;
		if (target)
			// set timeout cause in a first time the scroll doesn't work
			setTimeout(() => {
				target.scrollIntoView();
			}, 0);

		if (isShowOptions) setFieldTouched(field.name, true);
		setIsShowOptions(!isShowOptions);
	};

	const selectedStoreItem = useMemo(
		() => options.find(item => String(item.id) === String(field.value)),
		[field.value, options]
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	return (
		<div
			id={id}
			className={cx(styles.dropdown_select, styles.dropdown_select__storeItems, {
				[styles.md]: classList?.includes('md'),
				[styles.dropdown_select__active]: isShowOptions,
				[styles.dropdown_select__storeItems_active]: isShowOptions,
				[styles.dropdown_select__storeItems_active_lessThenFour]: options.length < 4,
				[styles.dropdown_select__storeItems_unpublished]: selectedStoreItem && !selectedStoreItem?.isPublished,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, {
					[styles.dropdown_select__title_selected]: !!field.value,
				})}
				onClick={titleClickHandler}
			>
				<div className={cx(styles.dropdown_select__title_value)}>
					{!hideValue && selectedStoreItem ? (
						<>
							<ImageCell
								imagePath={(selectedStoreItem?.mediaPathes as unknown as Array<string>)?.[0]}
								title={selectedStoreItem.title}
								withPopup={false}
							/>
							<span>{selectedStoreItem.title}</span>
						</>
					) : (
						placeholder
					)}
				</div>
				{!isShowOptions ? (
					<BlueArrow width={24} height={24} style={{ minWidth: '24px' }} />
				) : (
					<WhiteArrow width={24} height={24} style={{ minWidth: '24px' }} />
				)}
			</div>
			<div
				className={cx(styles.dropdown_select__options, {
					[styles.dropdown_select__options_center]: classList?.includes('option_center'),
				})}
			>
				{options.map((option, index) => (
					<div
						key={option.id}
						ref={optionRef[index]}
						className={styles.dropdown_select__options__item}
						onClick={() => optionClickHandler(option)}
					>
						<ImageCell
							imagePath={(option?.mediaPathes as unknown as Array<string>)?.[0]}
							title={option.title}
							withPopup={false}
						/>
						<span>{option.title}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default StoreItemSelect;
