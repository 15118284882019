import React, { FC } from 'react';
import cx from 'classnames';
import PossibleVolunteersItem from 'shared/components/PossibleVolunteersItem';
import { GetVolunteerByIdResponse, OPPORTUNITY_STATUSES, OPPORTUNITY_TYPES } from '@joc/api-gateway';
import styles from './PossibleVolunteersCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';

type PossibleVolunteersCellProps = {
	volunteers: Array<GetVolunteerByIdResponse> | undefined;
	opportunityId: number;
	isApprovalRequired: boolean;
	opportunityName: string;
	opportunityStatus: OPPORTUNITY_STATUSES;
	opportunityType: OPPORTUNITY_TYPES;
	inPastOppoList?: boolean;
};

const PossibleVolunteersCell: FC<PossibleVolunteersCellProps> = ({
	volunteers,
	opportunityId,
	isApprovalRequired,
	opportunityName,
	opportunityStatus,
	opportunityType,
	inPastOppoList = false,
}: PossibleVolunteersCellProps) => {
	return (
		<div className={cx(styles.possibleVolunteers, defaultStyles.main)}>
			<PossibleVolunteersItem
				volunteers={volunteers}
				opportunityId={opportunityId}
				isApprovalRequired={isApprovalRequired}
				opportunityName={opportunityName}
				opportunityStatus={opportunityStatus}
				opportunityType={opportunityType}
				actionsDisable={opportunityStatus === OPPORTUNITY_STATUSES.CLOSED}
				inPastOppoList={inPastOppoList}
			/>
		</div>
	);
};

export default PossibleVolunteersCell;
