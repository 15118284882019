import { useMutation, useQueryClient } from '@tanstack/react-query';

// types
import { IStatusRequest, StatusRequest } from '@joc/api-gateway';
import { StatusResponse } from '@joc/api-gateway/lib/api-client';

// core
import { API } from 'core/API';
import { STATUSES_QUERY_KEY } from 'core/constants';

interface IUseCreateStatus {
	// eslint-disable-next-line no-unused-vars
	createStatus: ({ organizationId, status }: { organizationId: string; status: IStatusRequest }) => void;
}

const createNewStatus = async (organizationId: string, status: IStatusRequest) => {
	return API.createStatus(organizationId, StatusRequest.fromJS(status));
};

export const useCreateStatus = (): IUseCreateStatus => {
	const queryClient = useQueryClient();
	const { mutate: createStatus } = useMutation<
		StatusResponse,
		Error,
		{ organizationId: string; status: IStatusRequest }
	>({
		mutationFn: ({ organizationId, status }) => createNewStatus(organizationId, status),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STATUSES_QUERY_KEY] }),
	});

	return { createStatus };
};
