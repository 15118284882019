import React, { createRef, FC, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useField } from 'formik';

import { ReactComponent as BlueArrow } from 'assets/image/selects/blue-arrow.svg';
import { ReactComponent as WhiteArrow } from 'assets/image/selects/white-arrow.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/checkbox/checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/icons/checkbox/unchecked.svg';
import { ReactComponent as ClearSvg } from 'assets/image/inputs/clear.svg';

import { CountryResponse } from '@joc/api-gateway';
import { API } from 'core/API';

import styles from './index.module.scss';

const CountriesSelect: FC<{ fieldName: string }> = ({ fieldName }) => {
	const [field, , helper] = useField<Array<number>>(fieldName);

	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);
	const [countries, setCountries] = useState<Array<CountryResponse>>([]);

	useEffect(() => {
		const fetchCountries = async () => {
			setCountries((await API.getCountries()) || []);
		};

		fetchCountries().finally();
	}, []);

	const optionRef = useMemo(() => countries.map(() => createRef<HTMLDivElement>()), [countries]);

	const optionClickHandler = (option: CountryResponse): void => {
		// setIsShowOptions(false);
		helper
			.setValue(
				field.value.includes(Number(option.id))
					? field.value.filter((id: number) => Number(id) !== Number(option.id))
					: [...field.value, Number(option.id)]
			)
			.finally();
	};

	const handleClickOutside = (event: Event) => {
		if (isShowOptions) helper.setTouched(true).finally();
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setIsShowOptions(false);
		}
	};
	const titleClickHandler = () => {
		if (isShowOptions) helper.setTouched(true).finally();
		setIsShowOptions(!isShowOptions);
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isShowOptions]);

	const value = useMemo(
		() =>
			countries
				.filter(country => field.value.includes(Number(country.id)))
				.map(c => c.countryName)
				.join(', '),
		[countries, field.value]
	);

	return (
		<div
			id={field.name}
			className={cx(styles.dropdown_select, {
				[styles.dropdown_select__active]: isShowOptions,
			})}
			ref={selectRef}
		>
			<div
				className={cx(styles.dropdown_select__title, styles.dropdown_select__title_countries, {
					[styles.dropdown_select__title_selected]: !!field.value.length,
				})}
				onClick={titleClickHandler}
			>
				<span style={{ ...(!field.value.length && !isShowOptions ? { color: '#5b768b' } : {}) }}>
					{field.value.length ? value : 'Select country'}
				</span>

				{!isShowOptions ? (
					<BlueArrow
						className={cx(
							styles.dropdown_select__title__vector,
							styles.dropdown_select__title__vector_countries
						)}
					/>
				) : (
					<WhiteArrow
						className={cx(
							styles.dropdown_select__title__vector,
							styles.dropdown_select__title__vector_countries,
							styles.dropdown_select__title__vector_countries_white
						)}
					/>
				)}

				{field.value.length && !isShowOptions ? (
					<ClearSvg
						className={styles.dropdown_select__title_remove_icon}
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							helper.setValue([]).finally();
						}}
					/>
				) : null}
			</div>
			<div className={cx(styles.dropdown_select__options, styles.dropdown_select__options_countries)}>
				{countries.map((option, index: number) => (
					<div
						key={option.id}
						ref={optionRef[index]}
						className={cx(styles.dropdown_select__options__item, {
							[styles.dropdown_select__options__item_selected]: field.value.includes(Number(option.id)),
						})}
						onClick={() => optionClickHandler(option)}
					>
						{field.value.includes(Number(option.id)) ? <CheckedIcon /> : <UncheckedIcon />}
						<span>{option.countryName}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default CountriesSelect;
