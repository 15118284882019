import React, { FC } from 'react';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import cx from 'classnames';

// icons
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';

// components
import SortableImagesListField from 'shared/components/SortableImagesListField';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputText from 'shared/components/FormInputs/InputText';
import Editor from 'shared/components/Editor';
import InputErrorHint from 'shared/components/InputErrorHint';
import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
import Loader from 'shared/components/Loader';
import CountriesSelect from 'shared/components/Selects/SelectDropdown/CountriesSelect';
import CustomTooltip from 'shared/components/Tooltip';

// helpers
import { MAX_SUB_TITLE_LENGTH, MAX_TITLE_LENGTH } from '../../helpers';

// types
import { DonationItemFormType } from '../../types';
import { DONATION_TYPE } from '@joc/api-gateway/lib/api-client';

// styles
import styles from './DonationItemForm.module.scss';

type DonationItemFormTypeProps = {
	isNew: boolean;
	isSaving: boolean;
	donationType: DONATION_TYPE;
	onCancel: () => void;
};

const DonationItemForm: FC<DonationItemFormTypeProps> = ({ isNew, isSaving, donationType, onCancel }) => {
	const { dirty, isValid, setFieldTouched, setFieldValue } = useFormikContext<DonationItemFormType>();

	const handleCancel = () => onCancel();

	const changeDateHandler = (field: string, value: Date) => {
		setFieldTouched(field, true).finally();
		setFieldValue(field, value || null).finally();
	};

	return (
		<Form className={styles.donationItemForm}>
			<SortableImagesListField fieldName="mediaPathes" />
			<Field name="title">
				{({ field, meta, form }: FieldProps<string, DonationItemFormType>) => (
					<div className={styles.donationItemForm__item}>
						<label className={styles.donationItemForm__item_label} htmlFor="title">
							Title<span className={styles.donationItemForm__item_required}>*</span>
						</label>
						<InputText
							id="title"
							field={field}
							placeholder="Enter donate name"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{!meta.error || !meta.touched ? (
							<span className={cx(styles.donationItemForm__item__fieldDescription)}>
								Maximum characters: {field.value.trim().length}/{MAX_TITLE_LENGTH}
							</span>
						) : null}
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<Field name="subtitle">
				{({ field, form, meta }: FieldProps<string, DonationItemFormType>) => (
					<div className={styles.donationItemForm__item}>
						<label className={styles.donationItemForm__item_label} htmlFor="subtitle">
							Subtitle
						</label>
						<InputText
							id="subtitle"
							field={field}
							placeholder="Enter donate subtitle"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{!meta.error || !meta.touched ? (
							<span className={cx(styles.donationItemForm__item__fieldDescription)}>
								Maximum characters: {field.value.trim().length}/{MAX_SUB_TITLE_LENGTH}
							</span>
						) : null}
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<Field name="description">
				{({ form, meta, field }: FieldProps<string, DonationItemFormType>) => (
					<div className={styles.donationItemForm__item}>
						<label className={styles.donationItemForm__item_label} htmlFor="description">
							Description<span className={styles.donationItemForm__item_required}>*</span>
						</label>
						<Editor
							fieldValue={field.value}
							onChange={value => form.setFieldValue('description', value)}
							onBlur={() => form.setFieldTouched(field.name, true)}
							placeholder="Enter your description"
							isShowToolbar={true}
						/>
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<Field name="price">
				{({ field, meta, form }: FieldProps<string, DonationItemFormType>) => (
					<div className={styles.donationItemForm__item}>
						<label className={styles.donationItemForm__item_label} htmlFor="price">
							{donationType === DONATION_TYPE.CAMPAIGN ? (
								<>Donation goal</>
							) : (
								<>
									Donation Amount <span className={styles.donationItemForm__item_required}>*</span>
								</>
							)}
						</label>
						<InputText
							id="price"
							type="number"
							field={field}
							placeholder="1"
							fieldValue={field.value}
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
							isDonation={true}
						/>
						<span className={cx(styles.donationItemForm__item__fieldDescription)}>
							{donationType === DONATION_TYPE.CAMPAIGN
								? 'Enter a numeric value to set the donation goal'
								: 'Enter a numeric value to set the donation amount'}
						</span>

						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<div className={styles.donationItemForm__item_pickers}>
				<Field name="expirationDate">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.donationItemForm__item, styles.donationItemForm__item_pickers_field)}>
							<label className={styles.donationItemForm__item_label} htmlFor={field.name}>
								Expiration Date
								{donationType === DONATION_TYPE.CAMPAIGN ? (
									<span className={styles.donationItemForm__item_required}>*</span>
								) : null}
							</label>
							<InlineDatePicker
								fieldName={field.name}
								fieldValue={field.value}
								setFieldValue={changeDateHandler}
								availableFuture={true}
								inputParentClassNames={styles.donationItemForm__item_pickers_item}
								cleareble={true}
							/>
							{meta.error && meta.touched && (
								<InputErrorHint errorText={meta.error} disableMargin={true} />
							)}
						</div>
					)}
				</Field>
			</div>
			<Field name="countryIds">
				{({ field, meta }: FieldProps<Array<string>>) => (
					<div className={styles.donationItemForm__item}>
						<label className={styles.donationItemForm__item_label} htmlFor="countryIds">
							<div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
								Countries
								<CustomTooltip
									title="Select countries where Donation Activity will be displayed. If no country is selected, donations will be shown globally."
									arrow={true}
									placement="top"
									PopperProps={{
										style: {
											width: '300px',
											textAlign: 'center',
											fontFamily: '"Rubik", sans-serif',
										},
									}}
								>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: '18.96px',
											height: '18.96px',
											cursor: 'pointer',
										}}
									>
										<InfoSvg width={16} height={16} />
									</div>
								</CustomTooltip>
							</div>
						</label>
						<CountriesSelect fieldName={field.name} />
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<div className={styles.donationItemForm__actions}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.donationItemForm__actions_button}
					title="Cancel"
					clickHandler={handleCancel}
				/>
				<div className={styles.donationItemForm__actions_button}>
					{isSaving ? (
						<Loader
							loadProps={{
								disableCenterStyle: true,
								parentClassName: styles.donationItemForm__actions_button_loader,
							}}
						/>
					) : (
						<ButtonDefault
							submitType={true}
							classList={['primary', 'lg']}
							parentClassName={cx(styles.donationItemForm__actions_button, {
								[styles.disable_submit]: !dirty || !isValid,
							})}
							disableShadow={true}
							title={isNew ? 'Create' : 'Save'}
							disabled={!dirty || !isValid}
						/>
					)}
				</div>
			</div>
		</Form>
	);
};

export default DonationItemForm;
