import { Dispatch, FC, SetStateAction } from 'react';

// components
import PopupContainer from '../PopupContainer';
import WhiteContainer from '../WhiteContainer';

// styles
import styles from './ZoomPhotoPopup.module.scss';

type ZoomPhotoPopupParentProps = {
	urlPhoto: string;
	isPhotoStyleUpdating?: boolean;
	isShowPopup: boolean;
	setIsShowPopup: Dispatch<SetStateAction<boolean>>;
	title?: string;
};

const NewZoomPhotoPopup: FC<ZoomPhotoPopupParentProps> = ({
	urlPhoto,
	isPhotoStyleUpdating,
	isShowPopup,
	setIsShowPopup,
	title,
}: ZoomPhotoPopupParentProps) => (
	<>
		{isShowPopup && (
			<PopupContainer
				isPhotoStyle={true}
				isDisablePadding={true}
				isPhotoStyleUpdating={isPhotoStyleUpdating}
				setIsShowPopup={setIsShowPopup}
				closeButtonParentClickHandler={() => setIsShowPopup(false)}
			>
				<WhiteContainer title={title || ''} parentClassName={styles.wrapper}>
					<div className={styles.wrapper}>
						<img className={styles.logo} src={urlPhoto} alt="" />
					</div>
				</WhiteContainer>
			</PopupContainer>
		)}
	</>
);

export default NewZoomPhotoPopup;
